import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { getRouteMetaDataFromPathName } from '../../router/routes.config';

import { selectOrganizationById } from '../../modules/organization/organization.selector';
import { selectStudiotelsByOrganizationId } from '../../modules/studiotel/studiotel.selector';

import Title from '../../modules/title/components/title.component';
import FleetTable from '../../modules/fleet/components/table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingBottom: theme.spacing(2),
    },
    content: {
      marginTop: theme.spacing(2),
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(2),
      '& #title': {
        flex: 1,
      },
    },
  })
);

interface ParamTypes {
  organizationId: string;
}

export default function Fleet() {
  const location = useLocation();
  const { organizationId } = useParams<ParamTypes>();
  const organization = useSelector(selectOrganizationById)(
    parseInt(organizationId)
  );
  const studiotels = useSelector(selectStudiotelsByOrganizationId)(
    parseInt(organizationId)
  );
  const routeMeta = getRouteMetaDataFromPathName(location.pathname);
  const classes = useStyles();

  return (
    <div id="fleet-view" className={classes.container}>
      <Title>{routeMeta && routeMeta.name}</Title>

      <Container>
        <Paper id="fleet-view-content" className={classes.content}>
          <div id="fleet-view-toolbar" className={classes.toolbar}>
            {organization && (
              <Typography id="title" variant="h5" align="left">
                {`Studioplay(s) - ${organization.name}`}
              </Typography>
            )}
          </div>
          <Divider />

          <FleetTable studiotels={studiotels} />
        </Paper>
      </Container>
    </div>
  );
}

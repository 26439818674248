import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StyledMenu from './elements/menu.component';
import StyledMenuItem from './elements/item.component';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import { DispatchProps, SelectProps } from './appBarMenu.connector';
// TODO: remove decodeJwt() and create a module user to get user info
import decodeJwt from '../../../utils/jwt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      '& .MuiMenu': {
        width: '500px',
      },
    },
    iconAccount: {
      marginRight: theme.spacing(1),
      color: theme.palette.common.white,
    },
    menuButton: {
      color: theme.palette.common.white,
      textTransform: 'lowercase',
    },
    icon: {
      color: theme.icon.color.light,
    },
  })
);

interface IProps {
  token: SelectProps['token'];
  logout: DispatchProps['logout'];
}

export default function AppBarMenu(props: IProps) {
  const { logout } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { token } = props;
  let decodeToken;
  if (token) {
    decodeToken = decodeJwt(token);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menuButton}
      >
        <AccountCircleIcon className={classes.iconAccount} />
        {decodeToken ? decodeToken.email : null}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <StyledMenuItem onClick={handleClose} disabled>
          <ListItemText primary={decodeToken ? decodeToken.email : null} />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <SettingsRoundedIcon className={classes.icon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Paramètres" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <HelpOutlineRoundedIcon className={classes.icon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Aides" />
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleLogout}>
          <ListItemText primary="Se deconnecter" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../layout.component';
import Aside from '../aside.component';
import svg from './img_step2.svg';
import SoundsList from './soundsList.component';
import Loader from '../../../../../utils/components/loader.component';
import {
  selectFetchPending,
  selectSoundsList,
} from '../../../../../sound/sound.selector';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Upload from './upload.component';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    stepTitle: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.h6.fontSize,
      textAlign: 'left',
      fontWeight: 'bold',
    },
    soundsContainer: {
      display: 'flex',
      paddingRight: theme.spacing(1),
    },
    soundsControlContainer: {
      display: 'flex',
      flexBasis: '60%',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    update: {
      marginLeft: theme.spacing(3),
    },
    soundsWrapper: {
      width: '100%',
      flex: 1,
      height: '42vh',
      overflow: 'auto',
      marginRight: theme.spacing(1),
    },
    uploadWrapper: {
      flex: 1,
      flexBasis: '40%',
    },
  })
);

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
}

export default function Step2(props: IProps) {
  const { Controls, deployment, setDeployment, setIsStepValid } = props;
  const pending = useSelector(selectFetchPending);
  const sounds = useSelector(selectSoundsList);
  const classes = useStyles();

  return (
    <Layout
      title="Message à déployer"
      aside={
        <Aside
          svg={svg}
          title="Contenu à déployer"
          description="Tout d’abord, sélectionnez le message que vous souhaitez attribuer à la flotte. Vous pouvez ajouter un message transmis par ATS Studios."
        />
      }
      controls={<Controls />}
    >
      {pending ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Loader />
        </Box>
      ) : (
        <div id="step2-content" className={classes.container}>
          <Typography
            id="title"
            className={classes.stepTitle}
            color="textPrimary"
          >
            {sounds &&
              sounds.length > 0 &&
              `Message${sounds.length > 1 ? 's' : ''} Disponible${
                sounds.length > 1 ? 's' : ''
              }`}
          </Typography>

          <div id="sounds-container" className={classes.soundsContainer}>
            {sounds && sounds.length > 0 && !deployment.sound && (
              <div
                id="sounds-control-container"
                className={classes.soundsControlContainer}
              >
                <div id="sounds-wrapper" className={classes.soundsWrapper}>
                  <SoundsList
                    sounds={sounds}
                    deployment={deployment}
                    setDeployment={setDeployment}
                    setIsStepValid={setIsStepValid}
                  />
                </div>
                <Button className={classes.update} disabled>
                  Modifier
                </Button>
              </div>
            )}
            <div id="upload-wrapper" className={classes.uploadWrapper}>
              <Upload
                deployment={deployment}
                setDeployment={setDeployment}
                setIsStepValid={setIsStepValid}
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

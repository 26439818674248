import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import {
  KeyboardTimePicker,
  MaterialUiPickersDate
} from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {}
  })
);

interface IProps {
  disabled: boolean;
  label: string;
  selectedTime: MaterialUiPickersDate;
  setSelectedTime: Dispatch<SetStateAction<any>>;
  className?: string;
}

const TimePicker = (props: IProps) => {
  const { selectedTime, setSelectedTime, label, disabled, className } = props;
  const classes = useStyles();

  const handleTimeChange = (time: MaterialUiPickersDate) => {
    setSelectedTime(time);
  };

  return (
    <KeyboardTimePicker
      className={`${clsx(classes.root, className)}`}
      disabled={disabled}
      views={['hours', 'minutes', 'seconds']}
      format="HH:mm:ss"
      ampm={false}
      variant="inline"
      label={label}
      value={selectedTime}
      onChange={handleTimeChange}
    />
  );
};

export default TimePicker;

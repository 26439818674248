import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../@types/state';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import * as planningAction from '../../planning.action';
import { selectCurrentDate } from '../../planning.selector';
import DateNavigator from './dateNavigator.component';

export interface SelectProps {
  currentDate: State['planning']['currentDate'];
}

const mapStateToProps = (state: State) => {
  return {
    currentDate: selectCurrentDate(state)
  };
};

export interface DispatchProps {
  setCurrentDate: (currentDate: State['planning']['currentDate']) => void;
}

const mapDispatchToProps = (dispach: ThunkDispatch<State, {}, AnyAction>): DispatchProps => {
  return {
    setCurrentDate: (currentDate: State['planning']['currentDate']) => {
      dispach(planningAction.setCurrentDate(currentDate));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateNavigator);

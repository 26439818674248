import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../@types/state';
import { Planning } from '../../../../@types/state/planning';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import * as studiotelAction from '../../studiotel.action';
import * as planningAction from '../../../planning/planning.action';
import {
  selectCurrentStudiotelOffhookPlanning,
  selectCurrentStudiotelMohPlanning,
  selectCurrentStudiotelVoicemailPlanning
} from '../../../planning/planning.selector';
import CurrentMessages from './currentMessages.component';

export interface DispatchProps {
  getPlannings: () => void;
  setCurrentStudiotel: (
    currentStudiotelId: State['studiotel']['currentStudiotelId']
  ) => void;
}

export interface SelectProps {
  currentStudiotelOffhookPlanning: Planning | null;
  currentStudiotelMohPlanning: Planning | null;
  currentStudiotelVoicemailPlanning: Planning | null;
}

const mapStateToProps = (state: State) => {
  return {
    currentStudiotelOffhookPlanning: selectCurrentStudiotelOffhookPlanning(
      state
    ),
    currentStudiotelMohPlanning: selectCurrentStudiotelMohPlanning(state),
    currentStudiotelVoicemailPlanning: selectCurrentStudiotelVoicemailPlanning(
      state
    )
  };
};

const mapDispatchToProps = (
  dispach: ThunkDispatch<State, {}, AnyAction>
): DispatchProps => {
  return {
    getPlannings: async () => {
      await dispach(planningAction.getPlannings());
    },
    setCurrentStudiotel: (
      currentStudiotelId: State['studiotel']['currentStudiotelId']
    ) => {
      dispach(studiotelAction.setCurrentStudiotel(currentStudiotelId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentMessages);

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import Title from '../../modules/title/components/title.component';
import Box from '@material-ui/core/Box';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import { useLocation } from 'react-router-dom';
import * as deploymentAction from '../../modules/deployment/deployment.action';
import DeploymentWorkflow from '../../modules/deployment/components/workflow/deploymentWorkflow.component';
import {
  selectInitFetch,
  selectFetchPending,
} from '../../modules/deployment/deployment.selector';
import Loader from '../../modules/utils/components/loader.component';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useWindowHeight } from '../../modules/utils/hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: theme.workflow.background.main,
    },
    content: (height: number) => ({
      height: height,
    }),
  })
);

export default function DeploymentCreate() {
  const [height, setHeight] = useState(0);
  const location = useLocation();
  const routeMeta = getRouteMetaDataFromPathName(location.pathname);
  const dispatch = useDispatch();
  const isInitFetch = useSelector(selectInitFetch);
  const isLoading = useSelector(selectFetchPending);
  const windowHeight = useWindowHeight();
  const titleEl = useRef<null | HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const { current } = titleEl;
    if (current) {
      const contentHeight =
        windowHeight - current.clientHeight - theme.header.height;
      setHeight(contentHeight);
    }
  }, [theme.header.height, titleEl, windowHeight]);

  const classes = useStyles(height);

  useEffect(() => {
    dispatch(deploymentAction.getDeployments());
  }, [dispatch]);

  return (
    <div id="create-workflow-view" className={classes.container}>
      {isInitFetch && isLoading ? (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: 'translateX(-50%)' }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Title ref={titleEl}>{routeMeta && routeMeta.name}</Title>
          <div id="create-workflow-view-content" className={classes.content}>
            <DeploymentWorkflow height={height} />
          </div>
        </>
      )}
    </div>
  );
}

export enum StudiotelActionTypes {
  GET_STUDIOTELS_PENDING = '@@studiotel/GET_STUDIOTELS_PENDING',
  GET_STUDIOTELS_SUCCESS = '@@studiotel/GET_STUDIOTELS_SUCCESS',
  GET_STUDIOTELS_ERROR = '@@studiotel/GET_STUDIOTELS_ERROR',
  GET_STUDIOTELS_STATE_PENDING = '@@studiotel/GET_STUDIOTELS_STATE_PENDING',
  GET_STUDIOTELS_STATE_SUCCESS = '@@studiotel/GET_STUDIOTELS_STATE_SUCCESS',
  GET_STUDIOTELS_STATE_ERROR = '@@studiotel/GET_STUDIOTELS_STATE_ERROR',
  SET_CURRENT_STUDIOTEL = '@@studiotel/SET_CURRENT_STUDIOTEL',
  ASSOCIATE_STUDIOTEL_PENDING = '@@studiotel/ASSOCIATE_STUDIOTEL_PENDING',
  ASSOCIATE_STUDIOTEL_SUCCESS = '@@studiotel/ASSOCIATE_STUDIOTEL_SUCCESS',
  ASSOCIATE_STUDIOTEL_ERROR = '@@studiotel/ASSOCIATE_STUDIOTEL_ERROR',
  GET_UNASSOCIATED_STUDIOTEL_PENDING = '@@studiotel/GET_UNASSOCIATED_STUDIOTEL_PENDING',
  GET_UNASSOCIATED_STUDIOTEL_SUCCESS = '@@studiotel/GET_UNASSOCIATED_STUDIOTEL_SUCCESS',
  GET_UNASSOCIATED_STUDIOTEL_ERROR = '@@studiotel/GET_UNASSOCIATED_STUDIOTEL_ERROR',
  UPDATE_STUDIOTEL_PENDING = '@@studiotel/UPDATE_STUDIOTEL_PENDING',
  UPDATE_STUDIOTEL_SUCCESS = '@@studiotel/UPDATE_STUDIOTEL_SUCCESS',
  UPDATE_STUDIOTEL_ERROR = '@@studiotel/UPDATE_STUDIOTEL_ERROR',
}

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { ActionSelected } from '../../../config/studiotel.config';

import StepperLayout from '../layout.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    '& #alert': {
      width: '100%',
    },
    '& #config-usage-choice': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  button: {
    '&:hover': {
      transform: 'scale(1.01)',
      transition: 'all .5s',
      '& $imageBackdrop': {
        opacity: 0.2,
      },
    },
    boxShadow: theme.shadows[2],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 200,
    height: '100%',
    padding: theme.spacing(2),
    '& svg': {
      fontSize: 60,
    },
  },
  selected: {
    opacity: 0.08,
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
}));

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  hasUploadedConfigFile: boolean;
  actionSelected: ActionSelected;
  setActionSelected: React.Dispatch<React.SetStateAction<ActionSelected>>;
  version: string | undefined;
}

export default function SetupConfigFile(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    hasUploadedConfigFile,
    actionSelected,
    setActionSelected,
    version,
  } = props;

  const classes = useStyles();

  React.useEffect(() => {
    setStepValid(true);
  }, [setStepValid]);

  function handleActionSelect(
    event: React.MouseEvent<HTMLElement>,
    newAction: ActionSelected
  ) {
    setActionSelected(newAction);
  }

  return (
    <StepperLayout
      hintTitle={`Moyen d'installation`}
      hintContent={
        <>
          <Typography variant="caption" color="textSecondary" align="center">
            {`- Télécharger : obtenez le fichier de configuration pour l'installer localement sur votre Studioplay`}
          </Typography>
          <Typography variant="caption" color="textSecondary" align="center">
            {`- Téléverser : installation automatique de votre Studioplay`}
          </Typography>
        </>
      }
      hintAction={hintAction && hintAction}
      title={`Choix du moyen d'installation`}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        <Alert id="alert" severity="success">
          Fichier de configuration prêt à être installé !
        </Alert>
        <div id="config-usage-choice">
          <ToggleButtonGroup
            value={actionSelected}
            exclusive
            onChange={handleActionSelect}
            aria-label="text alignment"
          >
            {!hasUploadedConfigFile && (
              <ToggleButton value={ActionSelected.UPLOAD}>
                <Typography style={{ marginRight: '8px' }}>
                  TELECHARGER
                </Typography>
                <GetAppRoundedIcon />
              </ToggleButton>
            )}
            <ToggleButton value={ActionSelected.TELEVERSE}>
              <Typography style={{ marginRight: '8px' }}>TELEVERSER</Typography>
              <PublishRoundedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </StepperLayout>
  );
}

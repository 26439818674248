import { getAuthenticatedInstance } from './helpers/axios.helpers';
import {AssociateStudiotelApiPayload, GetUnassociatedStudiotelApiPayload, UpdateStudiotelApiPayload} from '../../@types/api/studiotel';

const authenticatedInstance = getAuthenticatedInstance();

export async function getStudiotels(): Promise<any> {
  return authenticatedInstance.get('/studiotel_with_plannings');
}

export async function getStudiotelsState(): Promise<any> {
  return authenticatedInstance.get('/studiotels_state');
}

export async function associateStudiotel(payload: AssociateStudiotelApiPayload): Promise<any> {
  return authenticatedInstance.post('rpc/associate_studiotel', payload);
}

export async function getUnassociatedStudiotel(payload: GetUnassociatedStudiotelApiPayload): Promise<any> {
  return authenticatedInstance.post('rpc/get_unassociated_studiotel', payload);
}

export async function updateStudiotel(payload:UpdateStudiotelApiPayload , studiotelId:number): Promise<any> {
  return authenticatedInstance.patch(`/studiotel_with_plannings?id=eq.${studiotelId}`, payload);
}

import React, { Dispatch, SetStateAction, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import { Sound } from '../../../../../../@types/state/sound';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import Message from '../../../../../studiotel/components/currentMessages/message/message.connector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

export interface IProps {
  sounds: Sound[];
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
}

export default function SoundsList(props: IProps) {
  const { sounds, deployment, setDeployment, setIsStepValid } = props;
  const [selectedValue, setSelectedValue] = useState<string>('');

  const classes = useStyles();

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const soundId = event.target.value;
    setSelectedValue(soundId);
    const sound = sounds.find(
      (sound: Sound) => sound.id.toString() === soundId
    );
    if (sound) {
      const deploymentUpdated = {
        ...deployment,
        sound_id: sound.id,
        sound_title: sound.title,
        sound_description: sound.description,
        sound_path: sound.path,
        message_type: sound.type,
      };
      setDeployment(deploymentUpdated);
      setIsStepValid(true);
    }
  }

  return (
    <List className={classes.root}>
      {sounds.map((sound: Sound) => (
        <ListItem key={sound.id} className={classes.item}>
          <Message
            key={sound.id}
            sounds={sound}
            messageType={sound.type}
            isDefault
          />
          <Box marginLeft={1}>
            <Radio
              color="primary"
              checked={selectedValue === sound.id.toString()}
              onChange={handleOnChange}
              value={sound.id}
              name="sound-selection"
            />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}

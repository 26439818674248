import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { StudiotelConfigFile } from '../../stepper.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    maxHeight: `calc(100vh - ${350}px)`,
    overflow: 'auto',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    width: 500,
  },
}));

interface IProps {
  configFile: StudiotelConfigFile;
  setConfigFile: React.Dispatch<React.SetStateAction<StudiotelConfigFile>>;
}

export default function Form(props: IProps) {
  const { configFile, setConfigFile } = props;
  const classes = useStyles();
  const [errors, setErrors] = React.useState({
    nb_pre: false,
    nb_att: false,
    nb_rep: false,
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    setConfigFile({
      ...configFile,
      studiotel: { ...configFile.studiotel, [name]: value },
    });
  }

  function handleChangeToNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, min, max } = event.target;
    const parsedValue = parseInt(value) ?? 0;

    const isError = parsedValue < Number(min) || parsedValue > Number(max);

    setErrors((errors) => ({ ...errors, [name]: isError }));

    setConfigFile({
      ...configFile,
      studiotel: {
        ...configFile.studiotel,
        [name]: parsedValue,
      },
    });
  }

  return (
    <div className={classes.formContent}>
      <TextField
        name="hostname"
        label="Nom de domaine ou IP"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        value={configFile?.studiotel?.hostname || ''}
        onChange={handleChange}
        helperText="Veuillez entrer l’adresse IP ou le nom de domaine de l’interface OXO Connect ou Connect Evolution. Par exemple : 192.168.1.10 ou oxo.local.lan"
      />
      <TextField
        name="login"
        label="Login"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="new-login"
        value={configFile?.studiotel?.login || ''}
        onChange={handleChange}
      />
      <TextField
        name="password"
        label="Mot de passe"
        type="password"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="new-password"
        value={configFile?.studiotel?.password || ''}
        onChange={handleChange}
      />
      <TextField
        name="nb_pre"
        label="Numéro du son pré-décroché"
        type="number"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="Veuillez entrer l’identifiant du champ pour le son pré-décroché (de 1 à 20 inclus) qui a été déclaré lors de la configuration du matériel"
        value={configFile?.studiotel?.nb_pre || ''}
        onChange={handleChangeToNumber}
        error={errors.nb_pre}
        inputProps={{ min: 1, max: 20 }}
      />
      <TextField
        name="nb_att"
        label="Numéro du son d’attente"
        type="number"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="Veuillez entrer l’identifiant du champ pour le son d’attente (de 1 à 4 inclus) qui a été déclaré lors de la configuration du matériel"
        value={configFile?.studiotel?.nb_att || ''}
        onChange={handleChangeToNumber}
        error={errors.nb_att}
        inputProps={{ min: 1, max: 4 }}
      />
      <TextField
        name="nb_rep"
        label="Numéro du son de répondeur"
        type="number"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="Veuillez entrer l’identifiant du champ pour le son répondeur (de 1 à 20 inclus) qui a été déclaré lors de la configuration du matériel"
        value={configFile?.studiotel?.nb_rep || ''}
        onChange={handleChangeToNumber}
        error={errors.nb_rep}
        inputProps={{ min: 1, max: 20 }}
      />
    </div>
  );
}

import { Calendar, useStaticState } from '@material-ui/pickers';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DispatchProps } from './planningDatePicker.connector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      maxHeight: theme.datePicker.height
    }
  })
);

interface IProps {
  setCurrentDate: DispatchProps['setCurrentDate'];
}

const PlanningDatePicker: React.FC<IProps> = props => {
  const { setCurrentDate } = props;
  const [value, handleDateChange] = useState<Date>(new Date());
  const classes = useStyles();

  useEffect(() => {
    setCurrentDate(value);
  }, [setCurrentDate, value]);

  // TODO: remove @ts-ingnore after finding the correct type of material-ui/picker lib
  const { pickerProps } = useStaticState({
    value,
    // @ts-ignore
    onChange: handleDateChange
  });

  return (
    <div className={classes.datePicker}>
      <Calendar {...pickerProps} />
    </div>
  );
};

export default PlanningDatePicker;

import React from 'react';
import Box from '@material-ui/core/Box';

export default function Error() {
  return (
    <Box pt={3} textAlign="center" fontSize="h6.fontSize">
      Page non trouvée
    </Box>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { TreeItems } from '../../../../@types/hierarchy';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Hierachy from '../../../hierarchy/components/hierarchy.component';
import { getTreeItemsFromData } from '../../../hierarchy/helpers';
import { selectTreeItems } from '../../../hierarchy/hierarchy.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
}));

export default function FleetContent() {
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const treeItems = useSelector(selectTreeItems);
  const topLevelNode = treeItems[0];
  const children = topLevelNode?.children;
  let startTreeItem: TreeItems[];
  const classes = useStyles();

  if (children) {
    startTreeItem = children;
  } else {
    startTreeItem = treeItems;
  }

  return (
    <>
      <Box id="drawer-fleet-content" px={2} py={2} overflow="auto">
        <Box
          pl={1}
          pb={2}
          textAlign="left"
          fontWeight="fontWeightBold"
          fontSize="h6.fontSize"
        >
          {topLevelNode && topLevelNode.name}
        </Box>
        <div id="fleet-drawer-content" className={classes.container}>
          {startTreeItem && (
            <Hierachy expanded={expanded} setExpanded={setExpanded}>
              {getTreeItemsFromData(startTreeItem, {
                checkbox: false,
                activeClick: true,
              })}
            </Hierachy>
          )}
        </div>
      </Box>
    </>
  );
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { HierarchyActionTypes } from './types';
import * as hierarchyServiceApi from '../../services/api/hierarchy.service';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';

export interface GetHierarchyPending
  extends Action<HierarchyActionTypes.GET_HIERARCHY_PENDING> {
  pending: boolean;
}

export interface GetHierarchySuccess
  extends Action<HierarchyActionTypes.GET_HIERARCHY_SUCCESS> {
  data: State['hierarchy']['data'];
}

export interface GetHierarchyError
  extends Action<HierarchyActionTypes.GET_HIERARCHY_ERROR> {
  message: any;
}

export type HierachyAction =
  | GetHierarchyPending
  | GetHierarchySuccess
  | GetHierarchyError;

const getHierarchyPending = (pending: boolean): GetHierarchyPending => {
  return {
    type: HierarchyActionTypes.GET_HIERARCHY_PENDING,
    pending
  };
};

const getHierarchySuccess = (
  data: State['hierarchy']['data']
): GetHierarchySuccess => {
  return {
    type: HierarchyActionTypes.GET_HIERARCHY_SUCCESS,
    data
  };
};

const getHierarchyError = (message: string): GetHierarchyError => {
  return {
    type: HierarchyActionTypes.GET_HIERARCHY_ERROR,
    message
  };
};

export const getHierarchy = (): ThunkAction<
  Promise<void>,
  State,
  {},
  HierachyAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, HierachyAction>
  ): Promise<void> => {
    dispatch(getHierarchyPending(true));
    try {
      const response = await hierarchyServiceApi.getHierarchy();
      await waitFor(appConfig.waitForTime);
      const { data } = response;

      if (response.status === 200) {
        dispatch(getHierarchySuccess({ hierarchy: data }));
      }
    } catch (error) {
      dispatch(getHierarchyError(error.message));
    }
  };
};

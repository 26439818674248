import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const StyledMenuItem = withStyles(theme => ({
  root: {
    widht: '500px',
    ' &:hover': {
      backgroundColor: theme.appbar.background,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiSvgIcon-root': {
        color: theme.palette.text.secondary
      }
    }
  }
}))(MenuItem);

export default StyledMenuItem;

import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../@types/state';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
// import * as planningAction from '../../planning/planning.action';
import { selectCurrentDate, selectCurrentStudiotelPlannings } from '../../planning/planning.selector';
import Planning from './planning.component';

export interface DispatchProps {}

export interface SelectProps {
  currentDate: State['planning']['currentDate'];
  currentStudiotelPlannings: State['planning']['data']['plannings'];
}

const mapStateToProps = (state: State) => {
  return {
    currentDate: selectCurrentDate(state),
    currentStudiotelPlannings: selectCurrentStudiotelPlannings(state)
  };
};

const mapDispatchToProps = (dispach: ThunkDispatch<State, {}, AnyAction>): DispatchProps => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Planning);

export enum DeploymentActionTypes {
  GET_DEPLOYMENTS_PENDING = '@@deployment/GET_DEPLOYMENTS_PENDING',
  GET_DEPLOYMENTS_SUCCESS = '@@deployment/GET_DEPLOYMENTS_SUCCESS',
  GET_DEPLOYMENTS_ERROR = '@@deployment/GET_DEPLOYMENTS_ERROR',
  CREATE_DEPLOYMENT_PENDING = '@@deployment/CREATE_DEPLOYMENT_PENDING',
  CREATE_DEPLOYMENT_SUCCESS = '@@deployment/CREATE_DEPLOYMENT_SUCCESS',
  CREATE_DEPLOYMENT_ERROR = '@@deployment/CREATE_DEPLOYMENT_ERROR',
  SET_DEPLOYMENT_ORGANIZATION = '@@deployment/SET_DEPLOYMENT_ORGANIZATION',
  RESET_DEPLOYMENT_ERRORS = '@@deployment/RESET_DEPLOYMENT_ERRORS'
}

import React from 'react';
import { Studiotel } from '../../@types/state/studiotel';
import { TreeItems, Options } from '../../@types/hierarchy';
import StudiotelItem from './components/items/studiotelItem.component';
import OrganizationItem from './components/items/organizationItem.component';

export function getTreeItemsFromData(
  treeItems: TreeItems[],
  options: Options
): JSX.Element[] {
  return treeItems.map((treeItem: TreeItems) => {
    let children: JSX.Element[] | undefined = undefined;
    const studiotels = treeItem.studiotels;
    options.alert = treeItem.alert;

    // build the tree recursively
    if (treeItem.children) {
      children = getTreeItemsFromData(treeItem.children, options);
    }

    if (studiotels) {
      if (children) {
        studiotels.map((studiotel: Studiotel) => {
          return children?.push(
            <StudiotelItem key={studiotel.id} studiotel={studiotel} />
          );
        });
      } else {
        children = [];
        studiotels.map((studiotel: Studiotel) => {
          return children?.push(
            <StudiotelItem key={studiotel.id} studiotel={studiotel} />
          );
        });
      }
    }

    return (
      <OrganizationItem
        key={treeItem.id}
        treeItem={treeItem}
        children={children}
        options={options}
      />
    );
  });
}

export function findChildrenIds(treeItems: TreeItems[]) {
  let ids: number[] = [];

  treeItems.forEach((treeItem: TreeItems) => {
    const { children } = treeItem;
    if (children) {
      const childIds = findChildrenIds(children);
      ids = [...new Set([...ids, treeItem.id, ...childIds])];
    } else {
      ids = [...new Set([...ids, treeItem.id])];
    }
  });

  return ids;
}

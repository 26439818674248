import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Layout from '../layout.component';
import Aside from '../aside.component';
import svg from './img_step4.svg';
import Box from '@material-ui/core/Box';
import TimelapseRoundedIcon from '@material-ui/icons/TimelapseRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Scheduler from './scheduler/scheduler.component';
import { InProgressDeployment } from '../../../../../../@types/deployment';

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
}

export default function Step4(props: IProps) {
  const { Controls, deployment, setDeployment, setIsStepValid } = props;
  const [messageType, setMessageType] = useState('default');

  useEffect(() => {
    if (messageType === 'default') {
      setIsStepValid(true);
    } else {
      setIsStepValid(false);
    }
  }, [messageType, setIsStepValid]);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setMessageType(value);
    // default planning (when not default, planning is generated by the scheduler component)
    if (value) {
      const deploymentUpdated = {
        ...deployment,
        planning: {
          is_default: true,
          start_time: null,
          stop_time: null,
          week_days: [
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
          ],
        },
      };
      setDeployment(deploymentUpdated);
    } else {
      setIsStepValid(false);
    }
  }

  return (
    <Layout
      title="Plage de diffusion"
      aside={
        <Aside
          svg={svg}
          title="Période de diffusion"
          description="Vous pouvez à présent définir une période de diffusion du message en fonction du planning souhaité. Vous pouvez aussi préciser la récurrence du message."
        />
      }
      controls={<Controls />}
    >
      <Box
        id="step4-content"
        display="flex"
        width="100%"
        flexDirection="column"
        p={1}
      >
        <Box id="message-type-selection">
          <RadioGroup onChange={handleOnChange} value={messageType}>
            <FormControlLabel
              value="default"
              control={<Radio color="primary" />}
              label={
                <Box display="inline-flex" alignItems="center">
                  <Box mr={1}>Message par défaut</Box>
                  <Box>
                    <RepeatRoundedIcon />
                  </Box>
                </Box>
              }
            />
            <Box
              textAlign="start"
              maxWidth="70%"
              ml={1}
              fontSize="caption.fontSize"
            >
              Le message remplacera l’ancien message par défaut et sera joué
              lorsque aucun message temporaire n’est programmé.
            </Box>
            <FormControlLabel
              value="temp"
              control={<Radio color="primary" />}
              label={
                <Box display="inline-flex" alignItems="center">
                  <Box mr={2}>Message temporaire</Box>
                  <Box>
                    <TimelapseRoundedIcon />
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </Box>
        <Scheduler
          disabled={messageType === 'default'}
          setIsStepValid={setIsStepValid}
          deployment={deployment}
          setDeployment={setDeployment}
        />
      </Box>
    </Layout>
  );
}

import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import PowerRoundedIcon from '@material-ui/icons/PowerRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import config, { Column } from '../config/fleet.config';
import { Endpoint } from '../../../router/routes.config';
import { replaceParams } from '../../../router/routes.helpers';

import { selectStateByStudiotelId } from '../../studiotel/studiotel.selector';

import { Studiotel } from '../../../@types/state/studiotel';

import AddDialog from './dialog/addStudiotel.component';
import RemoveDialog from './dialog/removeStudiotel.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: `calc(100vh - ${200}px)`,
    overflow: 'auto',
  },
  table: {
    minWidth: 650,
  },
  label: {
    background: theme.palette.common.white,
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IProps {
  studiotels: Studiotel[] | undefined;
}

export default function FleetTable(props: IProps) {
  const { studiotels } = props;
  const [openAddConfirm, setOpenAddConfirm] = React.useState(false);
  const [openRemoveConfirm, setOpenRemoveConfirm] = React.useState(false);
  const [studiotelIdToRemove, setStudiotelIdToRemove] = React.useState<
    number | null
  >(null);
  const getStateByStudiotelId = useSelector(selectStateByStudiotelId);
  const classes = useStyles();
  const history = useHistory();

  function handleAddStudiotel() {
    setOpenAddConfirm(true);
  }

  function handleRemoveStudiotel(studiotelId: number) {
    setOpenRemoveConfirm(true);
    setStudiotelIdToRemove(studiotelId);
  }

  function handleSetupStudiotel(studiotelId: number) {
    const path = replaceParams(
      Endpoint.STUDIOTEL_SETUP,
      [/:studiotelId/],
      [studiotelId.toString()]
    );
    history.push(path);
  }

  return (
    <React.Fragment>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {config.table.columns.map((column: Column) => {
                return (
                  <TableCell
                    align="center"
                    key={column.label}
                    className={classes.label}
                  >
                    <Typography>{column.label}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {studiotels && studiotels.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={config.table.columns.length}
                  style={{ textAlign: 'center' }}
                >
                  Aucun Studioplay
                </TableCell>
              </TableRow>
            )}

            {studiotels &&
              studiotels.map((studiotel: Studiotel) => {
                const studiotelState = getStateByStudiotelId(studiotel.id);

                return (
                  <TableRow key={studiotel.id}>
                    <TableCell align="center">
                      {studiotel.user_configuration &&
                      studiotelState?.connected ? (
                        <PowerRoundedIcon />
                      ) : (
                        <SettingsRoundedIcon />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                    >{`Studioplay - ${studiotel.id}`}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleSetupStudiotel(studiotel.id)}
                      >
                        <BuildRoundedIcon fontSize="small" color="primary" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleRemoveStudiotel(studiotel.id)}
                      >
                        <DeleteRoundedIcon fontSize="small" color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TableFooter component="div" className={classes.footer}>
        <TableRow component="div" className={classes.footerContainer}>
          <div className={classes.action}>
            <IconButton onClick={handleAddStudiotel}>
              <AddCircleRoundedIcon fontSize="small" color="primary" />
            </IconButton>
            <Typography>Ajouter un Studioplay</Typography>
          </div>
        </TableRow>
      </TableFooter>

      <AddDialog open={openAddConfirm} setOpen={setOpenAddConfirm} />
      <RemoveDialog
        open={openRemoveConfirm}
        setOpen={setOpenRemoveConfirm}
        studiotelIdToRemove={studiotelIdToRemove}
        setStudiotelIdToRemove={setStudiotelIdToRemove}
      />
    </React.Fragment>
  );
}

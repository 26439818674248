import { createMuiTheme } from '@material-ui/core';

// Theme customisation
declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    login: {
      background: string;
    };
    appbar: {
      background: string;
    };
    card: {
      background: {
        [key: string]: string;
      };
    };
    borderColor: {
      [key: string]: string;
    };
    playIcon: {
      background: {
        [key: string]: string;
      };
    };
    icon: {
      color: {
        light: string;
        dark: string;
      };
    };
    pbx: {
      global: {
        background: string;
        color: string;
      };
      open: {
        background: string;
        color: string;
      };
      close: {
        background: string;
        color: string;
      };
    };
    dayScaleCellBase: {
      today: {
        background: string;
        color: string;
      };
    };
    header: {
      height: number;
    };
    datePicker: {
      height: number;
    };
    drawer: {
      width: number;
    };
    message: {
      height: string;
      width: string;
      header: {
        height: string;
      };
      content: {
        height: string;
      };
    };
    search: {
      background: string;
    };
    soundUpload: {
      background: string;
      button: {
        background: string;
      };
    };
    workflow: {
      background: {
        light: string;
        main: string;
        dark: string;
      };
      content: {
        background: string;
      };
      errors: {
        background: string;
      };
    };
  }

  // tslint:disable-next-line:align
  interface ThemeOptions {
    appbar?: {
      background?: string;
    };
    card?: {
      background?: {
        [key: string]: string;
      };
    };
    borderColor?: {
      [key: string]: string;
    };
    playIcon?: {
      background?: {
        [key: string]: string;
      };
    };
    icon?: {
      color?: {
        light?: string;
        dark?: string;
      };
    };
    pbx?: {
      global?: {
        background?: string;
        color?: string;
      };
      open?: {
        background?: string;
        color?: string;
      };
      close?: {
        background?: string;
        color?: string;
      };
    };
    workflow: {};
  }
}

export const theme = {
  shape: {
    borderRadius: 5,
  },
  palette: {
    common: {
      black: '#000',
      white: 'rgba(255, 255, 255, 1)',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: '#E9EFF1',
    },
    primary: {
      dark: '#000000',
      main: '#231f20',
      light: '#4b4647',
      contrastText: '#fff',
    },
    secondary: {
      dark: '#c7c000',
      main: '#FFF200',
      light: '#ffff57',
      contrastText: '#231f20',
    },
    error: {
      light: 'rgba(185, 45, 0, 1)',
      main: 'rgba(185, 45, 0, 1)',
      dark: 'rgba(185, 45, 0, 1)',
      contrastText: '#fff',
    },
    text: {
      hint: 'rgba(0, 0, 0, 0.38)',
      primary: '#000000',
      disabled: 'rgba(0, 0, 0, 0.38)',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
  },
  login: {
    background: '#808E95',
  },
  appbar: {
    background: 'rgba(186, 189, 190, 1)',
  },
  icon: {
    color: {
      light: 'rgba(128, 142, 149, 0.87)',
      dark: 'rgba(255, 255, 255, 1)',
    },
  },
  card: {
    background: {
      offhook: 'rgba(255, 227, 138, 1)',
      voicemail: 'rgba(148, 201, 254, 1)',
      moh: 'rgba(255, 195, 135, 1)',
    },
  },
  borderColor: {
    offhook: 'rgba(255, 211, 69, 1)',
    voicemail: 'rgba(87, 167, 248, 1)',
    moh: 'rgba(255, 168, 80, 1)',
  },
  playIcon: {
    background: {
      offhook: 'rgba(255, 227, 138, 1)',
      voicemail: 'rgba(148, 201, 254, 1)',
      moh: 'rgba(255, 195, 135, 1)',
    },
  },
  pbx: {
    global: {
      background: 'rgba(255, 255, 255, 1)',
      color: 'rgba(176, 190, 197, 1)',
    },
    open: {
      background: 'rgba(255, 255, 255, 1)',
      color: '#4caf50',
    },
    close: {
      background: 'rgba(255, 255, 255, 1)',
      color: '#2196f3',
    },
  },
  dayScaleCellBase: {
    today: {
      background: 'rgba(128, 142, 149, 0.87)',
      color: 'rgba(255, 255, 255, 1)',
    },
  },
  header: {
    height: 72,
  },
  datePicker: {
    height: 280,
  },
  drawer: {
    width: 350,
  },
  message: {
    height: 120,
    width: 350,
    header: {
      height: 15,
    },
    content: {
      height: 80,
    },
  },
  search: {
    background: 'rgba(245, 245, 245, 1)',
  },
  soundUpload: {
    background: 'rgba(193, 193, 193, 1)',
    button: {
      background: 'rgba(0, 170, 255, 0.87)',
    },
  },
  workflow: {
    background: {
      light: 'rgba(236, 239, 241, 1)',
      main: 'rgba(236, 239, 241, 1)',
      dark: 'rgba(236, 239, 241, 1)',
    },
    content: {
      background: 'rgba(255, 255, 255, 1)',
    },
    errors: {
      background: 'rgba(176, 190, 197, 1)',
    },
  },
  overrides: {
    MuiButton: {
      text: {
        background: 'transparent',
        border: 0,
        color: 'rgba(128, 142, 149, 0.87)',
      },
    },
    MuiStepLabel: {
      label: {
        color: 'rgba(236, 239, 241, 1)',
      },
    },
  },
};

const createTheme = () => createMuiTheme(theme);

export default createTheme;

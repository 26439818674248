import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Dialogs } from '../../config/auth.config';
import { AskResetPasswordFormData } from '../../../../@types/form/auth';
import Loader from '../../../utils/components/loader.component';
import * as authAction from '../../auth.action';
import { selectFetchPending } from '../../auth.selector';

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  title: {
    paddingLeft: theme.spacing(2),
    '& p': {
      fontWeight: 'bold',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& #login-field': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  fields: {
    width: '100%',
  },
  errors: {
    color: theme.palette.error.main,
  },
}));

interface IProps {
  open: Dialogs;
  onClose: Dispatch<SetStateAction<Dialogs>>;
}

export default function ResetPasswordDialog(props: IProps) {
  const { open, onClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
  } = useForm<AskResetPasswordFormData>();
  const fetchPending = useSelector(selectFetchPending);

  async function onSubmit(data: AskResetPasswordFormData) {
    const payload = {
      login: data.login,
    };

    await dispatch(authAction.askResetPasswordLink(payload));
    onClose({ ...open, confirm: false });
  }

  function handleClose() {
    onClose({ ...open, confirm: false });
  }

  return (
    <Dialog
      id="ask-link-reset-password-dialog"
      open={!!open.confirm}
      onClose={handleClose}
      className={classes.container}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle className={classes.title}>
          <Typography color="textSecondary">
            {t('login.resetPassword.confirmAsk')}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <div id="login-field" className={classes.fields}>
            <TextField
              fullWidth
              name="login"
              type="test"
              label={t('login.resetPassword.email')}
              margin="normal"
              autoFocus
              inputRef={register({
                required: true,
                pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
              })}
            />
          </div>
          {errors.login && (
            <FormHelperText className={classes.errors}>
              {t('login.badEmail')}
            </FormHelperText>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>{t('login.action.cancel')}</Button>
          <Button type="submit" variant="outlined">
            {!fetchPending && t('login.action.confirm')}
            {fetchPending && <Loader size={20} />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Tabs as MuiTabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { makeStyles, Theme } from '@material-ui/core/styles';
import config, { TabConfig } from '../../config/appBar.config';
import { selectRole } from '../../../auth/auth.selector';
import { AuthRoute } from '../../../../router/routes.config';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textTransform: 'capitalize',
    '& .MuiTabs-flexContainer': {
      alignItems: 'center',
    },
  },
  tab: {
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiSvgIcon-root:last-child': {
        marginRight: '4px',
        marginBottom: 0,
      },
      '& img': {
        marginRight: '4px',
      },
    },
  },
}));

interface IProps {
  routeMetaData: AuthRoute;
  classe?: string;
}

export default function Tabs(props: IProps) {
  const { classe, routeMetaData } = props;
  const history = useHistory();
  const location = useLocation();

  const [index, setIndex] = React.useState(0);
  const classes = useStyles();
  const role = useSelector(selectRole);

  React.useEffect(() => {
    if (location) {
      setIndex(routeMetaData?.tab || 0);
    }
  }, [location, routeMetaData]);

  function handleChange(event: React.ChangeEvent<{}>, id: number) {
    const selectedTabConfig = config.tabs.find((tab: TabConfig) => {
      return tab.id === id;
    });
    if (selectedTabConfig) {
      history.push(selectedTabConfig.path);
    }
    setIndex(id);
  }

  return (
    <MuiTabs
      id="appbar-tabs"
      value={index}
      className={classe ? clsx(classe, classes.container) : classes.container}
      onChange={handleChange}
    >
      {config.tabs.map((tab: TabConfig) => {
        const display = role
          ? tab.role.includes(role) &&
            routeMetaData.name &&
            tab.tabVisibility.includes(routeMetaData.name)
          : false;

        if (!display) {
          return null;
        }

        return (
          <Tab
            value={tab.id}
            className={classes.tab}
            key={`tab-${tab.id}`}
            label={tab.label}
            disabled={tab.disabled}
            icon={<tab.Icon />}
          />
        );
      })}
    </MuiTabs>
  );
}

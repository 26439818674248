import { State } from '../../@types/state';

export function selectOrganizations(state: State) {
  return state?.organization?.data?.organizations;
}

export function selectOrganizationById(state: State) {
  return function(id: number | undefined) {
    const organizations = selectOrganizations(state);
    const organization = organizations?.find(
      organization => organization.id === id
    );

    return organization;
  };
}

import React from 'react';
import { useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import appConfig from '../../application/app.config';

import {
  selectInitFetch,
  selectFetchPending,
} from '../../modules/hierarchy/hierarchy.selector';
import { selectSearchedStudiotels } from '../../modules/studiotel/studiotel.selector';
import { selectOrganizationById } from '../../modules/organization/organization.selector';

import { Studiotel } from '../../@types/state/studiotel';

import Loader from '../../modules/utils/components/loader.component';
import Search from '../../modules/utils/components/search.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      display: 'flex',
      height: '100%',
    },
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    search: {
      marginTop: theme.spacing(4),
    },
    noResults: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      textAlign: 'center',
    },
  })
);

export default function FleetSearch() {
  const [results, setResults] = React.useState<Studiotel[]>([]);

  const isInitFetch = useSelector(selectInitFetch);
  const isLoading = useSelector(selectFetchPending);
  const classes = useStyles();
  const getSearchedStudiotels = useSelector(selectSearchedStudiotels);
  const getOrganizationById = useSelector(selectOrganizationById);

  function searchAction(search: string) {
    setResults([]);
    const studiotels = getSearchedStudiotels(search);
    if (studiotels && studiotels.length > 0) {
      setResults(studiotels);
    }
  }

  function onItemClick(studiotelId: number) {
    window.open(`/studiotels/${studiotelId}`, '_blank');
  }

  function getResult() {
    return (
      <>
        {results && results.length > 0 && (
          <List id="search-results" component="nav">
            {results.map((result: Studiotel) => {
              const organization = getOrganizationById(result.organization_id);

              return (
                <ListItem
                  key={result.id}
                  button
                  onClick={() => onItemClick(result.id)}
                >
                  <ListItemText
                    primary={`studiotel-${result.id}`}
                    secondary={organization?.name || ''}
                  />
                </ListItem>
              );
            })}
          </List>
        )}

        {results && results.length === 0 && (
          <Typography className={classes.noResults}>Aucun résultat</Typography>
        )}
      </>
    );
  }

  return (
    <div id="search-view" className={classes.view}>
      <div id="search-view-content" className={classes.container}>
        {isInitFetch && isLoading ? (
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Loader />
          </Box>
        ) : (
          <Container className={classes.search}>
            <Search
              placeholder="Rechercher un Studioplay"
              loading={false}
              searchAction={searchAction}
              Results={getResult}
              debouceTime={appConfig.search.debounceTime}
            />
          </Container>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import ConnexionStep from '../components/setup/step/connexion.component';
import AskConfigFileStep from '../components/setup/step/askConfigFile.component';
import NeworkStep from '../components/setup/step/network.component';
import HardwareStep from '../components/setup/step/hardware.component';
import UseConfigFileStep from '../components/setup/step/useConfigFile.component';
import FinalStep from '../components/setup/step/final.component';
import LiveBoxOpo from '../components/setup/step/form/liveBoxOpo.component';
import MittelOffice500 from '../components/setup/step/form/mittelOffice500.component';
import Pabx from '../components/setup/step/form/pabx.component';
import AlcatelOxe from '../components/setup/step/form/alcatelOxe.component';
import AlcatelOxo from '../components/setup/step/form/alcatelOxo.component';

import { EnumMessageType } from '../../../@types/message';

export const enumMessageType: EnumMessageType = {
  offhook: 'PRÉ-DÉCROCHÉ',
  voicemail: 'REPONDEUR',
  moh: 'ATTENTE',
};

export const messageTypes = {
  offhook: 'PRÉ-DÉCROCHÉ',
  voicemail: 'REPONDEUR',
  moh: 'ATTENTE',
};

export enum StudiotelConfig {
  NONE = 'none',
  PABX = 'pabx',
  LIVEBOX_OPO = 'livebox_opo',
  MITEL_OFFICE_500 = 'mitel_office_500',
  ALCATEL_OXO = 'alcatel_oxo',
  ALCATEL_OXE = 'alcatel_oxe',
  AVAYA_IP_OFFICE = 'avaya_ip_office',
}

export enum Steps {
  STEP_1 = 0,
  STEP_2 = 1,
  STEP_3 = 2,
  STEP_4 = 3,
  STEP_5 = 4,
  STEP_6 = 5,
}

export enum ActionSelected {
  TELEVERSE = 'televerse',
  UPLOAD = 'upload',
}

export enum Level {
  NOVICE = 'novice',
  ADVANCED = 'advanced',
}

export interface Step {
  Content: React.ElementType;
  nbStep: number;
  nextStep: null | number;
  previousStep: null | number;
  label: string;
}

export interface Form {
  Form: React.ElementType;
}

export interface RadioList {
  value: StudiotelConfig;
  label: string;
  disabled: boolean;
}

export interface Config {
  steps: { [index: number]: Step };
  radioList: RadioList[];
  form: { [index: string]: Form };
}

const config: Config = {
  steps: {
    [Steps.STEP_1]: {
      Content: (props: any) => <ConnexionStep {...props}></ConnexionStep>,
      nbStep: 6,
      nextStep: Steps.STEP_2,
      previousStep: null,
      label: 'Connexion',
    },
    [Steps.STEP_2]: {
      Content: (props: any) => (
        <AskConfigFileStep {...props}></AskConfigFileStep>
      ),
      nbStep: 6,
      nextStep: Steps.STEP_4,
      previousStep: Steps.STEP_1,
      label: 'Charger un fichier',
    },
    [Steps.STEP_3]: {
      Content: (props: any) => <NeworkStep {...props}></NeworkStep>,
      nbStep: 6,
      nextStep: Steps.STEP_4,
      previousStep: null,
      label: 'Configuration réseau',
    },
    [Steps.STEP_4]: {
      Content: (props: any) => <HardwareStep {...props}></HardwareStep>,
      nbStep: 6,
      nextStep: Steps.STEP_5,
      previousStep: null,
      label: 'Configuration matériel',
    },
    [Steps.STEP_5]: {
      Content: (props: any) => (
        <UseConfigFileStep {...props}></UseConfigFileStep>
      ),
      nbStep: 6,
      nextStep: Steps.STEP_6,
      previousStep: Steps.STEP_4,
      label: 'Mise en service',
    },
    [Steps.STEP_6]: {
      Content: (props: any) => <FinalStep {...props}></FinalStep>,
      nbStep: 6,
      nextStep: null,
      previousStep: Steps.STEP_5,
      label: 'Finalisation',
    },
  },
  radioList: [
    {
      value: StudiotelConfig.LIVEBOX_OPO,
      label: 'Livebox OPO',
      disabled: false,
    },
    {
      value: StudiotelConfig.MITEL_OFFICE_500,
      label: 'Mittel Office 500',
      disabled: false,
    },
    {
      value: StudiotelConfig.PABX,
      label: 'PABX',
      disabled: false,
    },
    {
      value: StudiotelConfig.ALCATEL_OXE,
      label: 'Alcatel-OXE',
      disabled: false,
    },
    {
      value: StudiotelConfig.ALCATEL_OXO,
      label: 'Alcatel-OXO',
      disabled: false,
    },
    {
      value: StudiotelConfig.AVAYA_IP_OFFICE,
      label: 'Avaya IP Office',
      disabled: true,
    },
  ],
  form: {
    [StudiotelConfig.LIVEBOX_OPO]: {
      Form: (props: any) => <LiveBoxOpo {...props} />,
    },
    [StudiotelConfig.MITEL_OFFICE_500]: {
      Form: (props: any) => <MittelOffice500 {...props} />,
    },
    [StudiotelConfig.PABX]: {
      Form: (props: any) => <Pabx {...props} />,
    },
    [StudiotelConfig.ALCATEL_OXO]: {
      Form: (props: any) => <AlcatelOxo {...props} />,
    },
    [StudiotelConfig.ALCATEL_OXE]: {
      Form: (props: any) => <AlcatelOxe {...props} />,
    },
    [StudiotelConfig.AVAYA_IP_OFFICE]: {
      Form: (props: any) => <LiveBoxOpo {...props} />,
    },
  },
};

export default config;

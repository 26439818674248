import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Store } from 'redux';
import 'typeface-roboto';
import './App.css';
import Login from './views/auth/login.view';
import ResetPassword from './views/auth/resetPassword.view';
import Layout from './views/layout.view';
import AuthRoute from './modules/auth/components/route/authRoute.connector';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
  })
);

interface IProps {
  store: Store;
  history: History;
  theme: Theme;
  utils: typeof DateFnsUtils;
  locale: typeof frLocale | null;
}

const App = ({ store, history, theme, utils, locale }: IProps) => {
  const classes = useStyles();

  function handleDragOverCapture(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function handleDropCapture(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  return (
    <>
      <CssBaseline />
      <div
        id="app-root"
        className={classes.root}
        onDragOverCapture={handleDragOverCapture}
        onDropCapture={handleDropCapture}
      >
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={utils} locale={locale}>
                <>
                  <Switch>
                    <Route path={'/login'} component={Login} />
                    <Route path={'/password/new'} component={ResetPassword} />
                    <AuthRoute path={'/'} component={Layout} />
                  </Switch>
                </>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>
      </div>
    </>
  );
};

export default App;

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { OrganizationActionTypes } from './types';
import { OrganisationAction } from './organization.action';

const initialState = {
  pending: false,
  data: {
    organizations: undefined,
  },
  errors: undefined,
};

const organization: Reducer<State['organization'], OrganisationAction> = (
  state: State['organization'] = initialState,
  action: OrganisationAction
) => {
  switch (action.type) {
    case OrganizationActionTypes.GET_ORGANIZATIONS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case OrganizationActionTypes.GET_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    }
    case OrganizationActionTypes.GET_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default organization;

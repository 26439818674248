import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../@types/state';
import * as authAction from '../../../auth/auth.action';
import { AnyAction } from 'redux';
import { getToken } from '../../../auth/auth.selector';

import AppBarMenu from './appBarMenu.component';

export interface DispatchProps {
  logout: () => void;
}

export interface SelectProps {
  token: State['auth']['data']['token'];
}

const mapStateToProps = (state: State) => {
  return {
    token: getToken(state)
  };
};

const mapDispatchToProps = (
  dispach: ThunkDispatch<State, {}, AnyAction>
): DispatchProps => {
  return {
    logout: async () => {
      await dispach(authAction.logout());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBarMenu);

import {
  getAuthenticatedInstance,
  getAuthenticatedInstanceExtras,
} from './helpers/axios.helpers';
import { InProgressDeployment } from '../../@types/deployment';

const authenticatedInstance = getAuthenticatedInstance();
const authenticatedInstanceExtras = getAuthenticatedInstanceExtras();

export async function getDeployments(): Promise<any> {
  return authenticatedInstance.get('/deployments');
}

export async function createDeployment(
  deployment: InProgressDeployment
): Promise<any> {
  const formData = new FormData();
  const deploymentCopy = { ...deployment };

  if (deploymentCopy.sound) {
    formData.append('sound', deploymentCopy.sound);
    delete deploymentCopy.sound;
  }
  formData.append('data', JSON.stringify(deploymentCopy));

  return authenticatedInstanceExtras.post('/create_deployment', formData);
}

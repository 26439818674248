import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import televerseImg from '../../../../../application/images/televerse.png';

import { ActionSelected } from '../../../config/studiotel.config';

import {
  selectStudiotelById,
  selectErrors as selectErrorsStudiotel,
} from '../../../studiotel.selector';

import StepperLayout from '../layout.component';
import Loader from '../../../../utils/components/loader.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minHeight: ' 100%',
    '& #alert': {
      width: '100%',
    },
    '& img': {
      maxWidth: '80%',
    },
  },
  loader: {
    position: 'absolute',
  },
}));

enum InstallationState {
  INSTALLING = 'installing',
  INSTALLED = 'installed',
  ERROR_INSTALL = 'error_install',
}

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  actionSelected: ActionSelected;
  loading: boolean;
  version: string | undefined;
}
interface ParamTypes {
  studiotelId: string;
}

export default function SetupConfigFile(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    actionSelected,
    loading,
    version,
  } = props;
  const { studiotelId } = useParams<ParamTypes>();
  const studiotel = useSelector(selectStudiotelById)(parseInt(studiotelId));
  const errorsStudiotel = useSelector(selectErrorsStudiotel);
  const [
    installationState,
    setInstallationState,
  ] = React.useState<InstallationState>(InstallationState.INSTALLING);
  const classes = useStyles();

  React.useEffect(() => {
    if (installationState === InstallationState.INSTALLED) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [installationState, setStepValid]);

  React.useEffect(() => {
    if (!loading && studiotel && studiotel.user_configuration) {
      setInstallationState(InstallationState.INSTALLED);
    } else {
      setInstallationState(InstallationState.ERROR_INSTALL);
    }
  }, [loading, studiotel]);

  return (
    <StepperLayout
      hintTitle={`Finalisation`}
      hintContent={
        <>
          <Typography variant="caption" color="textSecondary" align="center">
            {actionSelected === ActionSelected.TELEVERSE
              ? 'Votre appareil va redémarrer automatiquement.'
              : 'Votre fichier est prêt à être installer en mode local'}
          </Typography>
        </>
      }
      hintAction={hintAction && hintAction}
      title={`${
        actionSelected === ActionSelected.TELEVERSE
          ? 'Téléversement'
          : 'Téléchargement'
      }`}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        {loading && <Loader classe={classes.loader} />}

        {loading && actionSelected === ActionSelected.TELEVERSE && (
          <img src={televerseImg} alt="televerseImg" />
        )}

        {!loading && actionSelected === ActionSelected.UPLOAD && (
          <Alert id="alert" severity="success">
            <Typography variant="caption" style={{ fontWeight: 'bold' }}>
              Votre fichier de configuration a été généré avec succès !
            </Typography>
            <Typography variant="caption">
              Veuillez l'utiliser ulterieurement pour configurer votre matériel
            </Typography>
          </Alert>
        )}

        {!loading &&
          installationState === InstallationState.INSTALLED &&
          actionSelected === ActionSelected.TELEVERSE && (
            <Alert id="alert" severity="success">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Votre Studioplay a été configuré avec succès !
              </Typography>
              <Typography variant="caption">
                Afin de vérifier si votre Studioplay est bien installé
                assurez-vous que l'icône de branchement apparaisse à côté de
                votre appareil.
              </Typography>
            </Alert>
          )}

        {!loading &&
          errorsStudiotel &&
          installationState === InstallationState.ERROR_INSTALL && (
            <Alert id="alert" severity="error">
              <Typography variant="caption">
                L'installation a rencontré un problème, le Studioplay n'a pas pu
                être installé, veuillez réessayer.
              </Typography>
            </Alert>
          )}
      </div>
    </StepperLayout>
  );
}

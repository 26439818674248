import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import success from './success.svg';
import error from './error.svg';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import * as deploymentAction from '../../../../deployment.action';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  selectErrors,
  selectFetchPending,
} from '../../../../deployment.selector';
import Loader from '../../../../../utils/components/loader.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: (errors) => ({
    background: `${
      errors
        ? theme.workflow.errors.background
        : theme.workflow.content.background
    }`,
  }),
}));

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
}

export default function Step6(props: IProps) {
  const { Controls, setIsStepValid, deployment } = props;
  const dispatch = useDispatch();
  const pending = useSelector(selectFetchPending);
  const errors = useSelector(selectErrors);
  const classes = useStyles(!!errors);

  function getSuccessContent() {
    return (
      <>
        <Box mt={5}>
          <img src={success} alt="success" />
        </Box>
        <Box my={1} fontWeight="fontWeightBold" fontSize={16}>
          Traitement terminé
        </Box>
        <Box my={1} py={1} fontWeight="fontWeightBold">
          FÉLICITATION !
        </Box>
        <Box my={1} p={2}>
          Le message est en cours de mise en place sur les Studioplay concernés.
          Vous serez notifiés dès que les plannings auront été mis à jour.
        </Box>
        <Box px={10}>
          <Controls />
        </Box>
      </>
    );
  }

  function getErrorContent() {
    return (
      <>
        <Box mt={5}>
          <img src={error} alt="error" />
        </Box>
        <Box
          my={1}
          fontWeight="fontWeightBold"
          fontSize={16}
          color="common.white"
        >
          Echec du déploiement
        </Box>
        <Box my={1} py={1} fontWeight="fontWeightBold" color="common.white">
          ERREUR
        </Box>
        <Box my={1} p={2} color="common.white">
          Votre déploiement n’a pu être réalisé car une erreur s’est produit.
          Veuillez ressayer ou contacter le support technique.
        </Box>
        <Box px={10} color="common.white">
          <Controls errors={!!errors} />
        </Box>
      </>
    );
  }

  useEffect(() => {
    dispatch(deploymentAction.createDeployment(deployment));
    setIsStepValid(true);
  }, [deployment, dispatch, setIsStepValid]);

  return (
    <>
      {pending ? (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: 'translateX(-50%)' }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          maxWidth={290}
          mx="auto"
          justifyContent="center"
          alignContent="center"
          textAlign="center"
          className={classes.container}
        >
          {errors ? getErrorContent() : getSuccessContent()}
        </Box>
      )}
    </>
  );
}

import React from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';

interface IProps {
  label: string | undefined;
  selectableId: number;
  checked: boolean;
  onChange: (selectableId: number) => void;
}

export default function Checkbox(props: IProps) {
  const { label, selectableId, checked, onChange } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <MuiCheckbox
          color="primary"
          checked={checked}
          onClick={(event: React.MouseEvent) => event.stopPropagation()}
          onChange={() => onChange(selectableId)}
        />
      </Box>
      <Box flexGrow={1}>{label}</Box>
    </Box>
  );
}

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Message from '../../../studiotel/components/currentMessages/message/message.connector';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    planningInfo: {
      marginTop: theme.spacing(1),
    },
  })
);
// TODO : find the correct type
interface IProps {
  appointmentData?: any;
}
const ToolTipContent: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { appointmentData } = props;

  return (
    <AppointmentTooltip.Content
      className={classes.root}
      appointmentData={appointmentData}
    >
      <Message
        sounds={appointmentData.sounds}
        messageType={appointmentData.type}
        isDefault={appointmentData.is_default}
      />
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-end"
        className={classes.planningInfo}
      >
        <Grid item>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="caption">
                <Box fontWeight="fontWeightBold">A partir du : </Box>
              </Typography>
            </Grid>
            <Grid item>
              {appointmentData && appointmentData.start_time ? (
                <Typography variant="caption">
                  {moment(appointmentData.start_time).format(
                    'DD/MM/YYYY à HH:mm:ss'
                  )}
                </Typography>
              ) : (
                <Typography variant="caption"> - </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="caption">
                <Box fontWeight="fontWeightBold">Jusqu'au : </Box>
              </Typography>
            </Grid>
            <Grid item>
              {appointmentData && appointmentData.stop_time ? (
                <Typography variant="caption">
                  {moment(appointmentData.stop_time).format(
                    'DD/MM/YYYY à HH:mm:ss'
                  )}
                </Typography>
              ) : (
                <Typography variant="caption"> - </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="caption">
                <Box fontWeight="fontWeightBold">De </Box>
              </Typography>
            </Grid>
            <Grid item>
              {appointmentData && appointmentData.startDate ? (
                <Typography variant="caption">
                  {moment(appointmentData.startDate).format('HH:mm:ss')}
                </Typography>
              ) : (
                <Typography variant="caption"> - </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="caption">
                <Box fontWeight="fontWeightBold">Jusqu'a : </Box>
              </Typography>
            </Grid>
            <Grid item>
              {appointmentData && appointmentData.endDate ? (
                <Typography variant="caption">
                  {moment(appointmentData.endDate).format('HH:mm:ss')}
                </Typography>
              ) : (
                <Typography variant="caption"> - </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {!appointmentData.is_default && <Button disabled>Effacer</Button>}
          <Button disabled>Modifier</Button>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  );
};

export default ToolTipContent;

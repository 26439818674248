import { History } from 'history';
import { State } from '../@types/state';

import { routerMiddleware } from 'connected-react-router';
import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  DeepPartial
} from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import createRootReducer from './root.reducer';

const logger = createLogger({
  collapsed: true
});

const createStore = (history: History) => (
  preloadedState: DeepPartial<State> = {}
) => {
  const middlewares = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === `production`) {
    middlewares.push(logger);
  }

  preloadedState.auth = {};
  const savedToken = localStorage.getItem('auth/studiotel');

  if (savedToken) {
    preloadedState.auth.data = JSON.parse(savedToken);
  }

  if (process.env.NODE_ENV === `development`) {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createReduxStore(
      createRootReducer(history),
      preloadedState,
      composeEnhancers(applyMiddleware(...middlewares))
    );
  }

  return createReduxStore(
    createRootReducer(history),
    preloadedState,
    applyMiddleware(...middlewares)
  );
};

export default createStore;

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { MapSound, Sound } from '../../@types/state/sound';
import * as soundServiceApi from '../../services/api/sound.service';
import { SoundActionTypes } from './types';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';

export interface GetSoundsForAnOrganizationIdPending
  extends Action<SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_PENDING> {
  pending: boolean;
}

export interface GetSoundsForAnOrganizationIdSuccess
  extends Action<SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_SUCCESS> {
  data: State['sound']['data'];
}

export interface GetSoundsForAnOrganizationIdError
  extends Action<SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_ERROR> {
  message: any;
}

export type SoundAction =
  | GetSoundsForAnOrganizationIdPending
  | GetSoundsForAnOrganizationIdSuccess
  | GetSoundsForAnOrganizationIdError;

const getSoundsForAnOrganizationIdPending = (
  pending: boolean
): GetSoundsForAnOrganizationIdPending => {
  return {
    type: SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_PENDING,
    pending
  };
};

const getSoundsForAnOrganizationIdSuccess = (
  data: State['sound']['data']
): GetSoundsForAnOrganizationIdSuccess => {
  return {
    type: SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_SUCCESS,
    data
  };
};

const getSoundsForAnOrganizationIdError = (
  message: string
): GetSoundsForAnOrganizationIdError => {
  return {
    type: SoundActionTypes.GET_SOUNDS_FOR_AN_ORGANIZATION_ID_ERROR,
    message
  };
};

export const getSoundsForAnOrganizationId = (
  organizationId: number
): ThunkAction<Promise<void>, State, {}, SoundAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, SoundAction>
  ): Promise<void> => {
    dispatch(getSoundsForAnOrganizationIdPending(true));
    try {
      const response = await soundServiceApi.getSoundsForAnOrganizationId(
        organizationId
      );
      await waitFor(appConfig.waitForTime);

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapSound, currentValue: Sound) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        dispatch(
          getSoundsForAnOrganizationIdSuccess({ sounds: dataNormalized })
        );
      }
    } catch (error) {
      dispatch(getSoundsForAnOrganizationIdError(error.message));
    }
  };
};

import React, { useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import { Step as MuiStep } from '@material-ui/core/';
import StepLabel from '@material-ui/core/StepLabel';
import config, { Step, StepWorkflow } from '../../config/deployment.config';
import { getStepContent } from '../../helpers';
import { InProgressDeployment } from '../../../../@types/deployment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    contentContainer: (height: number) => ({
      height: height,
    }),
    content: {
      height: '100%',
      padding: '1% 15%',
    },
    stepper: {
      padding: theme.spacing(2),
      '& span': {
        fontSize: theme.typography.caption.fontSize,
      },
      '& .MuiStepLabel-label': {
        marginTop: theme.spacing(1),
      },
    },
  })
);

interface IProps {
  height: number;
}

export default function DeploymentWorkflow(props: IProps) {
  const { height } = props;
  const [stepperHeight, setStepperHeight] = useState(0);
  const classes = useStyles(height - stepperHeight);
  const stepperEl = useRef<null | HTMLDivElement>();
  const [activeStep, setActiveStep] = useState(StepWorkflow.STEP_1);
  const [isStepValid, setIsStepValid] = useState(false);
  const [deployment, setDeployment] = useState<InProgressDeployment>({
    description: '',
    planning: {
      is_default: true,
      start_time: null,
      stop_time: null,
      week_days: [
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
        [{ stop_time: '23:59:59', start_time: '00:00:00' }],
      ],
    },
  });
  const { steps } = config.deployment.workflow;

  React.useEffect(() => {
    const { current } = stepperEl;
    if (current) {
      setStepperHeight(current.clientHeight);
    }
  }, []);

  return (
    <div id="deployment-workflow" className={classes.container}>
      <Stepper
        id="stepper"
        className={classes.stepper}
        ref={stepperEl}
        activeStep={activeStep}
        alternativeLabel
      >
        {steps &&
          steps.map((step: Step) => (
            <MuiStep key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </MuiStep>
          ))}
      </Stepper>
      <div
        id="deployment-workflow-content-container"
        className={classes.contentContainer}
      >
        <div id="content-wrapper" className={classes.content}>
          {getStepContent(
            activeStep,
            setActiveStep,
            steps,
            isStepValid,
            setIsStepValid,
            deployment,
            setDeployment
          )}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { matchPath } from 'react-router';
import FleetSearch from '../views/fleet/search.view';
import Fleet from '../views/fleet/fleet.view';
import FleetAddStudiotel from '../views/fleet/addStudiotel.view';
import NotFound from '../views/notFound.view';
import Studiotel from '../views/studiotel/studiotel.view';
import StudiotelSetup from '../views/studiotel/setup.view';
import Deployments from '../views/deployment/deployments.view';
import DeploymentCreate from '../views/deployment/deploymentCreate.view';
import ChangePassword from '../views/auth/changePassword.view';
import { Redirect } from 'react-router-dom';

export enum Endpoint {
  ROOT = '/',
  LOGIN = '/login',
  FLEET_SEARCH = '/fleet',
  FLEET = '/fleet/:organizationId',
  FLEET_ADD_STUDIOTEL = '/fleet/:organizationId/add',
  DEPLOYMENTS = '/deployments',
  DEPLOYMENT_CREATE = '/deployments/create',
  DEPLOYMENT = '/deployments/:deploymentId',
  HISTORY = '/history',
  PARAMS = '/params',
  CONTACT = '/contact',
  STUDIOTELS = '/studiotels',
  STUDIOTEL = '/studiotels/:studiotelId',
  STUDIOTEL_SETUP = '/studiotels/:studiotelId/setup',
  CHANGE_PASSWORD = '/changePassword',
}

export enum RouteName {
  FLEET_SEARCH = 'Flotte',
  FLEET = `Détail de l'organization`,
  FLEET_ADD_STUDIOTEL = `Identification du Studioplay`,
  DEPLOYMENT = 'Déploiements',
  DEPLOYMENT_CREATE = 'Déploiement de message',
  HISTORY = 'Historique',
  PARAMS = 'Paramètres',
  CONTACT = 'Contact',
  STUDIOTEL = 'Programmation',
  STUDIOTEL_SETUP = 'Configuration du Studioplay',
  CHANGE_PASSWORD = 'Création de nouveau mot de passe',
}

export interface AuthRoute {
  path: string;
  component: any;
  name?: string;
  tab?: number;
  tabVisibility?: string[];
  appBar: boolean;
  drawer: boolean;
  params?: any;
  disabled?: boolean;
}

export enum RouteTabIndex {
  FLEET = 0,
  DEPLOYMENT = 1,
  HISTORY = 2,
  PARAMS = 3,
  CONTACT = 4,
  STUDIOTEL = 5,
}

export const HEADER_TABS_STANDARD = [
  RouteName.FLEET_SEARCH,
  RouteName.FLEET,
  RouteName.FLEET_ADD_STUDIOTEL,
  RouteName.DEPLOYMENT,
  RouteName.DEPLOYMENT_CREATE,
  RouteName.HISTORY,
  RouteName.PARAMS,
  RouteName.CONTACT,
  RouteName.STUDIOTEL_SETUP,
];

export const HEADER_TABS_STUDIOTEL = [RouteName.STUDIOTEL];

export const authRoutes: AuthRoute[] = [
  {
    path: Endpoint.CHANGE_PASSWORD,
    component: <ChangePassword />,
    name: RouteName.FLEET_SEARCH,
    appBar: false,
    drawer: false,
  },
  {
    path: Endpoint.FLEET_ADD_STUDIOTEL,
    component: <FleetAddStudiotel />,
    appBar: true,
    drawer: false,
    tab: 0,
    name: RouteName.FLEET_ADD_STUDIOTEL,
    tabVisibility: HEADER_TABS_STANDARD,
  },
  {
    path: Endpoint.FLEET_SEARCH,
    component: <FleetSearch />,
    appBar: true,
    drawer: true,
    name: RouteName.FLEET_SEARCH,
    tab: 0,
    tabVisibility: HEADER_TABS_STANDARD,
  },
  {
    path: Endpoint.FLEET,
    component: <Fleet />,
    appBar: true,
    drawer: true,
    tab: 0,
    name: RouteName.FLEET,
    tabVisibility: HEADER_TABS_STANDARD,
  },
  {
    path: Endpoint.STUDIOTEL_SETUP,
    component: <StudiotelSetup />,
    appBar: true,
    drawer: false,
    name: RouteName.STUDIOTEL_SETUP,
    tab: 0,
    tabVisibility: HEADER_TABS_STANDARD,
  },
  {
    path: Endpoint.STUDIOTEL,
    component: <Studiotel />,
    appBar: true,
    drawer: false,
    name: RouteName.STUDIOTEL,
    tab: 2,
    tabVisibility: HEADER_TABS_STUDIOTEL,
    disabled: true,
  },
  {
    path: Endpoint.DEPLOYMENTS,
    component: <Deployments />,
    appBar: true,
    drawer: false,
    tab: 1,
    name: RouteName.DEPLOYMENT,
    tabVisibility: HEADER_TABS_STANDARD,
  },
  {
    path: Endpoint.DEPLOYMENT_CREATE,
    component: <DeploymentCreate />,
    appBar: true,
    drawer: false,
    name: RouteName.DEPLOYMENT_CREATE,
  },
  {
    path: Endpoint.ROOT,
    appBar: true,
    drawer: true,
    component: <Redirect to={Endpoint.FLEET_SEARCH} />,
  },
  {
    path: '*',
    appBar: false,
    drawer: false,
    component: <NotFound />,
  },
];

export function getRouteMetaDataFromPathName(pathName: string) {
  for (const authRoute of authRoutes) {
    const match = matchPath(pathName, authRoute.path);
    if (match) {
      authRoute.params = match.params;

      return authRoute;
    }
  }
}

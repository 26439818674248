import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { StudiotelActionTypes } from './types';
import { StudiotelAction } from './studiotel.action';

const initialState = {
  pending: false,
  data: {
    studiotels: undefined,
    studiotelsState: undefined,
    unassociatedStudiotel:[]
  },
  currentStudiotelId: undefined,
  errors: undefined,
};

const studiotel: Reducer<State['studiotel'], StudiotelAction> = (
  state: State['studiotel'] = initialState,
  action: StudiotelAction
) => {
  switch (action.type) {
    case StudiotelActionTypes.GET_STUDIOTELS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case StudiotelActionTypes.GET_STUDIOTELS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          studiotels: action.data,
        },
      };
    }
    case StudiotelActionTypes.GET_STUDIOTELS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case StudiotelActionTypes.GET_STUDIOTELS_STATE_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case StudiotelActionTypes.GET_STUDIOTELS_STATE_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          studiotelsState: action.data,
        },
      };
    }
    case StudiotelActionTypes.GET_STUDIOTELS_STATE_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case StudiotelActionTypes.SET_CURRENT_STUDIOTEL: {
      return {
        ...state,
        currentStudiotelId: action.currentStudiotelId,
      };
    }
    case StudiotelActionTypes.ASSOCIATE_STUDIOTEL_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case StudiotelActionTypes.ASSOCIATE_STUDIOTEL_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
        },
      };
    }
    case StudiotelActionTypes.ASSOCIATE_STUDIOTEL_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          unassociatedStudiotel: action.unassociatedStudiotel
        },
      };
    }
    case StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case StudiotelActionTypes.UPDATE_STUDIOTEL_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case StudiotelActionTypes.UPDATE_STUDIOTEL_SUCCESS: {

      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
        },
      };
    }
    case StudiotelActionTypes.UPDATE_STUDIOTEL_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default studiotel;

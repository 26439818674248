import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { findChildrenIds } from '../../helpers';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Box from '@material-ui/core/Box';

import { Endpoint } from '../../../../router/routes.config';
import { replaceParams } from '../../../../router/routes.helpers';

import { selectTreeItemsFromAnOrganizationId } from '../../../hierarchy/hierarchy.selector';

import { TreeItems, Options } from '../../../../@types/hierarchy';

import CheckBox from './checkBox.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
    },
    content: {
      color: theme.palette.text.primary,
    },
  })
);

interface IProps {
  treeItem: TreeItems;
  children: JSX.Element[] | undefined;
  options: Options;
}

export default function OrganizationItem(props: IProps) {
  const { treeItem, children, options } = props;
  const { activeClick, selectedItems, setSelectedItems, onChange } = options;
  const [checked, setChecked] = useState(false);
  const getTreeItemsFromAnOrganizationId = useSelector(
    selectTreeItemsFromAnOrganizationId
  );
  const history = useHistory();

  useEffect(() => {
    const isChecked = !!selectedItems?.includes(treeItem.id);
    setChecked(isChecked);
  }, [selectedItems, treeItem.id]);

  const classes = useStyles();

  function handleOnChange(organisationId: number) {
    if (selectedItems && setSelectedItems) {
      const isChecked = selectedItems?.includes(treeItem.id);
      // search every child starting form the organisationId to act on it
      const treeItems = getTreeItemsFromAnOrganizationId(organisationId);
      const childIds = findChildrenIds(treeItems);

      if (isChecked) {
        // unselect
        const newSelectedItems = [...selectedItems];
        childIds.forEach((id: number) => {
          const indexForDelete = newSelectedItems.findIndex(
            (element) => element === id
          );
          newSelectedItems.splice(indexForDelete, 1);
          setSelectedItems(newSelectedItems);
          onChange && onChange(newSelectedItems);
        });
      } else {
        // select
        setSelectedItems([...new Set([...selectedItems, ...childIds])]);
        onChange && onChange([...new Set([...selectedItems, ...childIds])]);
      }
    }
  }

  function handleClick(organisationId: number) {
    if (activeClick) {
      const path = replaceParams(
        Endpoint.FLEET,
        [/:organizationId/],
        [organisationId.toString()]
      );
      history.push(path);
    }
  }

  return (
    <TreeItem
      key={`organization-${treeItem.id}`}
      nodeId={`organization-${treeItem.id}`}
      classes={{
        root: classes.root,
        content: classes.content,
      }}
      {...{
        ...(options.checkbox && {
          label: (
            <CheckBox
              key={`checkbox-organization-${treeItem.id}`}
              label={treeItem.name}
              selectableId={treeItem.id}
              onChange={handleOnChange}
              checked={checked}
            />
          ),
        }),
        ...(!options.checkbox && {
          label: (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>{`${treeItem.name}`}</Box>
              {treeItem.alert && <WarningRoundedIcon />}
            </Box>
          ),
        }),
      }}
      children={children}
      onClick={() => handleClick(treeItem.id)}
    />
  );
}

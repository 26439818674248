import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { Steps, Level } from '../../../config/studiotel.config';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '& svg': {
      fontSize: 80,
      marginRight: theme.spacing(2),
    },
  },
}));

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setLevel: React.Dispatch<React.SetStateAction<Level>>;
}

export default function ExitSetupDialog(props: IProps) {
  const { open, setOpen, setActiveStep, setLevel } = props;
  const classes = useStyles();

  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    setLevel(Level.ADVANCED);
    setActiveStep(Steps.STEP_3);
  }

  return (
    <Dialog id="exit-setup-dialog" open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText
          id="exit-setup-description"
          className={classes.content}
        >
          <WarningRoundedIcon fontSize="large" color="primary" />
          Cette espace est réservé à nos techniciens ou à nos utilisateurs
          expérimentés, êtes-vous sure de vouloir continuer ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          non
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
          autoFocus
        >
          oui
        </Button>
      </DialogActions>
    </Dialog>
  );
}

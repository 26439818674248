import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Layout from '../layout.component';
import Aside from '../aside.component';
import svg from './img_step3.svg';
import Loader from '../../../../../utils/components/loader.component';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import Hierachy from '../../../../../hierarchy/components/hierarchy.component';
import { getTreeItemsFromData } from '../../../../../hierarchy/helpers';
import {
  selectFetchPending,
  selectFleetTopLevelFromAnOrganizationId,
} from '../../../../../hierarchy/hierarchy.selector';
import { selectDeploymentOrganizationId } from '../../../../deployment.selector';
import { selectOrganizationById } from '../../../../../organization/organization.selector';
import { selectStudiotelByOrganizationIds } from '../../../../../studiotel/studiotel.selector';
import { Studiotel } from '../../../../../../@types/state/studiotel';
import Upload from './upload.component';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
  })
);

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
}

export default function Step3(props: IProps) {
  const { Controls, deployment, setDeployment, setIsStepValid } = props;
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [inputMethod, setInputMethod] = useState('manual');
  const pending = useSelector(selectFetchPending);
  const selectedOrganizationId = useSelector(selectDeploymentOrganizationId);
  const getStudiotelsByOrganizationIds = useSelector(
    selectStudiotelByOrganizationIds
  );
  const getOrganizationById = useSelector(selectOrganizationById);
  const selectedOrganization = getOrganizationById(selectedOrganizationId);
  const getFleetTopLevelFromAnOrganizationId = useSelector(
    selectFleetTopLevelFromAnOrganizationId
  );
  const topLevelNode = getFleetTopLevelFromAnOrganizationId(
    selectedOrganizationId
  );
  const classes = useStyles();

  function handleOnChange(organizationIds: number[]) {
    const studiotels = getStudiotelsByOrganizationIds(organizationIds);
    const studiotelsIds = studiotels?.map(
      (studiotel: Studiotel) => studiotel.id
    );
    const deploymentUpdated = {
      ...deployment,
      studiotels_ids: studiotelsIds,
    };

    setDeployment(deploymentUpdated);

    if (studiotelsIds && studiotelsIds.length > 0) {
      setIsStepValid(true);
    } else {
      setIsStepValid(false);
    }
  }

  function handleInputMethodChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputMethod((event.target as HTMLInputElement).value);
  }

  return (
    <Layout
      title="Échelle du déploiement"
      aside={
        <Aside
          svg={svg}
          title="Échelle du déploiement"
          description="Vous pouvez choisir l’échelle à laquelle devra s’appliquer le message."
        />
      }
      controls={<Controls />}
    >
      {pending ? (
        <Loader />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          overflow="hidden"
        >
          <Box
            my={1}
            fontSize="h6.fontSize"
            textAlign="left"
            fontWeight="fontWeightBold"
          >
            {selectedOrganization?.name}
          </Box>

          <RadioGroup
            className={classes.container}
            value={inputMethod}
            onChange={handleInputMethodChange}
          >
            <FormControlLabel
              value="manual"
              control={<Radio color="primary" />}
              label="Selection manuelle"
            />
            <FormControlLabel
              value="upload"
              control={<Radio color="primary" />}
              disabled
              label="Import de fichier (Excel, csv)"
            />
          </RadioGroup>

          <Box
            display="flex"
            bgcolor="workflow.background.main"
            textAlign="left"
          >
            <Box flexGrow={3}>
              {inputMethod && inputMethod === 'manual' ? (
                <Hierachy expanded={expanded} setExpanded={setExpanded}>
                  {getTreeItemsFromData(topLevelNode, {
                    checkbox: true,
                    activeClick: false,
                    selectedItems,
                    setSelectedItems,
                    onChange: handleOnChange,
                  })}
                </Hierachy>
              ) : (
                <Upload />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { selectStudiotelById } from '../../studiotel.selector';
import { selectOrganizationById } from '../../../organization/organization.selector';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import clsx from 'clsx';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    display: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })
);

interface IProps {
  studiotelId: number;
}

export default function StuditelDisplay(props: IProps) {
  const { studiotelId } = props;
  const getStudiotelById = useSelector(selectStudiotelById);
  const studiotel = getStudiotelById(studiotelId);
  const getOrganizationById = useSelector(selectOrganizationById);
  const organization = getOrganizationById(studiotel?.organization_id);
  const classes = useStyles();

  return (
    <div id="studiotel-display" className={classes.display}>
      {organization &&
        organization.name &&
        `${organization.name}-Studioplay-${studiotelId}`}
    </div>
  );
}

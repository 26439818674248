import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../@types/state';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import * as planningAction from '../../planning.action';

import PlanningDatePicker from './planningDatePicker.component';

export interface DispatchProps {
  setCurrentDate: (currentDate: State['planning']['currentDate']) => void;
}

const mapDispatchToProps = (dispach: ThunkDispatch<State, {}, AnyAction>): DispatchProps => {
  return {
    setCurrentDate: (currentDate: State['planning']['currentDate']) => {
      dispach(planningAction.setCurrentDate(currentDate));
    }
  };
};

export default connect(null, mapDispatchToProps)(PlanningDatePicker);

import { AlertTypes } from '../../../modules/alert/config/alert.config';

export const alertsResponse = new Promise(function(resolve) {
  setTimeout(function() {
    resolve({
      data: [
        {
          id: 1,
          type: AlertTypes.HEARTBEAT,
          description: 'mock alert 1',
          studiotel_id: 9
        },
        {
          id: 42,
          type: AlertTypes.HEARTBEAT,
          description: 'mock alert 42',
          studiotel_id: 9
        }
      ],
      status: 200
    });
  }, 5000);
});

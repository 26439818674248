import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { PbxStatus } from '../../config/planning.config';
import * as planningAction from '../../planning.action';
import { selectPbxStatus } from '../../planning.selector';
import appConfig from '../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    global: {
      background: theme.pbx.global.background,
      color: theme.pbx.global.color,
      border: `solid 1px ${theme.pbx.global.color}`,
      marginRight: theme.spacing(2),
    },
    open: {
      background: theme.pbx.open.background,
      color: theme.pbx.open.color,
      border: `solid 1px ${theme.pbx.open.color}`,
      marginRight: theme.spacing(2),
    },
    close: {
      background: theme.pbx.close.background,
      color: theme.pbx.close.color,
      border: `solid 1px ${theme.pbx.close.color}`,
    },
    globalActive: {
      color: theme.pbx.global.background,
      background: theme.pbx.global.color,
      '&:hover': {
        color: theme.pbx.global.background,
        background: theme.pbx.global.color,
      },
    },
    openActive: {
      color: theme.pbx.open.background,
      background: theme.pbx.open.color,
      '&:hover': {
        color: theme.pbx.open.background,
        background: theme.pbx.open.color,
      },
    },
    closeActive: {
      color: theme.pbx.close.background,
      background: theme.pbx.close.color,
      '&:hover': {
        color: theme.pbx.close.background,
        background: theme.pbx.close.color,
      },
    },
  })
);

export default function PbxFilter() {
  const pbxStatus = useSelector(selectPbxStatus);
  const classes = useStyles();
  const dispach = useDispatch();

  function handleOnClick(status: string) {
    dispach(planningAction.SetPbxStatus(status));
  }

  return (
    <Box display="flex" justifyContent="flex-end" mr={2}>
      {appConfig.featureFlip.pbxStatusGlobal && (
        <Button
          variant="outlined"
          className={
            pbxStatus === PbxStatus.GLOBAL
              ? clsx(classes.global, classes.globalActive)
              : classes.global
          }
          onClick={() => handleOnClick(PbxStatus.GLOBAL)}
        >
          Vue globale
        </Button>
      )}
      <Button
        variant="outlined"
        className={
          pbxStatus === PbxStatus.OPEN
            ? clsx(classes.open, classes.openActive)
            : classes.open
        }
        onClick={() => handleOnClick(PbxStatus.OPEN)}
      >
        Standard ouvert
      </Button>
      <Button
        variant="outlined"
        className={
          pbxStatus === PbxStatus.CLOSE
            ? clsx(classes.close, classes.closeActive)
            : classes.close
        }
        onClick={() => handleOnClick(PbxStatus.CLOSE)}
      >
        Standard fermé
      </Button>
    </Box>
  );
}

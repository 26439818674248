import { Action } from 'redux';
import { State } from '../../@types/state';
import { PlayerActionTypes } from './types';

export interface PlaySound extends Action<PlayerActionTypes.PLAY_SOUND> {
  data: {
    sounds: State['player']['sounds'];
    isPlaying: State['player']['isPlaying'];
    messageType: State['player']['message_type'];
  };
}

export interface SetCurrrentTime
  extends Action<PlayerActionTypes.SET_CURRENT_TIME> {
  currentTime: State['player']['currentTime'];
}

export type PlayerAction = PlaySound | SetCurrrentTime;

export const playSound = (
  sounds: State['player']['sounds'],
  isPlaying: State['player']['isPlaying'],
  messageType: State['player']['message_type']
): PlaySound => {
  return {
    type: PlayerActionTypes.PLAY_SOUND,
    data: {
      sounds,
      isPlaying,
      messageType
    }
  };
};

export const setCurrentTime = (
  currentTime: State['player']['currentTime']
): SetCurrrentTime => {
  return {
    type: PlayerActionTypes.SET_CURRENT_TIME,
    currentTime
  };
};

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  Studiotel,
  MapStudiotel,
  MapState,
  State as StudiotelState,
} from '../../@types/state/studiotel';
import { State } from '../../@types/state';
import * as studiotelServiceApi from './../../services/api/studiotel.service';

import {AssociateStudiotelActionPayload , GetUnassociateStudiotelActionPayload, UpdateStudiotelActionPayload} from '../../@types/action/studiotel';
import {AssociateStudiotelApiPayload, GetUnassociatedStudiotelApiPayload, UpdateStudiotelApiPayload} from '../../@types/api/studiotel';
import { StudiotelActionTypes } from './types';

import { waitFor } from '../../services/api/helpers/various.helpers';
import config from '../../application/app.config';


export type StudiotelAction =
  | GetStudiotelsPending
  | GetStudiotelsSuccess
  | GetStudiotelsError
  | GetStudiotelsStatePending
  | GetStudiotelsStateSuccess
  | GetStudiotelsStateError
  | SetCurrentStudiotel
  | AssociateStudiotelPending
  | AssociateStudiotelSuccess
  | AssociateStudiotelError
  | GetUnassociatedStudiotelPending
  | GetUnassociatedStudiotelSuccess
  | GetUnassociatedStudiotelError
  | UpdateStudiotelPending
  | UpdateStudiotelSuccess
  | UpdateStudiotelError;

export interface GetStudiotelsPending
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_PENDING> {
  pending: boolean;
}

export interface GetStudiotelsSuccess
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_SUCCESS> {
  data: MapStudiotel;
}

export interface GetStudiotelsError
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_ERROR> {
  message: any;
}

const getStudiotelsPending = (pending: boolean): GetStudiotelsPending => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_PENDING, pending };
};

const getStudiotelsSuccess = (data: MapStudiotel): GetStudiotelsSuccess => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_SUCCESS, data };
};

const getStudiotelsError = (message: string): GetStudiotelsError => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_ERROR, message };
};

export const getStudiotels = (): ThunkAction<
  Promise<void>,
  State,
  {},
  StudiotelAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, StudiotelAction>
  ): Promise<void> => {
    dispatch(getStudiotelsPending(true));
    try {
      const response = await studiotelServiceApi.getStudiotels();

      if (response.status === 200) {
        const { data } = response;

        const dataNormalized = data.reduce(
          (accumulator: MapStudiotel, currentValue: Studiotel) => {
            if (
              currentValue.plannings_ids &&
              currentValue.plannings_ids.length > 0
            ) {
              const planningKeys = currentValue.plannings_ids.map(String);

              currentValue.plannings_ids = planningKeys;
            }
            // TODO: remove mock data
            // paris la défense - livebox_opo
            // if (currentValue.id === 2485280132774) {
            //   const config = {
            //     studiotel : {
            //       type: StudiotelConfig.LIVEBOX_OPO,
            //       url: 'http://192.168.1.1',
            //       login: 'admin',
            //       password: '12345',
            //       phone_number: '000'
            //     },
            //     network: {
            //       dhcp: false,
            //       ip: '192.1.2.4',
            //       mask: '255.255.255',
            //       gateway: '216.58.198.195',
            //     },
            //     dns: ['216.58.198.195'],
            //     proxy: {
            //       https: {
            //         server: 'https://everblix.fr',
            //         port: 443,
            //         user: 'erwan',
            //         password: '12345',
            //       },
            //     },
            //      vlan: [
            //       {
            //         id: 42,
            //         dhcp: false,
            //         ip: '192.1.2.3',
            //         mask: '255.255.255',
            //         gateway: '216.58.198.195',
            //       },
            //     ],
            //   }

            //   currentValue.user_configuration = config
            // }
            // part dieu - mitel
            // if (currentValue.id === 2486043938700) {
            //   const config = {
            //     studiotel: {

            //     type: StudiotelConfig.MITEL_OFFICE_500,
            //     url: 'http://192.168.1.1',
            //     login: 'admin',
            //     password: '12345',
            //     phone_number: '000',
            //     offhook_tone: '64',
            //     }
            //   }
            //   currentValue.user_configuration = config
            // }
            // région parisienne - pabx
            // if (currentValue.id === 2762780585670) {
            //   const config = {
            //     studiotel: {

            //     type: StudiotelConfig.PABX,
            //     dtmf: 'D'
            //     }
            //   }
            //   currentValue.user_configuration = config
            // }
            accumulator[currentValue['id']] = currentValue;

            return accumulator;
          },
          {}
        );
        dispatch(getStudiotelsSuccess(dataNormalized));
      }
    } catch (error) {
      dispatch(getStudiotelsError(error.message));
    }
  };
};

export interface GetStudiotelsStatePending
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_STATE_PENDING> {
  pending: boolean;
}

export interface GetStudiotelsStateSuccess
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_STATE_SUCCESS> {
  data: MapState;
}

export interface GetStudiotelsStateError
  extends Action<StudiotelActionTypes.GET_STUDIOTELS_STATE_ERROR> {
  message: any;
}

const getStudiotelsStatePending = (
  pending: boolean
): GetStudiotelsStatePending => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_STATE_PENDING, pending };
};

const getStudiotelsStateSuccess = (
  data: MapState
): GetStudiotelsStateSuccess => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_STATE_SUCCESS, data };
};

const getStudiotelsStateError = (message: string): GetStudiotelsStateError => {
  return { type: StudiotelActionTypes.GET_STUDIOTELS_STATE_ERROR, message };
};

export const getStudiotelsState = (): ThunkAction<
  Promise<void>,
  State,
  {},
  StudiotelAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, StudiotelAction>
  ): Promise<void> => {
    dispatch(getStudiotelsStatePending(true));
    try {
      const response = await studiotelServiceApi.getStudiotelsState();

      if (response.status === 200) {
        const { data } = response;

        const dataNormalized = data.reduce(
          (accumulator: MapState, currentValue: StudiotelState) => {
            accumulator[currentValue['studiotel_id']] = currentValue;

            return accumulator;
          },
          {}
        );

        dispatch(getStudiotelsStateSuccess(dataNormalized));
      }
    } catch (error) {
      dispatch(getStudiotelsStateError(error.message));
    }
  };
};

export interface SetCurrentStudiotel
  extends Action<StudiotelActionTypes.SET_CURRENT_STUDIOTEL> {
  currentStudiotelId: State['studiotel']['currentStudiotelId'];
}

export const setCurrentStudiotel = (
  currentStudiotelId: State['studiotel']['currentStudiotelId']
): SetCurrentStudiotel => {
  return {
    type: StudiotelActionTypes.SET_CURRENT_STUDIOTEL,
    currentStudiotelId,
  };
};


export interface AssociateStudiotelPending
  extends Action<StudiotelActionTypes.ASSOCIATE_STUDIOTEL_PENDING> {
  pending: boolean;
}

export interface AssociateStudiotelSuccess
  extends Action<StudiotelActionTypes.ASSOCIATE_STUDIOTEL_SUCCESS> {
}

export interface AssociateStudiotelError
  extends Action<StudiotelActionTypes.ASSOCIATE_STUDIOTEL_ERROR> {
  message: any;
}

const associateStudiotelPending = (
  pending: boolean
): AssociateStudiotelPending => {
  return { type: StudiotelActionTypes.ASSOCIATE_STUDIOTEL_PENDING, pending };
};

const associateStudiotelSuccess = (
): AssociateStudiotelSuccess => {
  return { type: StudiotelActionTypes.ASSOCIATE_STUDIOTEL_SUCCESS };
};

const associateStudiotelError = (message: string): AssociateStudiotelError => {
  return { type: StudiotelActionTypes.ASSOCIATE_STUDIOTEL_ERROR, message };
};

export const associateStudiotel = (payload : AssociateStudiotelActionPayload): ThunkAction<
  Promise<void>,
  State,
  {},
  StudiotelAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, StudiotelAction>
  ): Promise<void> => {
    dispatch(associateStudiotelPending(true));
    try {

      const apiPayload: AssociateStudiotelApiPayload = {
        organization_id: payload.organization_id,
        studiotel_id: payload.studiotel_id
      }

      const response = await studiotelServiceApi.associateStudiotel(apiPayload);

      if (response.status === 200) {
        dispatch(associateStudiotelSuccess());
      }
    } catch (error) {
      dispatch(associateStudiotelError(error.message));
    }
  };
};


export interface GetUnassociatedStudiotelPending
  extends Action<StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_PENDING> {
  pending: boolean;
}

export interface GetUnassociatedStudiotelSuccess
  extends Action<StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_SUCCESS> {
    unassociatedStudiotel: Studiotel[];
}

export interface GetUnassociatedStudiotelError
  extends Action<StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_ERROR> {
  message: any;
}

const getUnassociatedPending = (
  pending: boolean
): GetUnassociatedStudiotelPending => {
  return { type: StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_PENDING, pending };
};

const getUnassociatedSuccess = ( data:Studiotel[]
): GetUnassociatedStudiotelSuccess => {
  return { type: StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_SUCCESS, unassociatedStudiotel: data };
};

const getUnassociatedError = (message: string): GetUnassociatedStudiotelError => {
  return { type: StudiotelActionTypes.GET_UNASSOCIATED_STUDIOTEL_ERROR, message };
};

export const getUnassociated = (payload : GetUnassociateStudiotelActionPayload): ThunkAction<
  Promise<void>,
  State,
  {},
  StudiotelAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, StudiotelAction>
  ): Promise<void> => {
    dispatch(getUnassociatedPending(true));
    try {

      const apiPayload: GetUnassociatedStudiotelApiPayload = {
        studiotel_id: payload.studiotel_id
      }

      const response = await studiotelServiceApi.getUnassociatedStudiotel(apiPayload);

      if (response.status === 200) {
        const {data} = response;
        dispatch(getUnassociatedSuccess(data));
      }
    } catch (error) {
      dispatch(getUnassociatedError(error.message));
    }
  };
};


export interface UpdateStudiotelPending
  extends Action<StudiotelActionTypes.UPDATE_STUDIOTEL_PENDING> {
  pending: boolean;
}

export interface UpdateStudiotelSuccess
  extends Action<StudiotelActionTypes.UPDATE_STUDIOTEL_SUCCESS> {
}

export interface UpdateStudiotelError
  extends Action<StudiotelActionTypes.UPDATE_STUDIOTEL_ERROR> {
  message: any;
}

const updateStudiotelPending = (
  pending: boolean
): UpdateStudiotelPending => {
  return { type: StudiotelActionTypes.UPDATE_STUDIOTEL_PENDING, pending };
};

const updateStudiotelSuccess = (
): UpdateStudiotelSuccess => {
  return { type: StudiotelActionTypes.UPDATE_STUDIOTEL_SUCCESS };
};

const updateStudiotelError = (message: string): UpdateStudiotelError => {
  return { type: StudiotelActionTypes.UPDATE_STUDIOTEL_ERROR, message };
};

export const updateStudiotel = (payload : UpdateStudiotelActionPayload, studiotelId:number): ThunkAction<
  Promise<void>,
  State,
  {},
  StudiotelAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, StudiotelAction>
  ): Promise<void> => {
    dispatch(updateStudiotelPending(true));
    try {

      const apiPayload: UpdateStudiotelApiPayload = {
        ...('organization_id' in payload && {organization_id: payload.organization_id }),
        ...('user_configuration' in payload && {user_configuration: payload.user_configuration })
      }

      const response = await studiotelServiceApi.updateStudiotel(apiPayload, studiotelId);

      await waitFor(config.waitForTimeSetup);

      if (response.status === 200 || response.status === 204) {
        dispatch(updateStudiotelSuccess());
      }
    } catch (error) {
      dispatch(updateStudiotelError(error.message));
    }
  };
};

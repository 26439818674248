import { Reducer } from 'redux';
import { State } from '../../@types/state';

import { PlayerActionTypes } from './types';
import { PlayerAction } from './player.action';

const initialState = {
  isPlaying: false,
  sounds: null,
  message_type: null,
  currentTime: null
};

const player: Reducer<State['player'], PlayerAction> = (
  state: State['player'] = initialState,
  action: PlayerAction
) => {
  switch (action.type) {
    case PlayerActionTypes.PLAY_SOUND: {
      return {
        ...state,
        isPlaying: action.data.isPlaying,
        sounds: action.data.sounds,
        message_type: action.data.messageType
      };
    }
    case PlayerActionTypes.SET_CURRENT_TIME: {
      return {
        ...state,
        currentTime: action.currentTime
      };
    }
    default: {
      return state;
    }
  }
};

export default player;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { ResetPasswordFormData } from '../../../../@types/form/auth';
import { ValidResetPasswordPayload } from '../../../../@types/auth';
import * as authAction from '../../auth.action';
import { selectHasAuthErrors } from '../../auth.selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',

      width: '50%',
      padding: theme.spacing(3),
      background: theme.palette.background.paper,
    },
    fields: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '70%',
    },
    errors: {
      color: theme.palette.error.main,
    },
    submit: {
      marginTop: theme.spacing(2),
      maxWidth: ' 50%',
    },
  })
);

interface IProps {
  classe?: string;
}

export default function ResetPasswordForm(props: IProps) {
  const { classe } = props;
  const {
    register,
    handleSubmit,
    watch,
    errors,
  } = useForm<ResetPasswordFormData>();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAuthErrors = useSelector(selectHasAuthErrors);

  function onSubmit(data: ResetPasswordFormData) {
    const newPassword: ValidResetPasswordPayload = {
      password: data.password,
    };
    dispatch(authAction.resetPassword(newPassword));
  }

  return (
    <form
      id="resetPassword-form"
      onSubmit={handleSubmit(onSubmit)}
      className={`${
        classe ? clsx(classes.container, classe) : classes.container
      }`}
    >
      <Typography
        variant="h5"
        gutterBottom
        color="textSecondary"
        style={{ fontWeight: 'bold' }}
      >
        {t('login.resetPassword.title')}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {t('login.resetPassword.text')}
      </Typography>

      <div id="reset-password-form-fields" className={classes.fields}>
        <TextField
          name="password"
          type={'password'}
          label={t('login.resetPassword.newPassword')}
          margin="normal"
          inputRef={register({ required: true })}
        />
        {errors.password && (
          <FormHelperText className={classes.errors}>
            {t('login.passRequired')}
          </FormHelperText>
        )}

        <TextField
          name="confirmPassword"
          type={'password'}
          label={t('login.resetPassword.confirmPassword')}
          margin="normal"
          inputRef={register({
            required: true,
            validate: (value) => value === watch('password'),
          })}
        />
        {errors.confirmPassword && (
          <FormHelperText className={classes.errors}>
            {t('login.resetPassword.fieldsErrors')}
          </FormHelperText>
        )}

        {hasAuthErrors && (
          <FormHelperText className={classes.errors}>
            {t('login.notAllowed')}
          </FormHelperText>
        )}
      </div>

      <Button variant="outlined" type="submit" className={classes.submit}>
        {t('login.action.confirm')}
      </Button>
    </form>
  );
}

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { DeploymentActionTypes } from './types';
import { DeploymentAction } from './deployment.action';

const initialState = {
  pending: false,
  data: {
    deployments: undefined,
  },
  selectedOrganization: -1,
  errors: undefined,
  initFetch: true,
};

const deployment: Reducer<State['deployment'], DeploymentAction> = (
  state: State['deployment'] = initialState,
  action: DeploymentAction
) => {
  switch (action.type) {
    case DeploymentActionTypes.GET_DEPLOYMENTS_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case DeploymentActionTypes.GET_DEPLOYMENTS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case DeploymentActionTypes.GET_DEPLOYMENTS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case DeploymentActionTypes.CREATE_DEPLOYMENT_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case DeploymentActionTypes.CREATE_DEPLOYMENT_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case DeploymentActionTypes.CREATE_DEPLOYMENT_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case DeploymentActionTypes.SET_DEPLOYMENT_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.organizationId,
      };
    }
    case DeploymentActionTypes.RESET_DEPLOYMENT_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default deployment;

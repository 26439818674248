import { State } from '../../@types/state';
import decodeJwt from '../utils/jwt';
import { Role } from '../../application/roles';

export function selectFetchPending(state: State): boolean {
  return state.auth.pending;
}

export function selectHasAuthErrors(state: State): boolean {
  const errors = state.auth.errors;

  return !!errors;
}

export const getToken = (state: State): string | null => {
  if (!isAuth(state)) {
    return null;
  }

  const token = state.auth.data.token ? state.auth.data.token : null;

  return token;
};

export const isAuth = (state: State): boolean => {
  const token =
    state.auth.data && state.auth.data.token ? state.auth.data.token : null;

  if (!token) return false;

  const payload = decodeJwt(token);

  if (!payload || !payload.exp) return false;

  const { exp } = payload;
  const expireAt = exp * 1000;
  const now = Date.now();

  if (expireAt > now) return true;

  return false;
};

export const selectErrorMsg = (state: State): string | undefined => {
  return state.auth.errors ? state.auth.errors : undefined;
};

export function selectUserOrganization(state: State) {
  return state.auth.data.organizationId;
}

export function selectRole(state: State): Role | null {
  const { role } = state.auth.data;

  if (!role) return null;

  return role;
}

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { StudiotelConfigFile } from '../../stepper.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    maxHeight: `calc(100vh - ${350}px)`,
    overflow: 'auto',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    minWidth: 500,
  },
}));

interface IProps {
  configFile: StudiotelConfigFile;
  setConfigFile: React.Dispatch<React.SetStateAction<StudiotelConfigFile>>;
}

export default function Form(props: IProps) {
  const { configFile, setConfigFile } = props;
  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    setConfigFile({
      ...configFile,
      studiotel: { ...configFile.studiotel, [name]: value },
    });
  }

  return (
    <div className={classes.formContent}>
      <TextField
        name="dtmf"
        label="Code DTMF"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="Sélectionner le son DTMF pour synchroniser le PABX (0-1-2-3-4-5-6-7-8-9-A-B-C-D-*-#)"
        value={configFile?.studiotel?.dtmf || ''}
        onChange={handleChange}
      />
    </div>
  );
}

import axios from 'axios';
import { injectToken } from '../interceptor/interceptor';

const API_URL = process.env.REACT_APP_API_SERVER;
const API_URL_EXTRAS = process.env.REACT_APP_API_SERVER_EXTRAS;

export function getInstance() {
  return axios.create({
    baseURL: API_URL
  });
}

export function getAuthenticatedInstance() {
  const instance = axios.create({
    baseURL: API_URL
  });

  instance.interceptors.request.use(injectToken);
  // instance.interceptors.response.use(
  //   response => {
  //     return response;
  //   },
  //   error => {
  //     if (error.response.status === 401) {
  //       // TODO: redirect login
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  return instance;
}

export function getAuthenticatedInstanceExtras() {
  const instance = axios.create({
    baseURL: API_URL_EXTRAS,
    headers: { 'Content-type': 'multipart/form-data' }
  });

  instance.interceptors.request.use(injectToken);

  return instance;
}

import { State } from '../../@types/state';
import { Deployment, MapDeployment } from '../../@types/state/deployment';
import config from './config/deployment.config';

export function selectInitFetch(state: State): boolean {
  return state.deployment.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.deployment.pending;
}

export function selectErrors(state: State): string | undefined {
  return state.deployment.errors;
}

export function selectDeployments(state: State): MapDeployment | undefined {
  const { deployments } = state.deployment.data;

  return deployments;
}

export function selectInProgressDeploymentsList(state: State): Deployment[] {
  const deployments = selectDeployments(state);

  if (deployments === undefined) return [];

  const deploymentsList = Object.values(deployments);
  const filteredDeployment = deploymentsList.filter(
    (deployment: Deployment) => {
      const { status } = deployment;

      return config.deployment.inProgressStatus.includes(status);
    }
  );

  return filteredDeployment;
}

export function selectCompletedDeploymentsList(state: State): Deployment[] {
  const deployments = selectDeployments(state);

  if (deployments === undefined) return [];

  const deploymentsList = Object.values(deployments);
  const filteredDeployment = deploymentsList.filter(
    (deployment: Deployment) => {
      const { status } = deployment;

      return config.deployment.completedStatus.includes(status);
    }
  );

  return filteredDeployment;
}

export function selectDeploymentOrganizationId(state: State): number {
  return state.deployment.selectedOrganization;
}

import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Step, StepWorkflow } from '../../../config/deployment.config';
import { push } from 'connected-react-router';
import { Endpoint } from '../../../../../router/routes.config';
import { InProgressDeployment } from '../../../../../@types/deployment';
import { resetDeploymentErrors } from '../../../deployment.action';
import { selectisPlaying } from '../../../../player/player.selector';
import * as playerAction from '../../../../player/player.action';

const useStyles = makeStyles((theme: Theme) => ({
  next: (errors) => ({
    color: `${
      errors === true ? theme.palette.common.white : theme.palette.text.primary
    }`,
  }),
  back: {
    marginRight: theme.spacing(1),
  },
}));

export interface IProps {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  steps: Step[];
  isStepValid: boolean;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
  errors?: boolean;
}

export default function Controls(props: IProps) {
  const {
    activeStep,
    setActiveStep,
    steps,
    isStepValid,
    setIsStepValid,
    deployment,
    setDeployment,
    errors,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles(errors);
  const lastStep = activeStep === steps.length - 1;
  const isPlaying = useSelector(selectisPlaying);

  function handleNext() {
    if (isPlaying) {
      dispatch(playerAction.playSound(null, false, null));
    }

    if (lastStep) {
      dispatch(resetDeploymentErrors());
      dispatch(push(Endpoint.DEPLOYMENTS));
    } else {
      setActiveStep(activeStep + 1);
      setIsStepValid(false);
    }
  }

  function handleBack() {
    if (isPlaying) {
      dispatch(playerAction.playSound(null, false, null));
    }
    if (activeStep === StepWorkflow.STEP_2) {
      const deploymentUpdated = {
        ...deployment,
      };

      delete deploymentUpdated.sound;
      delete deploymentUpdated.sound_id;
      delete deploymentUpdated.sound_title;
      delete deploymentUpdated.sound_description;
      delete deploymentUpdated.sound_path;

      setDeployment(deploymentUpdated);
      setIsStepValid(false);
    }

    if (activeStep === StepWorkflow.STEP_3) {
      const deploymentUpdated = {
        ...deployment,
        studiotels_ids: [],
      };

      setDeployment(deploymentUpdated);
      setIsStepValid(false);
    }

    if (activeStep === StepWorkflow.STEP_5) {
      const deploymentUpdated = {
        ...deployment,
        planning: {
          is_default: true,
          start_time: null,
          stop_time: null,
          week_days: [
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
            [{ stop_time: '23:59:59', start_time: '00:00:00' }],
          ],
        },
      };
      setDeployment(deploymentUpdated);
    }

    setActiveStep(activeStep - 1);
  }

  return (
    <>
      {!lastStep && (
        <Button
          color="primary"
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.back}
        >
          Précédent
        </Button>
      )}

      <Button
        color="primary"
        disabled={!isStepValid}
        onClick={handleNext}
        className={classes.next}
      >
        {lastStep ? 'Terminer' : 'Suivant'}
      </Button>
    </>
  );
}

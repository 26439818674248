import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { MapAlert, Alert } from '../../@types/state/alert';
import * as alertServiceApi from '../../services/api/alert.service';
import { AlertActionTypes } from './types';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';

export type AlertAction = GetAlertsPending | GetAlertsSuccess | GetAlertsError;

export interface GetAlertsPending
  extends Action<AlertActionTypes.GET_ALERTS_PENDING> {
  pending: boolean;
}

export interface GetAlertsSuccess
  extends Action<AlertActionTypes.GET_ALERTS_SUCCESS> {
  data: State['alert']['data'];
}

export interface GetAlertsError
  extends Action<AlertActionTypes.GET_ALERTS_ERROR> {
  message: any;
}

const getAlertsPending = (pending: boolean): GetAlertsPending => {
  return {
    type: AlertActionTypes.GET_ALERTS_PENDING,
    pending
  };
};

const getAlertsSuccess = (data: State['alert']['data']): GetAlertsSuccess => {
  return {
    type: AlertActionTypes.GET_ALERTS_SUCCESS,
    data
  };
};

const getAlertsError = (message: string): GetAlertsError => {
  return {
    type: AlertActionTypes.GET_ALERTS_ERROR,
    message
  };
};

export const getAlerts = (): ThunkAction<
  Promise<void>,
  State,
  {},
  AlertAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, AlertAction>
  ): Promise<void> => {
    dispatch(getAlertsPending(true));
    try {
      const response = await alertServiceApi.getAlerts();
      await waitFor(appConfig.waitForTime);

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapAlert, currentValue: Alert) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          dispatch(
            getAlertsSuccess({
              alerts: dataNormalized
            })
          );
        }
      }
    } catch (error) {
      dispatch(getAlertsError(error.message));
    }
  };
};

import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import * as authAction from '../../auth.action';
import { Dialogs } from '../../config/auth.config';
import { selectHasAuthErrors } from '../../auth.selector';

import { LoginFormData } from '../../../../@types/form/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(3),
      background: theme.palette.background.paper,
    },
    resetPassword: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    fields: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '70%',
    },
    errors: {
      color: theme.palette.error.main,
    },
    footer: {
      display: 'flex',
      flexDirection: 'column',
    },
    submit: {
      margin: theme.spacing(2, 0),
      maxWidth: ' 50%',
    },
    meta: {
      display: 'flex',
      alignItems: 'center',
    },
    version: {
      marginTop: theme.spacing(1),
      textAlign: 'right',
    },
  })
);

interface IProps {
  classe?: string;
  open: Dialogs;
  setOpen: Dispatch<SetStateAction<Dialogs>>;
}

export default function AuthForm(props: IProps) {
  const { classe, open, setOpen } = props;
  const [visible, setVisible] = React.useState(false);
  const { register, handleSubmit, errors } = useForm<LoginFormData>();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAuthErrors = useSelector(selectHasAuthErrors);

  function handleOpenResetDialog() {
    setOpen({ ...open, confirm: true });
  }

  function onSubmit(data: LoginFormData) {
    dispatch(authAction.login(data.email, data.password));
  }

  function toggleVisibility() {
    setVisible(!visible);
  }

  return (
    <form
      id="auth-form"
      onSubmit={handleSubmit(onSubmit)}
      className={`${
        classe ? clsx(classes.container, classe) : classes.container
      }`}
    >
      <Typography variant="h5" gutterBottom color="textSecondary">
        {t('login.title')}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {t('login.subtitle')}
      </Typography>

      <div id="auth-form-fields" className={classes.fields}>
        <TextField
          name="email"
          autoComplete="email"
          label={t('login.email')}
          margin="normal"
          type="email"
          autoFocus
          inputRef={register({
            required: true,
            pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
          })}
        />
        {errors.email && (
          <FormHelperText className={classes.errors}>
            {t('login.badEmail')}
          </FormHelperText>
        )}
        <TextField
          name="password"
          autoComplete="current-password"
          type={visible ? 'text' : 'password'}
          label={t('login.password')}
          margin="normal"
          inputRef={register({ required: true })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleVisibility}
                >
                  {visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errors.password && (
          <FormHelperText className={classes.errors}>
            {t('login.passRequired')}
          </FormHelperText>
        )}
        <Typography
          className={classes.resetPassword}
          variant="caption"
          align="right"
          onClick={handleOpenResetDialog}
        >
          {t('login.forgotPassword')}
        </Typography>

        {hasAuthErrors && (
          <FormHelperText className={classes.errors}>
            {t('login.notAllowed')}
          </FormHelperText>
        )}
      </div>

      <div id="auth-footer" className={classes.footer}>
        <Button variant="outlined" type="submit" className={classes.submit}>
          {t('login.connect')}
        </Button>
        <div className={classes.meta}>
          <Typography
            className={classes.version}
            variant="caption"
          >{`V${process.env.REACT_APP_VERSION}`}</Typography>
        </div>
      </div>
    </form>
  );
}

import { State } from '../../../../@types/state';
import { connect } from 'react-redux';
import { selectPbxStatus } from '../../planning.selector';
import DayScaleCellBase from './dayScaleCellBase.component';

export interface SelectProps {
  pbxStatus: State['planning']['pxbStatus'];
}

const mapStateToProps = (state: State) => {
  return {
    pbxStatus: selectPbxStatus(state)
  };
};

export default connect(mapStateToProps)(DayScaleCellBase);

import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Sound } from '../../../../../@types/state/sound';
import TimelapseRoundedIcon from '@material-ui/icons/TimelapseRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DispatchProps, SelectProps } from './message.connector';
import { DispachProps } from '../../../../player/components/player.connector';

import { enumMessageType } from '../../../config/studiotel.config';

const headerIcon = (isDefault: boolean) => {
  let icon: React.ReactNode;

  switch (isDefault) {
    case true: {
      icon = <RepeatRoundedIcon fontSize="inherit" />;
      break;
    }
    default: {
      icon = <TimelapseRoundedIcon fontSize="inherit" />;
    }
  }

  return icon;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: IProps) => ({
    flexGrow: 1,
    maxWidth: theme.message.width,
    height: theme.message.height,
    background: props.messageType && theme.card.background[props.messageType],
    overflow: 'hidden',
    boxShadow: 'none',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingRight: theme.spacing(2),
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
    },
  }),
  playIcon: (props: IProps) => ({
    color: props.messageType && theme.card.background[props.messageType],
    background: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.white,
    },
  }),
  header: {
    height: theme.message.header.height,
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: theme.message.content.height,
  },
}));

interface IProps {
  sounds: Sound;
  messageType: string | undefined;
  isDefault: boolean;
  playSound: DispatchProps['playSound'];
  isPlaying: SelectProps['isPlaying'];
  currentSounds: SelectProps['currentSounds'];
  currentMessageType: SelectProps['currentMessageType'];
  currentTime: SelectProps['currentTime'];
  setCurrentTime: DispachProps['setCurrentTime'];
}

const Message: React.FC<IProps> = (props) => {
  const {
    sounds,
    messageType,
    isDefault,
    playSound,
    isPlaying,
    currentSounds,
    currentMessageType,
    currentTime,
    setCurrentTime,
  } = props;
  const classes = useStyles(props);

  if (messageType === undefined) {
    return <div>type manquant</div>;
  }

  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => {
    setCurrentTime('00:00:00');

    if (!isPlaying) {
      playSound(sounds, true, messageType);
    } else if (
      sounds.id === currentSounds.id &&
      currentMessageType === messageType
    ) {
      playSound(null, false, null);
    } else if (
      sounds.id === currentSounds.id &&
      currentMessageType !== messageType
    ) {
      playSound(null, false, null);
      playSound(sounds, true, messageType);
    } else if (sounds.id !== currentSounds.id) {
      playSound(sounds, true, messageType);
    }
  };

  return (
    <>
      {sounds && (
        <Card className={classes.root}>
          <CardHeader
            title={enumMessageType[messageType]}
            action={headerIcon(isDefault)}
            className={classes.header}
            titleTypographyProps={{ variant: 'body2' }}
          ></CardHeader>

          <Divider />

          <CardContent className={classes.content}>
            <Box display="flex" flexWrap="noWrap">
              <Box pr={1}>
                <Box display="block">
                  <IconButton
                    aria-label="play/pause"
                    onClick={handleOnClick}
                    className={classes.playIcon}
                  >
                    <PlayArrowRoundedIcon />
                  </IconButton>
                </Box>
                {currentTime &&
                currentSounds &&
                sounds &&
                currentMessageType &&
                currentSounds.id === sounds.id &&
                currentMessageType === messageType ? (
                  <Typography variant="caption">{currentTime}</Typography>
                ) : (
                  <Typography variant="caption">00:00:00</Typography>
                )}
              </Box>
              <Box alignSelf="center">
                <Box
                  fontSize="body2.fontSize"
                  textAlign="left"
                  fontWeight="fontWeightBold"
                >
                  {sounds.title}
                </Box>
                <Box
                  fontWeight="fontWeightRegular"
                  fontSize="caption.fontSize"
                  textAlign="left"
                >
                  {sounds.description}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Message;

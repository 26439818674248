import React from 'react';
import { useLocation } from 'react-router';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { getRouteMetaDataFromPathName } from '../../router/routes.config';

import Title from '../../modules/title/components/title.component';
import VerifyStudiotelCard from '../../modules/fleet/components/card/verifyStudiotelCard.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingBottom: theme.spacing(2),
      background: theme.workflow.background.main,
    },
    content: {
      marginTop: theme.spacing(2),
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(2),
      '& #title': {
        flex: 1,
      },
    },
  })
);

export default function AddStudiotel() {
  const location = useLocation();

  const routeMeta = getRouteMetaDataFromPathName(location.pathname);
  const classes = useStyles();

  return (
    <div id="fleet-add-studiotel-view" className={classes.container}>
      <Title>{routeMeta && routeMeta.name}</Title>

      <Container maxWidth="md">
        <VerifyStudiotelCard />
      </Container>
    </div>
  );
}

import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { useTheme, Theme } from '@material-ui/core/styles';
import React from 'react';
import TimelapseRoundedIcon from '@material-ui/icons/TimelapseRounded';
import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';

const headerIcon = (isDefault: boolean) => {
  let icon: React.ReactNode;

  switch (isDefault) {
    case true: {
      icon = <RepeatRoundedIcon fontSize="inherit" />;
      break;
    }
    default: {
      icon = <TimelapseRoundedIcon fontSize="inherit" />;
    }
  }

  return icon;
};

const Appointment: React.ComponentType<Appointments.AppointmentProps> = (
  props
) => {
  const { data } = props;
  const theme: Theme = useTheme();

  const style: React.CSSProperties = {
    background: theme.card.background[data.type],
    textAlign: 'right',
    paddingRight: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    border: `${
      data.is_default ? 'none' : 'solid 4px ' + theme.borderColor[data.type]
    }`,
    // border: `solid 4px ${theme.borderColor[data.type]}`,
  };

  return (
    <Appointments.Appointment {...props} style={style}>
      {/* {props.data.title} */}
      {headerIcon(data.is_default)}
    </Appointments.Appointment>
  );
};

export default Appointment;

import React from 'react';
import Box from '@material-ui/core/Box';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Button from '@material-ui/core/Button';

export default function Upload() {
  return (
    <Box
      id="studiotel-download"
      flexGrow={1}
      border="1px dashed"
      borderColor="soundUpload.background"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexGrow={0}
      >
        <Box>
          <AddCircleOutlineRoundedIcon fontSize="large" color="disabled" />
        </Box>
        <Box textAlign="center">
          Glissez et déposez votre liste de studiotel ici (excel, csv).
        </Box>
        <Box>
          <Button disabled>Upload</Button>
        </Box>
      </Box>
    </Box>
  );
}

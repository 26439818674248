import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import * as organizationServiceApi from '../../services/api/organisation.service';
import { OrganizationActionTypes } from './types';
import { getHierarchy } from '../hierarchy/hierarchy.action';

export interface GetOrganizationsPending
  extends Action<OrganizationActionTypes.GET_ORGANIZATIONS_PENDING> {
  pending: boolean;
}

export interface GetOrganizationsSuccess
  extends Action<OrganizationActionTypes.GET_ORGANIZATIONS_SUCCESS> {
  data: State['organization']['data'];
}

export interface GetOrganizationsError
  extends Action<OrganizationActionTypes.GET_ORGANIZATIONS_ERROR> {
  message: any;
}

export type OrganisationAction =
  | GetOrganizationsPending
  | GetOrganizationsSuccess
  | GetOrganizationsError;

const getOrganizationsPending = (pending: boolean): GetOrganizationsPending => {
  return {
    type: OrganizationActionTypes.GET_ORGANIZATIONS_PENDING,
    pending
  };
};

const getOrganizationsSuccess = (
  data: State['organization']['data']
): GetOrganizationsSuccess => {
  return {
    type: OrganizationActionTypes.GET_ORGANIZATIONS_SUCCESS,
    data
  };
};

const getOrganizationsError = (message: string): GetOrganizationsError => {
  return {
    type: OrganizationActionTypes.GET_ORGANIZATIONS_ERROR,
    message
  };
};

export const getOrganizations = (): ThunkAction<
  Promise<void>,
  State,
  {},
  OrganisationAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, OrganisationAction>
  ): Promise<void> => {
    dispatch(getOrganizationsPending(true));
    try {
      const response = await organizationServiceApi.getOrganizations();

      if (response.status === 200) {
        dispatch(getOrganizationsSuccess({ organizations: response.data }));
      }
      dispatch(getHierarchy());
    } catch (error) {
      dispatch(getOrganizationsError(error.message));
    }
  };
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Planning from '../../modules/planning/components/planning.connector';
import StudiotelContent from '../../modules/studiotel/components/drawer/studiotelContent.component';
import Drawer from '../../modules/utils/components/drawer/drawer.component';
import Box from '@material-ui/core/Box';
import Title from '../../modules/title/components/title.component';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import { setCurrentStudiotel } from '../../modules/studiotel/studiotel.action';
import { selectCurrentStudiotel } from '../../modules/studiotel/studiotel.selector';

interface ParamTypes {
  studiotelId: string;
}

export default function Studiotel() {
  const location = useLocation();
  const routeMeta = getRouteMetaDataFromPathName(location.pathname);
  const { studiotelId } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const currentStudiolel = useSelector(selectCurrentStudiotel);

  useEffect(() => {
    dispatch(setCurrentStudiotel(studiotelId));
  }, [studiotelId, dispatch]);

  return (
    <Box display="flex">
      <Drawer content={<StudiotelContent />} />
      <Box display="flex" flexDirection="column">
        <Title>{`${routeMeta && routeMeta.name} du Studioplay : ${
          currentStudiolel && currentStudiolel?.id
        }`}</Title>
        <Planning />
      </Box>
    </Box>
  );
}

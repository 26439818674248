import moment from 'moment';
import { Planning as PlanningType } from '../../../@types/state/planning';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';

interface IPlanningBuilder {
  startTime: moment.Moment;
  stopTime: moment.Moment;
  planning: PlanningType;
}

/**
 * get default planning
 * @param plannings
 * @param startTime
 * @param stopTime
 */
function getDefaultPlanning(
  plannings: IPlanningBuilder[],
  startTime: moment.Moment,
  stopTime: moment.Moment
): IPlanningBuilder | null {
  let ret: IPlanningBuilder | null = null;
  // TODO: add condition with created_at
  plannings.forEach((planning) => {
    const stopEndOfSecond = planning.stopTime.clone().endOf('second');
    if (
      planning.planning.is_default &&
      planning.startTime.diff(startTime) <= 0 &&
      stopEndOfSecond.diff(stopTime) >= 0
    ) {
      ret = planning;
    }
  });

  return ret;
}
// function getDefaultPlanning(
//   plannings: IPlanningBuilder[],
//   startTime: moment.Moment,
//   stopTime: moment.Moment
// ): IPlanningBuilder | null {

//   const defaultPlannings = plannings.filter((planning: IPlanningBuilder) => planning.planning.is_default)

//   const sortdefaultPlannings = defaultPlannings.sort((a:IPlanningBuilder, b:IPlanningBuilder) => {
//     return moment(a.planning.created_at).diff(moment(b.planning.created_at));
//   })

// }

function buildFlatPlannings(
  plannings: PlanningType[],
  day: moment.Moment
): IPlanningBuilder[] {
  const flatPlannings: IPlanningBuilder[] = [];
  // substract 1 to get monday at index 0
  const dayIdx = day.isoWeekday() - 1;

  // check each periods of one day for a given planning
  plannings.forEach((planning) => {
    planning.week_days[dayIdx].forEach((element) => {
      const startTime = day
        .clone()
        .add(moment.duration(element.start_time))
        .startOf('second');

      const stopTime = day
        .clone()
        .add(moment.duration(element.stop_time))
        .endOf('second');

      // check planning start_time overlaping with period start_time
      if (
        planning.start_time &&
        startTime.diff(moment(planning.start_time)) <= 0 &&
        planning.stop_time &&
        stopTime.diff(moment(planning.start_time)) >= 0
      ) {
        flatPlannings.push({
          startTime: moment(planning.start_time).clone(),
          stopTime: stopTime,
          planning: planning,
        });
      }

      // check planning start_time overlaping with period start_time and stop_time
      if (
        planning.start_time &&
        startTime.diff(moment(planning.start_time)) >= 0 &&
        planning.stop_time &&
        stopTime.diff(moment(planning.stop_time)) <= 0
      ) {
        flatPlannings.push({
          startTime: startTime,
          stopTime: stopTime,
          planning: planning,
        });
      }

      // check planning start_time overlaping with period stop_time
      if (
        planning.stop_time && // A cheval sur la fin de la période
        startTime.diff(moment(planning.stop_time)) <= 0 &&
        planning.stop_time &&
        stopTime.diff(moment(planning.stop_time)) >= 0
      ) {
        flatPlannings.push({
          startTime: startTime,
          stopTime: moment(planning.stop_time).clone(),
          planning: planning,
        });
      }

      if (planning.is_default) {
        flatPlannings.push({
          startTime: startTime,
          stopTime: stopTime,
          planning: planning,
        });
      }
    });
  });

  return flatPlannings;
}

function sortFlatPlanningsByStartTime(flatPlannings: IPlanningBuilder[]) {
  return flatPlannings.sort((a, b) => a.startTime.diff(b.startTime));
}

function createPlanning(
  sortedFlatPlannings: IPlanningBuilder[],
  day: moment.Moment,
  acc: Array<AppointmentModel>
) {
  const endOfDay = day.clone().endOf('day');

  let prevPlanning: IPlanningBuilder | null = null;
  for (let i = 0; i < sortedFlatPlannings.length; i++) {
    const currentPlanning = sortedFlatPlannings[i];
    let currentPlanningStartDate: moment.Moment = currentPlanning.startTime;
    let currentPlanningEndDate: moment.Moment = currentPlanning.stopTime;

    const sound = currentPlanning.planning.sounds;
    if (prevPlanning !== null) {
      // Check if there is a hole if yes fill it with default else set the newest default planning
      if (currentPlanning.startTime.diff(prevPlanning.stopTime) > 0) {
        // Set start date to the previous appointment stopdate ( check condition on the created at)
        const defaultPlanning = getDefaultPlanning(
          sortedFlatPlannings,
          prevPlanning.stopTime,
          currentPlanning.startTime
        );
        if (defaultPlanning != null) {
          const defaultSound = defaultPlanning.planning.sounds;
          acc.push({
            startDate: prevPlanning.stopTime.format('YYYY/MM/DD HH:mm:ss'),
            endDate: currentPlanning.startTime.format('YYYY/MM/DD HH:mm:ss'),
            title: defaultPlanning.planning.title,
            type: defaultPlanning.planning.message_type,
            planningId: defaultPlanning.planning.id,
            is_default: defaultPlanning.planning.is_default,
            start_time: defaultPlanning.planning.start_time,
            stop_time: defaultPlanning.planning.stop_time,
            sounds: {
              description: defaultSound.description,
              id: defaultSound.id,
              path: defaultSound.path,
              title: defaultSound.title,
            },
          });
        }
      }
    }

    if (i + 1 < sortedFlatPlannings.length) {
      const nextPlanning = sortedFlatPlannings[i + 1];
      // Take planning item if it start before and if it is newer than the current item
      if (nextPlanning.startTime.diff(currentPlanningEndDate) <= 0) {
        if (
          moment(nextPlanning.planning.created_at).diff(
            moment(currentPlanning.planning.created_at)
          ) > 0 &&
          currentPlanning.planning.is_default ===
            nextPlanning.planning.is_default
        ) {
          currentPlanningEndDate = nextPlanning.startTime;
        }
        if (
          currentPlanning.planning.is_default &&
          !nextPlanning.planning.is_default
        ) {
          currentPlanningEndDate = nextPlanning.startTime;
        }
        if (
          !currentPlanning.planning.is_default &&
          nextPlanning.planning.is_default
        ) {
          currentPlanningEndDate = currentPlanning.stopTime;
        }
      }
    } else {
      if (prevPlanning !== null) {
        currentPlanningStartDate = prevPlanning.stopTime;
      }
      currentPlanningEndDate = currentPlanning.stopTime;
    }

    prevPlanning = {
      startTime: currentPlanningStartDate,
      stopTime: currentPlanningEndDate,
      planning: currentPlanning.planning,
    };

    // check with the previous added planning before add the current one
    const lastAddedPlanning = acc[acc.length - 1];

    if (lastAddedPlanning) {
      // TODO: check the lastest added planning and replace him with the current if created_at
      // is more recent and startDate and endDate are similar
      // the check between currentPlanningStartDate and currentPlanningEndDate is a quick fix
      // to avoid add planning with startDate equal to to endDate (not manage by the graphic library)
      if (currentPlanningStartDate.diff(currentPlanningEndDate) !== 0) {
        acc.push({
          startDate: currentPlanningStartDate.format('YYYY/MM/DD HH:mm:ss'),
          endDate: currentPlanningEndDate.format('YYYY/MM/DD HH:mm:ss'),
          title: currentPlanning.planning.title,
          type: currentPlanning.planning.message_type,
          planningId: currentPlanning.planning.id,
          is_default: currentPlanning.planning.is_default,
          start_time: currentPlanning.planning.start_time,
          stop_time: currentPlanning.planning.stop_time,
          sounds: {
            description: sound.description,
            id: sound.id,
            path: sound.path,
            title: sound.title,
          },
        });
      }
    } else {
      if (currentPlanningStartDate.diff(currentPlanningEndDate) !== 0) {
        acc.push({
          startDate: currentPlanningStartDate.format('YYYY/MM/DD HH:mm:ss'),
          endDate: currentPlanningEndDate.format('YYYY/MM/DD HH:mm:ss'),
          title: currentPlanning.planning.title,
          type: currentPlanning.planning.message_type,
          planningId: currentPlanning.planning.id,
          is_default: currentPlanning.planning.is_default,
          start_time: currentPlanning.planning.start_time,
          stop_time: currentPlanning.planning.stop_time,
          sounds: {
            description: sound.description,
            id: sound.id,
            path: sound.path,
            title: sound.title,
          },
        });
      }
    }
  }

  // If not filled until 23:59:59.999 then add one more element with default planning
  if (prevPlanning != null && prevPlanning.stopTime.diff(endOfDay) < 0) {
    const defaultPlanning = getDefaultPlanning(
      sortedFlatPlannings,
      prevPlanning.stopTime,
      endOfDay
    );
    if (defaultPlanning != null) {
      const defaultSound = defaultPlanning.planning.sounds;
      acc.push({
        startDate: prevPlanning.stopTime.format('YYYY/MM/DD HH:mm:ss'),
        endDate: endOfDay.format('YYYY/MM/DD HH:mm:ss'),
        title: defaultPlanning.planning.title,
        type: defaultPlanning.planning.message_type,
        planningId: defaultPlanning.planning.id,
        is_default: defaultPlanning.planning.is_default,
        start_time: defaultPlanning.planning.start_time,
        stop_time: defaultPlanning.planning.stop_time,
        sounds: {
          description: defaultSound.description,
          id: defaultSound.id,
          path: defaultSound.path,
          title: defaultSound.title,
        },
      });
    }
  }
}

// build a list of appointment model from a list of planning for one day and one type
export function buildOneDayPlanning(
  day: moment.Moment,
  plannings: PlanningType[],
  acc: Array<AppointmentModel>
) {
  day.startOf('day');
  const flatPlannings = buildFlatPlannings(plannings, day);

  const sortedFlatPlannings = sortFlatPlanningsByStartTime(flatPlannings);

  // check if there are several planning requiring a choice
  if (sortedFlatPlannings.length === 1) {
    const item = sortedFlatPlannings[0];
    const sound = item.planning.sounds;

    acc.push({
      startDate: item.startTime.format('YYYY/MM/DD HH:mm:ss'),
      endDate: item.stopTime.format('YYYY/MM/DD HH:mm:ss'),
      title: item.planning.title,
      type: item.planning.message_type,
      planningId: item.planning.id,
      is_default: item.planning.is_default,
      start_time: item.planning.start_time,
      stop_time: item.planning.stop_time,
      sounds: {
        description: sound.description,
        id: sound.id,
        path: sound.path,
        title: sound.title,
      },
    });

    return;
  } else {
    createPlanning(sortedFlatPlannings, day, acc);
  }
}

import { State } from '../../@types/state';

export const selectisPlaying = (state: State): State['player']['isPlaying'] => {
  return state.player.isPlaying && state.player.isPlaying
    ? state.player.isPlaying
    : false;
};

export const selectSounds = (state: State): State['player']['sounds'] => {
  return state.player.sounds && state.player.sounds
    ? state.player.sounds
    : null;
};

export const selectMessageType = (
  state: State
): State['player']['message_type'] => {
  return state.player.message_type && state.player.message_type
    ? state.player.message_type
    : null;
};

export const selectCurrentTime = (
  state: State
): State['player']['currentTime'] => {
  return state.player.currentTime && state.player.currentTime;
};

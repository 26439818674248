import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TreeItems } from '../../../../../../@types/hierarchy';
import * as soundAction from '../../../../../sound/sound.action';
import * as deploymentAction from '../../../../deployment.action';
import { selectDeploymentOrganizationId } from '../../../../deployment.selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    selectWrapper: {
      width: '40%'
    },
    select: {
      width: '100%'
    }
  })
);

interface IProps {
  organizations: TreeItems[];
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
}

export default function OrganizationSelect(props: IProps) {
  const { organizations, setIsStepValid } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedOrganizationId = useSelector(selectDeploymentOrganizationId);

  useEffect(() => {
    if (selectedOrganizationId !== -1) {
      dispatch(
        soundAction.getSoundsForAnOrganizationId(selectedOrganizationId)
      );
      setIsStepValid(true);
    }
  }, [dispatch, selectedOrganizationId, setIsStepValid]);

  function handleOnChange(event: React.ChangeEvent<{ value: any }>) {
    dispatch(deploymentAction.setDeploymentOrganization(event.target.value));
  }

  return (
    <div className={classes.container}>
      <div id="organization-select" className={classes.selectWrapper}>
        <InputLabel>Flotte</InputLabel>
        <Select
          id="organization-select"
          value={selectedOrganizationId}
          onChange={handleOnChange}
          className={classes.select}
        >
          {organizations &&
            organizations.map((item: TreeItems) => (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
        </Select>
      </div>
    </div>
  );
}

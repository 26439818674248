import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import appConfig from '../../../../../application/app.config';

import StepperLayout from '../layout.component';
import Loader from '../../../../utils/components/loader.component';

import { Level } from '../../../config/studiotel.config';

import { selectStateByStudiotelId } from '../../../studiotel.selector';

import AdvancedDialog from '../dialog/advanced.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '100%',
    '& #connexion': {
      display: 'flex',
      justifyContent: 'center',
      minWidth: '100%',
      '& #alert': {
        width: '100%',
      },
    },
    '& #advanced': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      minWidth: '100%',
      marginTop: theme.spacing(2),
    },
  },
}));

enum ConnexionState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
}

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setLevel: React.Dispatch<React.SetStateAction<Level>>;
  version: string | undefined;
}

interface ParamTypes {
  studiotelId: string;
}

export default function ConnexionStep(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    setActiveStep,
    setLevel,
    version,
  } = props;
  const { studiotelId } = useParams<ParamTypes>();
  const studiotelState = useSelector(selectStateByStudiotelId)(
    parseInt(studiotelId)
  );
  const [connexionState, setConnexionState] = React.useState<ConnexionState>(
    ConnexionState.CONNECTING
  );
  const [openAdvancedDialog, setOpenAdvancedDialog] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (studiotelState && studiotelState.connected) {
        setConnexionState(ConnexionState.CONNECTED);
      } else {
        setConnexionState(ConnexionState.NOT_CONNECTED);
      }
    }, appConfig.setup.connexionTime);

    return () => {
      clearTimeout(delay);
    };
  });

  React.useEffect(() => {
    if (connexionState === ConnexionState.CONNECTED) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [connexionState, setStepValid]);

  function handleAdvancedClick() {
    setOpenAdvancedDialog(true);
  }

  return (
    <StepperLayout
      hintTitle={'Vérification de la connexion'}
      hintContent={
        <Typography variant="caption" color="textSecondary" align="center">
          {
            'Patientez quelques instants nous établissons la communication avec votre Studioplay.'
          }
        </Typography>
      }
      hintAction={hintAction && hintAction}
      title={'Test de connexion'}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        <div id="connexion">
          {connexionState === ConnexionState.CONNECTING && <Loader />}
          {connexionState === ConnexionState.CONNECTED && (
            <Alert id="alert" severity="success">
              Connexion réussie !
            </Alert>
          )}
          {connexionState === ConnexionState.NOT_CONNECTED && (
            <Alert id="alert" severity="error">
              <Typography variant="caption" style={{ fontWeight: 'bold' }}>
                Votre Studioplay n'arrive pas à se connecter à internet :
              </Typography>
              <Typography variant="caption">
                Avez-vous correctement branché votre Studioplay (alimentation du
                boîtier et connexion ethernet) ? Si oui, il s'agit peut être
                d'un problème réseau. Dans ce cas veuillez vous rapprocher de
                notre service clientèle afin d’être guidé sur la configuration
                avancée.
              </Typography>
            </Alert>
          )}
        </div>
        <div id="advanced">
          <Button
            disabled={connexionState === ConnexionState.CONNECTING}
            size="small"
            onClick={handleAdvancedClick}
          >
            Configuration Avancée
          </Button>
        </div>
      </div>

      <AdvancedDialog
        open={openAdvancedDialog}
        setOpen={setOpenAdvancedDialog}
        setActiveStep={setActiveStep}
        setLevel={setLevel}
      />
    </StepperLayout>
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { getRouteMetaDataFromPathName } from '../../router/routes.config';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Endpoint } from '../../router/routes.config';
import * as deploymentAction from '../../modules/deployment/deployment.action';
import {
  selectInitFetch,
  selectFetchPending,
  selectInProgressDeploymentsList,
  selectCompletedDeploymentsList,
} from '../../modules/deployment/deployment.selector';
import Title from '../../modules/title/components/title.component';
import DeploymentList from '../../modules/deployment/components/list/deploymentList.component';
import Loader from '../../modules/utils/components/loader.component';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    listContainer: {
      margin: '0 5% 5% 5%',
    },
  })
);

export default function Deployments() {
  const location = useLocation();
  const routeMeta = getRouteMetaDataFromPathName(location.pathname);
  const dispatch = useDispatch();
  const isInitFetch = useSelector(selectInitFetch);
  const isLoading = useSelector(selectFetchPending);
  const inprogressDeployments = useSelector(selectInProgressDeploymentsList);
  const completedDeployments = useSelector(selectCompletedDeploymentsList);
  const classes = useStyles();

  useEffect(() => {
    dispatch(deploymentAction.getDeployments());
  }, [dispatch]);

  function handleOnClick() {
    dispatch(push(Endpoint.DEPLOYMENT_CREATE));
  }

  return (
    <div id="deployments-view" className={classes.container}>
      <div id="deployment-view-content" className={classes.content}>
        {isInitFetch && isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{ transform: 'translateX(-50%)' }}
          >
            <Loader />
          </Box>
        ) : (
          <div id="deployment-list-container" className={classes.listContainer}>
            <Title>{routeMeta && routeMeta.name}</Title>
            {inprogressDeployments && (
              <DeploymentList
                title="Déploiement en cours"
                deployments={inprogressDeployments}
                action={
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    px={1}
                  >
                    <IconButton onClick={handleOnClick}>
                      <AddCircleRoundedIcon color="primary" />
                    </IconButton>
                    <Typography>Déployer un message</Typography>
                  </Box>
                }
              />
            )}

            {completedDeployments && (
              <DeploymentList
                title="Déploiements traités avec succès"
                deployments={completedDeployments}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

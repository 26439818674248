import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: `calc(100vh - ${300}px)`,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  left: {
    flexBasis: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRight: `1px solid #00000029`,
    '& #hint-title': {
      fontWeight: 'bold',
    },
    '& #hint-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '70%',
    padding: theme.spacing(2),
    '& #title-container': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    '& #title': {
      fontWeight: 'bold',
      flex: 1,
    },
    '& #content-container': {
      flex: 1,
    },
    '& #alert': {
      marginTop: theme.spacing(2),
    },
    '& #action': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& :not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

interface IProps {
  hintTitle: string;
  hintContent: React.ReactNode;
  hintAction: React.ReactNode;
  title: string;
  studiotelVersion: string;
  children: React.ReactNode;
  action: React.ReactNode;
}

export default function StepperLayout(props: IProps) {
  const {
    hintTitle,
    hintContent,
    hintAction,
    title,
    studiotelVersion,
    children,
    action,
  } = props;

  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <div className={classes.content}>
        <div className={classes.left}>
          <div id="hint-content">
            <Typography
              id="hint-title"
              variant="body2"
              color="textSecondary"
              gutterBottom
              align="center"
            >
              {hintTitle}
            </Typography>

            {hintContent && hintContent}
          </div>
          {hintAction && hintAction}
        </div>

        <Divider orientation="vertical" />

        <div className={classes.right}>
          <div id="title-container">
            <Typography id="title">{title}</Typography>
            <Typography variant="caption">{studiotelVersion}</Typography>
          </div>

          <div id="content-container">{children && children}</div>

          <div id="action">{action && action}</div>
        </div>
      </div>
    </Card>
  );
}

import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { ViewState, AppointmentModel } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Appointments,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { getLocale } from '../../../application/i18n.config';
import { SelectProps } from './planning.connector';
import Appointment from './appointment/appointment.component';
import TooltipContent from './tooltip/tooltipContent.component';
import ToolTipHeader from './tooltip/tooltipHeader.component';
import DateNavigator from './dateNavigator/dateNavigator.connector';
import PbxFilter from './pbxFilter/pbxFilter.component';
import DayScaleCellBase from './dayScaleCellBase/dayScaleCellBase.connector';
import { useWindowHeight } from '../../utils/hook';
import { Planning as PlanningType } from '../../../@types/state/planning';
import { buildOneDayPlanning } from '../algo/planningCreation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    controls: {
      marginBottom: theme.spacing(2),
    },
    weekView: {},
  })
);

interface IProps {
  currentDate: SelectProps['currentDate'];
  currentStudiotelPlannings: SelectProps['currentStudiotelPlannings'];
}

const Planning: React.SFC<IProps> = (props) => {
  const { currentDate, currentStudiotelPlannings } = props;

  const navigatorLocal = getLocale();
  const [locale] = React.useState<string>(navigatorLocal);
  const classes = useStyles();

  // Ouput
  const planningsToDisplay: Array<AppointmentModel> = [];

  // Current day selected in the calendar
  const curDate: Date = currentDate as Date;
  const currentMomentDate = moment(curDate.toISOString());

  // First day of the selected week
  const firstDayWeek = currentMomentDate.startOf('isoWeek');

  // message_type list
  const messageTypes = ['offhook', 'moh', 'voicemail'];

  // week days
  const weekDays = [firstDayWeek];
  for (let day = 1; day < 7; day++) {
    let dayOfWeek = firstDayWeek.clone().add(day, 'day');
    weekDays.push(dayOfWeek);
  }
  if (currentStudiotelPlannings) {
    const dataset = Object.values(currentStudiotelPlannings);
    // Loop through message types
    messageTypes.forEach((messageType) => {
      const filteredTypeDataset = dataset.filter((planning: PlanningType) => {
        if (!planning) return false;

        return planning.message_type === messageType;
      });
      weekDays.forEach((day) => {
        const filteredDateDataset = filteredTypeDataset.filter(
          (planning: PlanningType) => {
            const startOfDay = day.clone().startOf('day');
            const endOfDay = day.clone().endOf('day');
            // We keep all the default calendars
            if (planning.is_default) {
              return true;
            }

            // Keep only planning when start time < end of day and stop time > start of day
            if (
              planning.start_time &&
              moment(planning.start_time).diff(endOfDay) < 0 &&
              planning.stop_time &&
              moment(planning.stop_time).diff(startOfDay) > 0
            ) {
              return true;
            }

            return false;
          }
        );
        buildOneDayPlanning(day, filteredDateDataset, planningsToDisplay);
      });
    });
  }
  const windowHeight = useWindowHeight();
  // height = windowHeight - (headerHeight + toolbarHeight + controlsHeight)
  const theme = useTheme();
  const height = windowHeight - (theme.header.height + 61 + 52);

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.controls}
      >
        <Grid item xs={3}>
          <DateNavigator />
        </Grid>
        <Grid item xs={9}>
          <PbxFilter />
        </Grid>
      </Grid>

      <Scheduler
        data={planningsToDisplay}
        locale={locale}
        firstDayOfWeek={1}
        height={height}
      >
        <ViewState defaultCurrentDate={currentDate} currentDate={currentDate} />

        <WeekView
          startDayHour={7}
          endDayHour={24}
          cellDuration={60}
          dayScaleCellComponent={DayScaleCellBase}
        />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip
          headerComponent={ToolTipHeader}
          contentComponent={TooltipContent}
        />
      </Scheduler>
    </div>
  );
};

export default Planning;

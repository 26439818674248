import { State } from '../../@types/state';
import {
  Studiotel,
  MapState,
  State as StudiotelState,
} from '../../@types/state/studiotel';

export function selectErrors(state: State): string | undefined {
  return state.deployment.errors;
}

export const selectStudiotelsMap = (
  state: State
): State['studiotel']['data']['studiotels'] => {
  return state.studiotel.data && state.studiotel.data.studiotels
    ? state.studiotel.data.studiotels
    : undefined;
};

export const selectCurrentStudiotelId = (
  state: State
): State['studiotel']['currentStudiotelId'] => {
  return state.studiotel.currentStudiotelId
    ? state.studiotel.currentStudiotelId
    : undefined;
};

export function selectStudiotels(state: State): Studiotel[] {
  const studiotelsMap = selectStudiotelsMap(state);

  if (!studiotelsMap) return [];

  return Object.values(studiotelsMap);
}

export const selectCurrentStudiotel = (state: State): Studiotel | undefined => {
  const studiotels = selectStudiotelsMap(state);

  if (!studiotels) return undefined;

  const currentStudiotelId = selectCurrentStudiotelId(state);

  if (!currentStudiotelId) return undefined;

  const currentStudiotel = studiotels[currentStudiotelId];

  return currentStudiotel;
};

export function selectStudiotelsByOrganizationId(state: State) {
  return function (organizationId: number): Studiotel[]  {
    const studiotelsMap = selectStudiotelsMap(state);

    if (!studiotelsMap) return [];

    const studiotels = Object.values(studiotelsMap);

    return studiotels.filter(
      (studiotel: Studiotel) => studiotel.organization_id === organizationId
    );
  };
}

export function selectStudiotelByOrganizationIds(state: State) {
  return function (organizationIds: number[]): Studiotel[] | undefined {
    let studiotelsForOrganizationIds: Studiotel[] = [];

    organizationIds.forEach((organizationId: number) => {
      const studiotels = selectStudiotelsByOrganizationId(state)(
        organizationId
      );
      if (!studiotels) return;

      studiotelsForOrganizationIds = [
        ...new Set([...studiotelsForOrganizationIds, ...studiotels]),
      ];
    });

    return studiotelsForOrganizationIds;
  };
}

export function selectStudiotelById(state: State) {
  const studiotelsMap = selectStudiotelsMap(state);

  return function (studiotelId: number): Studiotel | null {
    if (!studiotelsMap) return null;

    return studiotelsMap[studiotelId];
  };
}

export function selectStudiotelsStateMap(state: State): MapState | undefined {
  const { studiotelsState } = state.studiotel.data;

  return studiotelsState;
}

export function selectStudiotelsState(state: State): StudiotelState[] {
  const studiotelsStateMap = selectStudiotelsStateMap(state);

  if (!studiotelsStateMap) return [];

  return Object.values(studiotelsStateMap);
}

export function selectStateByStudiotelId(state: State) {
  const studiotelsState = selectStudiotelsState(state);

  return function (studiotelId: number): StudiotelState | null {
    const studiotelState = studiotelsState.find(
      (studiotelState: StudiotelState) =>
        studiotelState.studiotel_id === studiotelId
    );

    if (!studiotelState) return null;

    return studiotelState;
  };
}

export function selectHasStudiotelDisconnectedInStudiotelList(
  state: State,
  studiotelIds: number[] | undefined
): boolean {
  const studiotelsState = selectStudiotelsState(state);
  let HasStudiotelDisconnected = false;
  if (!studiotelIds) return HasStudiotelDisconnected;

  if (!studiotelsState) return HasStudiotelDisconnected;
  const filteredStudiotelsState = studiotelsState.filter(
    (studiotelsState: StudiotelState) => {
      return studiotelIds.includes(studiotelsState.studiotel_id);
    }
  );
  filteredStudiotelsState.forEach((studiotelState: StudiotelState) => {
    if (studiotelState.connected === false) {
      HasStudiotelDisconnected = true;
    }
  });

  return HasStudiotelDisconnected;
}

export function selectSearchedStudiotels(state: State) {
  return function (search: string): Studiotel[] {
    const studiotels = selectStudiotels(state);
    const regex = RegExp(`${search}`);

    const results = studiotels.filter((studiotel: Studiotel) => {
      return regex.test(studiotel.id.toString());
    });

    return results;
  };
}

export function selectUnassociatedStudiotelById(state:State) {
  return function (studiotelId:number): Studiotel | undefined {
    const {unassociatedStudiotel} = state.studiotel.data;

    return unassociatedStudiotel.find((studiotel: Studiotel) => studiotel.id === studiotelId);
  }
}

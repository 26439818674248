const fr = {
  fr: {
        translations: {
          legal: {
            link: '© Ats Studios 2020 - Mentions légales',
            title: 'Mentions légales',
            credit: 'Crédits',
            identity: 'Identification',
            cgu: `Conditions générales d'utilisation`,
            cgulink: `Voir les CGU`,
            development: `Création du site web`,
            social: `Dénomination sociale`,
            headQuarter: `Adresse du siège social`,
            phone: ` Numéro de téléphone`,
            mail: `Adresse de courrier électronique`,
            rcs: `N° RCS`,
            siret: `SIRET`
          },
          login: {
            title: `CONNEXION`,
            subtitle: `Connectez-vous pour accéder à la gestion de ligne`,
            badEmail: `Email incorrecte`,
            passRequired: `Mot de passe requis`,
            forgotPassword: `Créer ou modifier le mot de passe`,
            noAccount: `Pas de compte ?`,
            access: `Faite une demande d'accès.`,
            connect: `Connexion`,
            email: `Email`,
            password: `Mot de passe`,
            notAllowed: `Login ou mot de passe incorrect`,
            resetPassword: {
              confirmAsk: `Confirmer la demande de changement de mot de passe`,
              email: `Email`,
              title: `Changement de mot de passe`,
              text: `Veuillez entrer un nouveau mot de passe et le confirmer par la suite`,
              newPassword: `Nouveau mot de passe`,
              confirmPassword: `Confirmation du mot de passe`,
              fieldsErrors: `Les mots de passe doivent être identiques`,
            },
            action: {
              confirm: `Confirmer`,
              cancel: `annuler`,
            },
          },
        },
      },
}

export default fr;

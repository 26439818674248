import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Message from '../message/message.connector';
import Grid from '@material-ui/core/Grid';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import Box from '@material-ui/core/Box';
import { Planning } from '../../../../../@types/state/planning';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';

const useStyles = makeStyles((theme: Theme) => ({
  open: {
    background: theme.pbx.open.color
  },
  pbxOpenIcon: {
    marginRight: theme.spacing(1)
  }
}));

interface IProps {
  currentStudiotelOffhookPlanning: Planning | null;
  currentStudiotelMohPlanning: Planning | null;
}

const PbxOpen: React.FC<IProps> = props => {
  const {
    currentStudiotelOffhookPlanning,
    currentStudiotelMohPlanning
  } = props;
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexWrap="no-wrap" flexGrow={1}>
        <Box className={classes.open} width={8} mr={1}></Box>

        <Box width={1}>
          <Grid container alignItems="center">
            <Grid item>
              <PhoneRoundedIcon className={classes.pbxOpenIcon} />
            </Grid>
            <Grid item>
              <Box>Standard ouvert</Box>
            </Grid>
          </Grid>

          {currentStudiotelOffhookPlanning && (
            <Box display="flex" flexWrap="no-wrap" alignItems="center" mb={1}>
              <Message
                sounds={currentStudiotelOffhookPlanning.sounds}
                messageType={currentStudiotelOffhookPlanning.message_type}
                isDefault={currentStudiotelOffhookPlanning.is_default}
              />
              <Box>
                <DragIndicatorRoundedIcon />
              </Box>
            </Box>
          )}

          {currentStudiotelMohPlanning && (
            <Box display="flex" flexWrap="no-wrap" alignItems="center">
              <Message
                sounds={currentStudiotelMohPlanning.sounds}
                messageType={currentStudiotelMohPlanning.message_type}
                isDefault={currentStudiotelMohPlanning.is_default}
              />
              <Box>
                <DragIndicatorRoundedIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PbxOpen;

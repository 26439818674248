import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { AuthActionTypes } from './types';
import { AuthAction } from './auth.action';

const initialState = {
  pending: false,
  data: {
    token: null,
    refreshToken: null,
    role: null,
    organizationId: null
  },
  errors: undefined
};

const auth: Reducer<State['auth'], AuthAction> = (
  state: State['auth'] = initialState,
  action: AuthAction
) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_PENDING: {
      return {
        ...state,
        pending: action.pending
      };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data
      };
    }
    case AuthActionTypes.LOGIN_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message
      };
    }
    case AuthActionTypes.LOGOUT_PENDING: {
      return {
        ...state,
        pending: action.pending
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data
      };
    }
    case AuthActionTypes.LOGOUT_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message
      };
    }
        case AuthActionTypes.ASK_RESET_PASSWORD_LINK_PENDING: {
      return {
        ...state,
        pending: action.pending,
      };
    }
    case AuthActionTypes.ASK_RESET_PASSWORD_LINK_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case AuthActionTypes.ASK_RESET_PASSWORD_LINK_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_PENDING: {
      return {
        ...state,
        pending: action.pending,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case AuthActionTypes.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    case AuthActionTypes.REFRESH_TOKEN_PENDING: {
      return {
        ...state,
        pending: action.pending,
      };
    }
    case AuthActionTypes.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    }
    case AuthActionTypes.REFRESH_TOKEN_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import config, {
  StudiotelConfig,
  RadioList,
} from '../../../config/studiotel.config';

import { StudiotelConfigFile } from '../stepper.component';

import StepperLayout from '../layout.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    maxHeight: `calc(100vh - ${350}px)`,
    overflow: 'auto',
    '& .MuiInputBase-root': {
      maxWidth: 500,
    },
  },
}));

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  studiotelConfigFile: StudiotelConfigFile;
  setStudiotelConfigFile: React.Dispatch<
    React.SetStateAction<StudiotelConfigFile>
  >;
  version: string | undefined;
}

export default function HardwareSetup(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    studiotelConfigFile,
    setStudiotelConfigFile,
    version,
  } = props;
  const [studiotelConfig, setStudiotelConfig] = React.useState(
    studiotelConfigFile.studiotel.type
  );

  React.useEffect(() => {
    const { studiotel } = studiotelConfigFile;

    if (studiotel.type !== StudiotelConfig.NONE) {
      if (studiotel.type === StudiotelConfig.LIVEBOX_OPO) {
        if (
          studiotel.url &&
          studiotel.login &&
          studiotel.password &&
          studiotel.phone_number
        ) {
          setStepValid(true);
        } else {
          setStepValid(false);
        }
      }
      if (studiotel.type === StudiotelConfig.MITEL_OFFICE_500) {
        if (
          studiotel.url &&
          studiotel.login &&
          studiotel.password &&
          studiotel.phone_number &&
          studiotel.offhook_tone
        ) {
          setStepValid(true);
        } else {
          setStepValid(false);
        }
      }
      if (studiotel.type === StudiotelConfig.PABX) {
        if (studiotel.dtmf) {
          setStepValid(true);
        } else {
          setStepValid(false);
        }
      }
      if (studiotel.type === StudiotelConfig.ALCATEL_OXE) {
        if (
          studiotel.login &&
          studiotel.password &&
          studiotel.port &&
          studiotel.hostname &&
          studiotel.nb_att &&
          studiotel.nb_pre &&
          studiotel.nb_rep
        ) {
          setStepValid(true);
        } else {
          setStepValid(false);
        }
      }
      if (studiotel.type === StudiotelConfig.ALCATEL_OXO) {
        if (
          studiotel.login &&
          studiotel.password &&
          studiotel.hostname &&
          studiotel.nb_att &&
          studiotel.nb_pre &&
          studiotel.nb_rep
        ) {
          setStepValid(true);
        } else {
          setStepValid(false);
        }
      }
    } else {
      setStepValid(false);
    }
  }, [setStepValid, studiotelConfigFile]);

  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setStudiotelConfigFile({
      ...studiotelConfigFile,
      studiotel: { type: value as StudiotelConfig },
    });
    setStudiotelConfig(value as StudiotelConfig);
  }

  return (
    <StepperLayout
      hintTitle={'Information de configuration'}
      hintContent={
        <Typography variant="caption" color="textSecondary" align="center">
          {`Veuillez choisir parmi la liste l'appareil qui sera relié à votre Studioplay. Remplisser les champs demandés puis appuyer sur le bouton VALIDER.`}
        </Typography>
      }
      hintAction={hintAction && hintAction}
      title={'Votre appareil à configurer :'}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        <FormControl component="fieldset">
          <RadioGroup
            name="type"
            value={studiotelConfig}
            onChange={handleChange}
          >
            {config.radioList.map((radioList: RadioList) => {
              const { Form } = config.form[radioList.value];

              return (
                <React.Fragment key={radioList.value}>
                  <FormControlLabel
                    value={radioList.value}
                    control={<Radio />}
                    label={radioList.label}
                    disabled={radioList.disabled}
                  />
                  {Form && studiotelConfig === radioList.value && (
                    <Form
                      configFile={studiotelConfigFile}
                      setConfigFile={setStudiotelConfigFile}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    </StepperLayout>
  );
}

import React from 'react';
import debounce from 'lodash/debounce';
import { IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import Loader from './loader.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      background: theme.search.background,
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '& input': {
        color: theme.palette.text.secondary,
      },
    },
    loader: {
      marginRight: theme.spacing(2),
    },
  })
);

interface IProps {
  placeholder: string;
  className?: string;
  loading: boolean;
  searchAction: (search: string) => void;
  resetAction?: () => void;
  Results?: any;
  debouceTime: number;
  onMooveDown?: () => void;
  onMooveUp?: () => void;
  onPressEnter?: () => void;
  hideResults?: boolean;
}

export default function Search(props: IProps) {
  const {
    placeholder,
    className,
    loading,
    searchAction,
    resetAction,
    Results,
    debouceTime,
    onMooveDown,
    onMooveUp,
    onPressEnter,
    hideResults,
  } = props;
  const [isSearching, setIsSearching] = React.useState(false);
  const classes = useStyles();
  const search = React.useRef(
    debounce((params: string) => {
      if (params && params.length > 0) {
        searchAction(params);
        setIsSearching(true);
      } else {
        setIsSearching(false);
        if (resetAction) resetAction();
      }
    }, debouceTime)
  );

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const params = event.target.value;
    search.current(params);
  }

  function onKeyPress(e: React.KeyboardEvent) {
    if (e.keyCode === 38 && onMooveUp) {
      onMooveUp();
    }
    if (e.keyCode === 40 && onMooveDown) {
      onMooveDown();
    }
    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter();
    }
  }

  return (
    <>
      <Paper
        id="search-bar"
        className={`${clsx(classes.container, className)}`}
      >
        <IconButton aria-label="Search">
          <SearchIcon />
        </IconButton>
        <InputBase
          fullWidth
          onChange={handleSearch}
          placeholder={placeholder}
          onKeyDown={onKeyPress}
          endAdornment={
            isSearching && loading ? (
              <Loader size={20} classe={classes.loader} />
            ) : null
          }
        />
      </Paper>
      {Results && isSearching && !hideResults && (
        <Fade in={!!Results}>
          <Paper>
            <Results />
          </Paper>
        </Fade>
      )}
    </>
  );
}

import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { State } from '../../@types/state';
import { MapDeployment, Deployment } from '../../@types/state/deployment';
import { InProgressDeployment } from '../../@types/deployment';
import * as deploymentServiceApi from '../../services/api/deployment.service';
import { DeploymentActionTypes } from './types';
import { waitFor } from '../../services/api/helpers/various.helpers';
import appConfig from '../../application/app.config';

export type DeploymentAction =
  | GetDeploymentsPending
  | GetDeploymentsSuccess
  | GetDeploymentsError
  | SetDeploymentOrganization
  | CreateDeploymentPending
  | CreateDeploymentSuccess
  | CreateDeploymentError
  | ResetDeploymentErrors;

export interface GetDeploymentsPending
  extends Action<DeploymentActionTypes.GET_DEPLOYMENTS_PENDING> {
  pending: boolean;
}

export interface GetDeploymentsSuccess
  extends Action<DeploymentActionTypes.GET_DEPLOYMENTS_SUCCESS> {
  data: State['deployment']['data'];
}

export interface GetDeploymentsError
  extends Action<DeploymentActionTypes.GET_DEPLOYMENTS_ERROR> {
  message: any;
}

const getDeploymentsPending = (pending: boolean): GetDeploymentsPending => {
  return {
    type: DeploymentActionTypes.GET_DEPLOYMENTS_PENDING,
    pending
  };
};

const getDeploymentsSuccess = (
  data: State['deployment']['data']
): GetDeploymentsSuccess => {
  return {
    type: DeploymentActionTypes.GET_DEPLOYMENTS_SUCCESS,
    data
  };
};

const getDeploymentsError = (message: string): GetDeploymentsError => {
  return {
    type: DeploymentActionTypes.GET_DEPLOYMENTS_ERROR,
    message
  };
};

export const getDeployments = (): ThunkAction<
  Promise<void>,
  State,
  {},
  DeploymentAction
> => {
  return async (
    dispatch: ThunkDispatch<State, {}, DeploymentAction>
  ): Promise<void> => {
    dispatch(getDeploymentsPending(true));
    try {
      const response = await deploymentServiceApi.getDeployments();
      await waitFor(appConfig.waitForTime);

      const { data } = response;

      const dataNormalized = data.reduce(
        (accumulator: MapDeployment, currentValue: Deployment) => {
          accumulator[currentValue['id']] = currentValue;

          return accumulator;
        },
        {}
      );

      if (response.status === 200) {
        if (response) {
          dispatch(
            getDeploymentsSuccess({
              deployments: dataNormalized
            })
          );
        }
      }
    } catch (error) {
      dispatch(getDeploymentsError(error.message));
    }
  };
};

export interface CreateDeploymentPending
  extends Action<DeploymentActionTypes.CREATE_DEPLOYMENT_PENDING> {
  pending: boolean;
}

export interface CreateDeploymentSuccess
  extends Action<DeploymentActionTypes.CREATE_DEPLOYMENT_SUCCESS> {}

export interface CreateDeploymentError
  extends Action<DeploymentActionTypes.CREATE_DEPLOYMENT_ERROR> {
  message: any;
}

const createDeploymentPending = (pending: boolean): CreateDeploymentPending => {
  return {
    type: DeploymentActionTypes.CREATE_DEPLOYMENT_PENDING,
    pending
  };
};

const createDeploymentSuccess = (): CreateDeploymentSuccess => {
  return {
    type: DeploymentActionTypes.CREATE_DEPLOYMENT_SUCCESS
  };
};

const createDeploymentError = (message: string): CreateDeploymentError => {
  return {
    type: DeploymentActionTypes.CREATE_DEPLOYMENT_ERROR,
    message
  };
};

export const createDeployment = (
  deployment: InProgressDeployment
): ThunkAction<Promise<void>, State, {}, DeploymentAction> => {
  return async (
    dispatch: ThunkDispatch<State, {}, DeploymentAction>
  ): Promise<void> => {
    dispatch(createDeploymentPending(true));
    try {
      const response = await deploymentServiceApi.createDeployment(deployment);
      await waitFor(appConfig.waitForTime);

      if (response.status === 200) {
        if (response) {
          dispatch(createDeploymentSuccess());
        }
      }
    } catch (error) {
      dispatch(createDeploymentError(error.message));
    }
  };
};

export interface SetDeploymentOrganization
  extends Action<DeploymentActionTypes.SET_DEPLOYMENT_ORGANIZATION> {
  organizationId: State['deployment']['selectedOrganization'];
}

export const setDeploymentOrganization = (
  organizationId: State['deployment']['selectedOrganization']
): SetDeploymentOrganization => {
  return {
    type: DeploymentActionTypes.SET_DEPLOYMENT_ORGANIZATION,
    organizationId
  };
};

export interface ResetDeploymentErrors
  extends Action<DeploymentActionTypes.RESET_DEPLOYMENT_ERRORS> {}

export const resetDeploymentErrors = (): ResetDeploymentErrors => {
  return {
    type: DeploymentActionTypes.RESET_DEPLOYMENT_ERRORS
  };
};

import React from 'react';
import { DispatchProps, SelectProps } from './currentMessages.connector';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CurrentMessagesIcon from './currentMessagesIcon.svg';
import Grid from '@material-ui/core/Grid';
import PbxOpen from './pbxOpen/pbxOpen.component';
import PbxClose from './pbxClose/pbxClose.component';
import { useWindowHeight } from '../../../utils/hook';

const offsetY = 45;

const useStyles = makeStyles((theme: Theme) => ({
  root: (height: number) => ({
    width: '100%',
    boxShadow: 'none',
    '& .MuiExpansionPanelDetails-root': {
      padding: 0,
    },
    '& .MuiCollapse-container': {
      height: `${
        height - (theme.header.height + theme.datePicker.height + offsetY)
      }px !important`,
      overflowY: 'auto',
    },
    '& .MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 0,
      height: 40,
    },
  }),
  header: {
    background: theme.palette.primary.main,
  },
  expandIcon: {
    color: theme.palette.primary.contrastText,
  },
  currentMessagesIcon: {
    marginRight: theme.spacing(2),
    verticalAlign: 'middle',
  },
  details: {
    boxShadow: 'none',
    marginBottom: theme.spacing(3),
  },
  pbxOpen: {
    marginTop: theme.spacing(1),
  },
  pbxClose: {
    marginTop: theme.spacing(1),
  },
}));

interface IProps {
  getPlannings: DispatchProps['getPlannings'];
  setCurrentStudiotel: DispatchProps['setCurrentStudiotel'];
  currentStudiotelOffhookPlanning: SelectProps['currentStudiotelOffhookPlanning'];
  currentStudiotelMohPlanning: SelectProps['currentStudiotelMohPlanning'];
  currentStudiotelVoicemailPlanning: SelectProps['currentStudiotelVoicemailPlanning'];
}

const CurrentMessages: React.FC<IProps> = ({
  getPlannings,
  setCurrentStudiotel,
  ...props
}) => {
  const {
    currentStudiotelOffhookPlanning,
    currentStudiotelMohPlanning,
    currentStudiotelVoicemailPlanning,
  } = props;

  const height = useWindowHeight();
  const classes = useStyles(height);

  return (
    <>
      <ExpansionPanel className={classes.root} expanded>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.header }}
        >
          <Grid container alignItems="center">
            <Grid item>
              <img
                src={CurrentMessagesIcon}
                alt="currentMessagesIcon"
                className={classes.currentMessagesIcon}
              />
            </Grid>
            <Grid item>
              <Box color="common.white">Messages en cours de diffusion</Box>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.details}>
          <Grid container direction="column">
            <Grid item className={classes.pbxOpen}>
              <PbxOpen
                currentStudiotelOffhookPlanning={
                  currentStudiotelOffhookPlanning
                }
                currentStudiotelMohPlanning={currentStudiotelMohPlanning}
              />
            </Grid>
            <Grid item className={classes.pbxClose}>
              <PbxClose
                currentStudiotelVoicemailPlanning={
                  currentStudiotelVoicemailPlanning
                }
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

export default CurrentMessages;

import { connect } from 'react-redux';
import { State } from '../../../../@types/state';
import { isAuth as isAuthSelector } from '../../auth.selector';

import AuthRoute from './authRoute.component';

const mapStateToProps = (state: State) => {
  return {
    isAuth: isAuthSelector(state)
  };
};

export default connect(mapStateToProps)(AuthRoute);

import React, { useState, Dispatch, SetStateAction } from 'react';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import DatePicker from '../../../../../../utils/components/dateTime/DatePicker.component';
import TimePicker from '../../../../../../utils/components/dateTime/TimePicker.component';
import config from './config/config';
import { InProgressDeployment } from '../../../../../../../@types/deployment';
import appConfig from '../../../../../../../application/app.config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timePicker: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: 140,
    },
    datePicker: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: 140,
    },
  })
);

interface WeekDays {
  [key: string]: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

interface IProps {
  disabled: boolean;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
  deployment: InProgressDeployment;
}

const Scheduler = function (props: IProps) {
  const { disabled, setIsStepValid, deployment, setDeployment } = props;
  const [startTime, setStartTime] = useState<null | Date>(null);
  const [endTime, setEndTime] = useState<null | Date>(null);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [periodicity, setPeriodicity] = useState('week');
  const [weekDays, setWeekDays] = useState<WeekDays>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const classes = useStyles();

  React.useEffect(() => {
    // const haveDateTime = !!startDate && !!endDate && !!startTime && !!endTime;

    if (!disabled && startDate && endDate && startTime && endTime) {
      const momentStartDate = moment(startDate);
      momentStartDate.set({ h: 0, m: 0, s: 0, ms: 0 });
      const momentEndDate = moment(endDate);
      momentEndDate.set({ h: 23, m: 59, s: 59, ms: 999 });
      const momentStartTime = moment(startTime);
      const startTimeFormat = momentStartTime.format('HH:mm:ss');
      const momentEndTime = moment(endTime);
      const endTimeFormat = momentEndTime.format('HH:mm:ss');

      let deploymentUpdated = {
        ...deployment,
      };

      if (periodicity === 'week') {
        deploymentUpdated = {
          ...deploymentUpdated,
          planning: {
            is_default: disabled,
            start_time: momentStartDate.toISOString(),
            stop_time: momentEndDate.toISOString(),
            week_days: [
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
              [{ start_time: startTimeFormat, stop_time: endTimeFormat }],
            ],
          },
        };
      } else {
        deploymentUpdated = {
          ...deploymentUpdated,
          planning: {
            is_default: disabled,
            start_time: momentStartDate.toISOString(),
            stop_time: momentEndDate.toISOString(),
            week_days: [
              weekDays.monday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.tuesday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.wednesday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.thursday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.friday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.saturday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
              weekDays.sunday
                ? [
                    {
                      stop_time: endTimeFormat,
                      start_time: startTimeFormat,
                    },
                  ]
                : [],
            ],
          },
        };
      }

      const timeDuration = moment
        .duration(moment(endTime).diff(moment(startTime)))
        .as('minutes');

      if (
        startDate <= endDate &&
        startTime <= endTime &&
        Math.floor(timeDuration) >= appConfig.deployment.minTime
      ) {
        setIsStepValid(true);
        setDeployment(deploymentUpdated);
      } else {
        setIsStepValid(false);
      }
    }
  }, [
    disabled,
    deployment,
    endDate,
    endTime,
    periodicity,
    setDeployment,
    startDate,
    startTime,
    weekDays,
    setIsStepValid,
  ]);

  function handleOnPeriodicityChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setPeriodicity(event?.target.value);
  }

  function handleOnWeekDaysChange(day: string) {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      setWeekDays({
        ...weekDays,
        [day]: event.target.checked,
      });
    };
  }

  function displayTimeSelection() {
    return (
      <>
        <Box ml={1} fontSize="caption.fontSize">
          Le message sera joué (période minimum de 30 minutes)
        </Box>
        <Box id="time-selection">
          <TimePicker
            className={classes.timePicker}
            disabled={disabled}
            label="De"
            selectedTime={startTime}
            setSelectedTime={setStartTime}
          />
          <TimePicker
            className={classes.timePicker}
            disabled={disabled}
            label="à"
            selectedTime={endTime}
            setSelectedTime={setEndTime}
          />
        </Box>
      </>
    );
  }

  function displayPeriodicitySelection() {
    return (
      <Box id="periodicity-selection">
        <RadioGroup onChange={handleOnPeriodicityChange} value={periodicity}>
          <FormControlLabel
            disabled={disabled}
            value="week"
            control={<Radio color="primary" />}
            label="Toute la semaine"
          />
          <FormControlLabel
            disabled={disabled}
            value="days"
            control={<Radio color="primary" />}
            label={
              <Box
                id="days-selection"
                display="flex"
                flexWrap="wrap"
                width="100%"
                flexDirection="row"
              >
                <>
                  {config.map((day: any) => {
                    return (
                      <FormControlLabel
                        disabled={disabled || periodicity === 'week'}
                        key={day.key}
                        control={
                          <Checkbox
                            color="primary"
                            checked={weekDays[`${day.key}`]}
                            onChange={handleOnWeekDaysChange(day.key)}
                            value={day.key}
                          />
                        }
                        label={
                          <Box fontSize="caption.fontSize">{day.label}</Box>
                        }
                      />
                    );
                  })}
                </>
              </Box>
            }
          />
        </RadioGroup>
      </Box>
    );
  }

  function displayDateSelection() {
    return (
      <Box id="date-selection">
        <DatePicker
          className={classes.datePicker}
          disabled={disabled}
          label="À partir du"
          selectedDate={startDate}
          setSelectedDate={setStartDate}
        />
        <DatePicker
          className={classes.datePicker}
          disabled={disabled}
          label="jusqu’au"
          selectedDate={endDate}
          setSelectedDate={setEndDate}
        />
      </Box>
    );
  }

  return (
    <Box
      id="planning-scheduler"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
    >
      {displayTimeSelection()}

      {displayPeriodicitySelection()}

      {displayDateSelection()}
    </Box>
  );
};

function areEqual(prevProps: any, nextProps: any) {
  if (
    nextProps.deployment.planning.start_time !==
    prevProps.deployment.planning.start_time
  ) {
    return false;
  }
  if (
    nextProps.deployment.planning.stop_time !==
    prevProps.deployment.planning.stop_time
  ) {
    return false;
  }

  if (nextProps.disabled !== prevProps.disabled) {
    return false;
  }

  return true;
}

export default React.memo(Scheduler, areEqual);

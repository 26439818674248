import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import ResetPasswordForm from '../../modules/auth/components/form/resetPassword.component';
import Loader from '../../modules/utils/components/loader.component';
import { selectFetchPending } from '../../modules/auth/auth.selector';

const useStyles = makeStyles((theme: Theme) => ({
  view: {
    flex: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const fetchPending = useSelector(selectFetchPending);

  return (
    <div id="change-password-view" className={classes.view}>
      {fetchPending && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}
      <div id="change-password-container" className={classes.container}>
        <ResetPasswordForm />
      </div>
    </div>
  );
}

// import { getAuthenticatedInstance } from './helpers/helpers';
import { alertsResponse } from './mock/alert.mock';

// const authenticatedInstance = getAuthenticatedInstance();

// export async function getAlerts(): Promise<any> {
//   return authenticatedInstance.get('/alerts');
// }

export async function getAlerts(): Promise<any> {
  return alertsResponse;
}

export interface Column {
  label: string;
}

interface Config {
  table: {
    columns: Column[];
  };
}

const config: Config = {
  table: {
    columns: [
      {
        label: 'Statut',
      },
      {
        label: 'Studioplay',
      },
      {
        label: 'Configurer',
      },
      {
        label: 'Supprimer',
      },
    ],
  },
};

export default config;

import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as studiotelAction from '../../../studiotel/studiotel.action';
import { selectUnassociatedStudiotelById } from '../../../studiotel/studiotel.selector';

import {
  GetUnassociateStudiotelActionPayload,
  AssociateStudiotelActionPayload,
} from '../../../../@types/action/studiotel';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: `calc(100vh - ${150}px)`,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  left: {
    flexBasis: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRight: `1px solid #00000029`,
    '& #hint-title': {
      fontWeight: 'bold',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '70%',
    padding: theme.spacing(2),
    '& #title-container': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    '& #title': {
      fontWeight: 'bold',
      flex: 1,
    },
    '& #verify-container': {
      flex: 1,
    },
    '& #alert': {
      marginTop: theme.spacing(2),
    },
    '& #action': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& :not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

interface ParamTypes {
  organizationId: string;
}

export default function VerifyStudiotelCard() {
  const { organizationId } = useParams<ParamTypes>();
  const classes = useStyles();
  const [id, setId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [associationLoading, setAssociationLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const getUnassociatedStudiotelById = useSelector(
    selectUnassociatedStudiotelById
  );

  async function handleVerify(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    setLoading(true);
    const payload: GetUnassociateStudiotelActionPayload = {
      studiotel_id: parseInt(value),
    };
    await dispatch(studiotelAction.getUnassociated(payload));
    setLoading(false);
    setId(value);
  }

  async function handleAccept() {
    setAssociationLoading(true);
    const payload: AssociateStudiotelActionPayload = {
      organization_id: parseInt(organizationId),
      studiotel_id: parseInt(id),
    };
    await dispatch(studiotelAction.associateStudiotel(payload));
    await dispatch(studiotelAction.getStudiotels());
    setAssociationLoading(false);
    history.goBack();
  }

  function handleGoBack() {
    history.goBack();
  }

  return (
    <Card className={classes.container}>
      <div className={classes.content}>
        <div className={classes.left}>
          <Typography
            id="hint-title"
            variant="body2"
            color="textSecondary"
            gutterBottom
          >
            Numéro d'identification
          </Typography>
          <Typography variant="caption" color="textSecondary" align="center">
            Vous trouverez le numéro d'identification de votre Studioplay
            directement sur l'étiquette présente sur le dessus du boitier.
          </Typography>
        </div>

        <Divider orientation="vertical" />

        <div className={classes.right}>
          <div id="title-container">
            <Typography id="title">
              Entrer le numéro d'identification du Studioplay
            </Typography>
          </div>

          <div id="verify-container">
            <TextField
              id="studiotel-id"
              fullWidth
              variant="outlined"
              onChange={handleVerify}
              value={id}
            />
            {getUnassociatedStudiotelById(parseInt(id)) && (
              <Alert id="alert" severity="success">
                L'identifiant de votre Studioplay est reconnu
              </Alert>
            )}
            {!loading &&
              !getUnassociatedStudiotelById(parseInt(id)) &&
              id.length > 0 && (
                <Alert id="alert" severity="error">
                  L'identifiant de votre Studioplay n'est pas reconnu, veuillez
                  vérifier la saisie du numéro d'identification de votre
                  Studioplay.
                </Alert>
              )}
          </div>

          <div id="action">
            <Button color="primary" onClick={handleGoBack}>
              retour
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!getUnassociatedStudiotelById(parseInt(id))}
              onClick={handleAccept}
            >
              {associationLoading && <CircularProgress size={20} />}
              {!associationLoading && <Typography>valider</Typography>}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}

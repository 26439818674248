import { Drawer as MuiDrawer } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: theme.drawer.width
    },
    drawerPaper: {
      width: theme.drawer.width,
      marginTop: theme.header.height,
      overflowY: 'hidden'
    }
  })
);

interface IProps {
  content: React.ReactNode;
}

export default function Drawer(props: IProps) {
  const { content } = props;
  const classes = useStyles();

  return (
    <MuiDrawer
      id="app-drawer"
      className={classes.root}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      {content && content}
    </MuiDrawer>
  );
}

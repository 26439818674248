import React from 'react';
import { Box } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import PlanningDatePicker from '../../../planning/components/datePicker/planningDatePicker.connector';
import CurrentMessages from '../currentMessages/currentMessages.connector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentMessages: {
      position: 'absolute',
      width: '100%',
      top: theme.datePicker.height,
      left: 0,
    },
  })
);

export default function StudiotelContent() {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <Box width={theme.drawer.width}>
        <PlanningDatePicker />
      </Box>
      <Box className={classes.currentMessages}>
        <CurrentMessages />
      </Box>
    </>
  );
}

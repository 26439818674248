import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { SelectProps, DispatchProps } from './dateNavigator.connector';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2)
    }
  })
);

interface IProps {
  setCurrentDate: DispatchProps['setCurrentDate'];
  currentDate: SelectProps['currentDate'];
}

const DateNavigator: React.FC<IProps> = props => {
  const { setCurrentDate } = props;
  const today = new Date();

  const classes = useStyles();

  function handleOnClick() {
    setCurrentDate(today);
  }

  return (
    <Container>
      <Grid container justify="flex-start" className={classes.root}>
        <Grid item>
          <Button variant="outlined" onClick={handleOnClick}>
            Aujourd'hui
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DateNavigator;

import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../../../@types/state';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import * as playerAction from '../../../../player/player.action';
import Message from './message.component';
import {
  selectisPlaying,
  selectSounds,
  selectMessageType,
  selectCurrentTime
} from '../../../../player/player.selector';

export interface DispatchProps {
  playSound: (
    sounds: State['player']['sounds'],
    isPlaying: State['player']['isPlaying'],
    messageType: State['player']['message_type']
  ) => void;
  setCurrentTime: (currentTime: State['player']['currentTime']) => void;
}

export interface SelectProps {
  isPlaying: State['player']['isPlaying'];
  currentSounds: State['player']['sounds'];
  currentMessageType: State['player']['message_type'];
  currentTime: State['player']['currentTime'];
}

const mapStateToProps = (state: State) => {
  return {
    isPlaying: selectisPlaying(state),
    currentSounds: selectSounds(state),
    currentMessageType: selectMessageType(state),
    currentTime: selectCurrentTime(state)
  };
};

const mapDispatchToProps = (
  dispach: ThunkDispatch<State, {}, AnyAction>
): DispatchProps => {
  return {
    playSound: (
      sounds: State['player']['sounds'],
      isPlaying: State['player']['isPlaying'],
      messageType: State['player']['message_type']
    ) => {
      dispach(playerAction.playSound(sounds, isPlaying, messageType));
    },
    setCurrentTime: (currentTime: State['player']['currentTime']) => {
      dispach(playerAction.setCurrentTime(currentTime));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);

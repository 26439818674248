import { History } from 'history';

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { State } from '../@types/state';
import auth from '../modules/auth/auth.reducer';
import studiotel from '../modules/studiotel/studiotel.reducer';
import planning from '../modules/planning/planning.reducer';
import player from '../modules/player/player.reducer';
import organization from '../modules/organization/organization.reducer';
import hierarchy from '../modules/hierarchy/hierarchy.reducer';
import deployment from '../modules/deployment/deployment.reducer';
import sound from '../modules/sound/sound.reducer';
import alert from '../modules/alert/alert.reducer';

const createRootReducer = (history: History) =>
  combineReducers<State>({
    auth,
    studiotel,
    planning,
    player,
    organization,
    hierarchy,
    deployment,
    sound,
    alert,
    router: connectRouter(history)
  });

export default createRootReducer;

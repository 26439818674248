import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { store } from './Store';
import { history } from './Store';
import { getLocaleObject } from './application/i18n.config';
import DateFnsUtils from '@date-io/date-fns';
import './application/i18n';

import createTheme from './application/theme';

const theme = createTheme();
const locale = getLocaleObject();

ReactDOM.render(
  <App
    store={store}
    history={history}
    theme={theme}
    utils={DateFnsUtils}
    locale={locale}
  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { HierarchyActionTypes } from './types';
import { HierachyAction } from './hierarchy.action';

const initialState = {
  pending: false,
  data: {
    hierarchy: undefined,
  },
  errors: undefined,
  initFetch: true,
};

const hierachy: Reducer<State['hierarchy'], HierachyAction> = (
  state: State['hierarchy'] = initialState,
  action: HierachyAction
) => {
  switch (action.type) {
    case HierarchyActionTypes.GET_HIERARCHY_PENDING: {
      return {
        ...state,
        pending: action.pending,
        errors: undefined,
      };
    }
    case HierarchyActionTypes.GET_HIERARCHY_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false,
      };
    }
    case HierarchyActionTypes.GET_HIERARCHY_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message,
      };
    }
    default: {
      return state;
    }
  }
};

export default hierachy;

import React from 'react';
import { useSelector } from 'react-redux';
import TreeItem from '@material-ui/lab/TreeItem';
import { Studiotel } from '../../../../@types/state/studiotel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PowerOffRoundedIcon from '@material-ui/icons/PowerOffRounded';
import { selectStateByStudiotelId } from '../../../studiotel/studiotel.selector';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '.MuiTreeItem-root': {
      fontSize: 10,
      fontWeight: 'bold',
    },
  },
}));

interface IProps {
  studiotel: Studiotel;
}

export default function StudiotelItem(props: IProps) {
  const { studiotel } = props;
  const classes = useStyles();
  const getStateByStudiotelId = useSelector(selectStateByStudiotelId);
  const studiotelState = getStateByStudiotelId(studiotel.id);

  function handleOnClick(studiotelId: number) {
    window.open(`/studiotels/${studiotelId}`, '_blank');
  }

  return (
    <TreeItem
      className={classes.root}
      key={`studiotel-${studiotel.id}`}
      nodeId={`studiotel-${studiotel.id.toString()}`}
      label={
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box
            fontWeight="bold"
            fontSize={13}
          >{`Studioplay-${studiotel.id}`}</Box>
          {studiotelState && studiotelState.connected === false && (
            <PowerOffRoundedIcon color="primary" />
          )}
        </Box>
      }
      onClick={() => handleOnClick(studiotel.id)}
    />
  );
}

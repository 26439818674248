import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import Box from '@material-ui/core/Box';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      flex: 1,
      border: '1px dashed',
      borderColor: theme.palette.text.secondary,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      svg: {
        color: theme.palette.text.secondary,
      },
    },
    upload: {
      color: theme.palette.text.secondary,
    },
    soundContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
    type: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
    dragOver: {
      transform: 'scale(1.05)',
      transition: 'all .2s',
    },
  })
);

interface IProps {
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
}

export default function Upload(props: IProps) {
  const { deployment, setDeployment, setIsStepValid } = props;
  const [type, setType] = useState('');
  const dropZoneEl = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();

  useEffect(() => {
    if (
      deployment.sound &&
      deployment.sound_title &&
      deployment.sound_title.length > 0 &&
      deployment.sound_description &&
      deployment.sound_description.length > 0 &&
      deployment.message_type &&
      deployment.message_type.length > 0
    ) {
      setIsStepValid(true);
    } else {
      if (!deployment.sound_id) {
        setIsStepValid(false);
      }
    }
  }, [
    deployment.message_type,
    deployment.sound,
    deployment.sound_description,
    deployment.sound_id,
    deployment.sound_title,
    setIsStepValid,
  ]);

  useEffect(() => {
    if (deployment.sound && deployment.sound_id) {
      const deploymentUpdated = {
        ...deployment,
      };

      delete deploymentUpdated.sound_id;
      delete deploymentUpdated.sound_title;
      delete deploymentUpdated.sound_description;
      delete deploymentUpdated.sound_path;
      delete deploymentUpdated.message_type;

      setDeployment(deploymentUpdated);
    }
  }, [deployment, setDeployment]);

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    dropZoneEl.current?.classList.add(`${classes.dragOver}`);
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    dropZoneEl.current?.classList.remove(`${classes.dragOver}`);
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (event.dataTransfer.items) {
      const { items } = event.dataTransfer;
      for (const item of items) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          const deploymentUpdated = {
            ...deployment,
            sound: file,
          };
          setDeployment(deploymentUpdated);
        }
      }
    }
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const deploymentUpdated = {
      ...deployment,
      sound_title: event.currentTarget.value,
    };
    setDeployment(deploymentUpdated);
  }

  function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const deploymentUpdated = {
      ...deployment,
      sound_description: event.currentTarget.value,
    };
    setDeployment(deploymentUpdated);
  }

  function handleMessageTypeChange(event: React.ChangeEvent<any>) {
    const { value } = event.target;
    setType(value);
    const deploymentUpdated = {
      ...deployment,
      message_type: value,
    };
    setDeployment(deploymentUpdated);
  }

  return (
    <>
      {!deployment.sound ? (
        <div
          id="sound-upload-drop-zone"
          ref={dropZoneEl}
          className={classes.container}
          onDragOverCapture={handleDragOver}
          onDragLeaveCapture={handleDragLeave}
          onDrop={handleDrop}
        >
          <Typography variant="subtitle1" align="center">
            Aucun son disponible pour cette organisation
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box>
              <AddCircleOutlineRoundedIcon fontSize="large" />
            </Box>
            <Typography align="center">
              Glissez et déposez votre fichier mp3 ici.
            </Typography>
            <Box>
              <Button className={classes.upload}>Upload</Button>
            </Box>
          </Box>
        </div>
      ) : (
        <div id="sound-upload-info" className={classes.soundContainer}>
          <TextField label="Titre" onChange={handleTitleChange} fullWidth />
          <TextField
            label="Description"
            onChange={handleDescriptionChange}
            fullWidth
          />
          <FormControl className={classes.type}>
            <InputLabel id="type-select">Type de message</InputLabel>
            <Select
              labelId="type-select"
              value={type}
              onChange={handleMessageTypeChange}
            >
              <MenuItem value="offhook">PRÉ-DÉCROCHÉ</MenuItem>;
              <MenuItem value="voicemail">REPONDEUR</MenuItem>;
              <MenuItem value="moh">ATTENTE</MenuItem>;
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}

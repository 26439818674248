import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { StudiotelConfigFile } from '../../stepper.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    maxHeight: `calc(100vh - ${350}px)`,
    overflow: 'auto',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    minWidth: 500,
  },
}));

interface IProps {
  configFile: StudiotelConfigFile;
  setConfigFile: React.Dispatch<React.SetStateAction<StudiotelConfigFile>>;
}

export default function Form(props: IProps) {
  const { configFile, setConfigFile } = props;
  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    setConfigFile({
      ...configFile,
      studiotel: { ...configFile.studiotel, [name]: value },
    });
  }

  return (
    <div className={classes.formContent}>
      <TextField
        name="url"
        label="URL"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="L'adresse est celle qui vous permet de vous connecter à la configuration de votre sytème de messagerie. Elle commence par http:// ou **https:// ** suivi de l'adresse IP de votre matériel, par exemple https://217.108.79.249."
        value={configFile?.studiotel?.url || ''}
        onChange={handleChange}
      />
      <TextField
        name="login"
        label="Login"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="new-login"
        value={configFile?.studiotel?.login || ''}
        onChange={handleChange}
      />
      <TextField
        name="password"
        label="Mot de passe"
        type="password"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="new-password"
        helperText="Le mot de passe de votre Livebox OPO est accessible via le menu de l'interface de la box."
        value={configFile?.studiotel?.password || ''}
        onChange={handleChange}
      />
      <TextField
        name="phone_number"
        label="Numéro de poste du standard"
        type="text"
        variant="outlined"
        size="small"
        margin="normal"
        autoComplete="off"
        helperText="Numéro de la ligne sur laquelle les annonces seront installées"
        value={configFile?.studiotel?.phone_number || ''}
        onChange={handleChange}
      />
    </div>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../layout.component';
import Aside from '../aside.component';
import svg from './img_step1.svg';
import { selectFleetTopLevel } from '../../../../../hierarchy/hierarchy.selector';
import OrganizationSelect from './organizationSelect.component';
import { InProgressDeployment } from '../../../../../../@types/deployment';

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
}

export default function Step1(props: IProps) {
  const { Controls, setIsStepValid } = props;
  const fleetTopLevel = useSelector(selectFleetTopLevel);

  return (
    <Layout
      title="Cible du déploiement"
      aside={
        <Aside
          svg={svg}
          title="Cible du déploiement"
          description="Tout d’abord, sélectionnez la flotte pour laquelle vous souhaitez effectuer votre déploiement."
        />
      }
      controls={<Controls />}
    >
      <OrganizationSelect
        organizations={fleetTopLevel}
        setIsStepValid={setIsStepValid}
      />
    </Layout>
  );
}

import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';

import { StudiotelConfig } from '../../../config/studiotel.config';

import StepperLayout from '../layout.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    '& #alert': {
      width: '100%',
    },
    '& #content': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& #button-group': {
      marginBottom: theme.spacing(2),
    },
    '& #config-file-input': {
      display: 'none',
    },
  },
}));

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  hasUploadedConfigFile: boolean;
  setHasUploadedConfigFile: React.Dispatch<React.SetStateAction<boolean>>;
  setStudiotelConfigFile: React.Dispatch<
    React.SetStateAction<StudiotelConfig | null>
  >;
  version: string | undefined;
}

export default function AskConfigFile(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    hasUploadedConfigFile,
    setHasUploadedConfigFile,
    setStudiotelConfigFile,
    version,
  } = props;
  const [haveConfigFile, setHaveConfigFile] = React.useState(false);
  const [errors, setErrors] = React.useState(false);
  const classes = useStyles();

  // set step valid by default if user haven't a config file to upload
  React.useEffect(() => {
    if (!haveConfigFile) {
      setStepValid(true);
    } else {
      if (hasUploadedConfigFile && !errors) {
        setStepValid(true);
      } else {
        setStepValid(false);
      }
    }
  }, [errors, hasUploadedConfigFile, haveConfigFile, setStepValid]);

  function handleHaveConfigFile(
    event: React.MouseEvent<HTMLElement>,
    value: boolean
  ) {
    setHaveConfigFile(value);
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files) {
      setHasUploadedConfigFile(true);
      const reader = new FileReader();

      reader.onload = (event: any) => {
        try {
          const { result } = reader;
          if (typeof result === 'string') {
            const json: any = JSON.parse(result);
            if ('user_configuration' in json) {
              setStudiotelConfigFile(json.user_configuration);
              setErrors(false);
            } else {
              setErrors(true);
            }
          }
        } catch (err) {
          setErrors(true);
        }
      };
      reader.readAsText(files[0]);
    }
  }

  return (
    <StepperLayout
      hintTitle={'Information de configuration'}
      hintContent={
        <Typography variant="caption" color="textSecondary" align="center">
          {
            'Si vous avez déjà effectué la configuration de votre Studioplay vous pouvez charger votre fichier à cette étape.'
          }
        </Typography>
      }
      hintAction={hintAction && hintAction}
      title={'Possédez-vous un fichier de configuration ?'}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        <div id="content">
          <ToggleButtonGroup
            id="button-group"
            exclusive
            size="small"
            value={haveConfigFile}
            color="primary"
            onChange={handleHaveConfigFile}
          >
            <ToggleButton value={false}>non</ToggleButton>
            <ToggleButton value={true}>oui</ToggleButton>
          </ToggleButtonGroup>

          <input
            id="config-file-input"
            accept=".json"
            type="file"
            onChange={handleFileUpload}
            disabled={!haveConfigFile}
          />
          <label htmlFor="config-file-input">
            <Button
              variant="contained"
              size="small"
              disabled={!haveConfigFile}
              endIcon={<GetAppRoundedIcon />}
              component="span"
            >
              charger un fichier
            </Button>
          </label>
          <FormHelperText>
            Importer votre fichier de configuration ici
          </FormHelperText>
        </div>
      </div>
      {hasUploadedConfigFile && errors && (
        <Alert severity="error">
          Un problème est survenu lors de l'import du fichier de configuration
        </Alert>
      )}
      {hasUploadedConfigFile && !errors && (
        <Alert severity="success">
          Votre fichier de configuration a bien été importé
        </Alert>
      )}
    </StepperLayout>
  );
}

import React, { useRef, useEffect } from 'react';
import { SelectProps, DispachProps } from './player.connector';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useInterval } from '../../utils/hook';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  player: {
    display: 'none'
  }
}));

interface IProps {
  sounds: SelectProps['sounds'];
  messageType: SelectProps['messageType'];
  setCurrentTime: DispachProps['setCurrentTime'];
}

const Player: React.FC<IProps> = props => {
  const { sounds, messageType, setCurrentTime } = props;
  const classes = useStyles();
  const playerRef = useRef<HTMLAudioElement | null>(null);

  useInterval(() => {
    if (playerRef && playerRef.current) {
      setCurrentTime(
        moment.utc(playerRef.current.currentTime * 1000).format('HH:mm:ss')
      );
    }
  }, 1000);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      player.pause();
      player.currentTime = 0;
      player.play();
    }
  }, [messageType, sounds]);

  return (
    <>
      {sounds && messageType && (
        <audio
          ref={playerRef}
          controls
          autoPlay
          src={sounds.path}
          className={classes.player}
          id={messageType}
        ></audio>
      )}
    </>
  );
};

export default Player;

import React from 'react';
import Box from '@material-ui/core/Box';
import { Deployment } from '../../../../@types/state/deployment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getMetaDependingOnStatus } from '../../helpers';
import { useWindowHeight } from '../../../utils/hook';
import StudiotelDisplay from '../../../studiotel/components/studiotelDisplay/studiotelDisplay.component';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: '100%',
  },
  title: {
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  container: (height: number) => ({
    maxHeight: height / 2.5,
  }),
  tableCell: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: 'status',
    align: 'left',
    label: 'Status',
    maxWidth: 180,
  },
  {
    id: 'studiotels_ids',
    label: 'Cible',
    align: 'left',
    maxWidth: 150,
  },
  {
    id: 'created_at',
    align: 'left',
    label: 'Date',
    minWidth: 100,
  },
  {
    id: 'description',
    label: 'Info',
    align: 'left',
  },
];

interface IProps {
  deployments: Deployment[];
  title: string;
  action?: React.ReactNode;
}

export default function DeploymentList(props: IProps) {
  const { title, deployments, action } = props;
  const height = useWindowHeight();
  const classes = useStyles(height);

  function sortData(deployments: Deployment[]) {
    return deployments.sort(function (a, b) {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
  }

  function getStatusContent(column: Column, value: number) {
    const meta = getMetaDependingOnStatus(value);

    return (
      <TableCell
        key={column.id}
        align={column.align}
        style={{ maxWidth: column.maxWidth }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <Box mx={1}>{meta && meta.icon}</Box>
          <Box>{meta && meta.description}</Box>
        </Box>
      </TableCell>
    );
  }

  function getStudiotelIdsContent(column: Column, value: number[]) {
    return (
      <TableCell
        key={value.toLocaleString()}
        align={column.align}
        style={{
          maxWidth: column.maxWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <Tooltip
          title={
            <>
              {value.map((id: number) => {
                return <StudiotelDisplay studiotelId={id} key={id} />;
              })}
            </>
          }
        >
          <div
            key={value.toLocaleString()}
            id="studiotel-ids-content-wrapper"
            style={{
              overflow: 'inherit',
              textOverflow: 'inherit',
              whiteSpace: 'inherit',
              paddingRight: 100,
            }}
          >
            {value.map((studiotelId: number) => {
              return `Studioplay ${studiotelId}${value.length > 1 ? ', ' : ''}`;
            })}
          </div>
        </Tooltip>
      </TableCell>
    );
  }

  function getCreatedAtContent(column: Column, value: Date) {
    return (
      <TableCell key={column.id} align={column.align}>
        {moment(value).format('dddd D MMMM YYYY à HH:mm:ss')}
      </TableCell>
    );
  }

  function getDescriptionContent(column: Column, value: string) {
    return (
      <TableCell key={column.id} align={column.align}>
        <Tooltip title={value || 'aucune info'}>
          <InfoRoundedIcon />
        </Tooltip>
      </TableCell>
    );
  }

  return (
    <div id="deployment-list" className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Divider />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={`${column.id}-${column.label}`}
                    className={classes.tableCell}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(deployments).map((deployment: Deployment) => {
              return (
                <TableRow
                  key={deployment.id}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  {columns.map((column: Column) => {
                    // @ts-ignore
                    const value = deployment[column.id];

                    if (column.id === 'status') {
                      return getStatusContent(column, value);
                    } else if (column.id === 'studiotels_ids') {
                      return getStudiotelIdsContent(column, value);
                    } else if (column.id === 'created_at') {
                      return getCreatedAtContent(column, value);
                    } else if (column.id === 'description') {
                      return getDescriptionContent(column, value);
                    } else {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.maxWidth }}
                        >
                          {value}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {action && action}
    </div>
  );
}

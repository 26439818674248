import { State } from '../../@types/state';
import { Planning } from '../../@types/state/planning';
import { selectCurrentStudiotel } from '../studiotel/studiotel.selector';
import { PbxStatus } from './config/planning.config';

export const selectPlannings = (
  state: State
): State['planning']['data']['plannings'] => {
  return state.planning.data && state.planning.data.plannings
    ? state.planning.data.plannings
    : undefined;
};

export const selectPlanningById = (
  state: State,
  id: string
): Planning | null => {
  const plannings = selectPlannings(state);
  if (!plannings) return null;

  const planning = plannings[id];
  if (!planning) return null;

  return planning;
};

export const selectCurrentStudiotelOffhookPlanning = (
  state: State
): Planning | null => {
  const currentStudioTel = selectCurrentStudiotel(state);
  if (!currentStudioTel) return null;

  const { offhook_planning_id } = currentStudioTel;

  if (!offhook_planning_id) return null;

  const offhookPlanning = selectPlanningById(
    state,
    offhook_planning_id.toString()
  );
  if (!offhookPlanning) return null;

  return offhookPlanning;
};

export const selectCurrentStudiotelVoicemailPlanning = (
  state: State
): Planning | null => {
  const currentStudioTel = selectCurrentStudiotel(state);
  if (!currentStudioTel) return null;

  const { voicemail_planning_id } = currentStudioTel;

  if (!voicemail_planning_id) return null;

  const voicemailPlanning = selectPlanningById(
    state,
    voicemail_planning_id.toString()
  );
  if (!voicemailPlanning) return null;

  return voicemailPlanning;
};

export const selectCurrentStudiotelMohPlanning = (
  state: State
): Planning | null => {
  const currentStudioTel = selectCurrentStudiotel(state);
  if (!currentStudioTel) return null;

  const { moh_planning_id } = currentStudioTel;

  if (!moh_planning_id) return null;

  const mohPlanning = selectPlanningById(state, moh_planning_id.toString());
  if (!mohPlanning) return null;

  return mohPlanning;
};

export const selectCurrentDate = (
  state: State
): State['planning']['currentDate'] => {
  return state.planning.currentDate && state.planning.currentDate;
};

export const selectPbxStatus = (
  state: State
): State['planning']['pxbStatus'] => {
  return state.planning.pxbStatus && state.planning.pxbStatus;
};

export const selectCurrentStudiotelPlannings = (
  state: State
): State['planning']['data']['plannings'] => {
  const currentStudioTel = selectCurrentStudiotel(state);
  if (!currentStudioTel) return undefined;

  const hasFetchPlannings = !!selectPlannings(state);
  if (!hasFetchPlannings) return undefined;

  const plannings = currentStudioTel.plannings_ids.map((id: string) => {
    if (state.planning.data && state.planning.data.plannings) {
      return state.planning.data.plannings[id];
    }

    return undefined;
  });
  if (!plannings) return undefined;

  const pbxStatus = selectPbxStatus(state);

  let filteredPlanning: State['planning']['data']['plannings'];

  switch (pbxStatus) {
    case PbxStatus.OPEN: {
      filteredPlanning = plannings.filter(
        (planning: Planning) =>
          planning.message_type === 'moh' || planning.message_type === 'offhook'
      );
      break;
    }
    case PbxStatus.CLOSE: {
      filteredPlanning = plannings.filter(
        (planning: Planning) => planning.message_type === 'voicemail'
      );
      break;
    }
    case PbxStatus.GLOBAL: {
      filteredPlanning = plannings;
      break;
    }
  }

  return filteredPlanning;
};

// import { ThunkDispatch } from 'redux-thunk';
import { State } from '../../../@types/state';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import Player from './player.component';
import { selectSounds, selectMessageType } from '../player.selector';
import * as playerAction from '../player.action';

export interface SelectProps {
  sounds: State['player']['sounds'];
  messageType: State['player']['message_type'];
}

export interface DispachProps {
  setCurrentTime: (currentTime: State['player']['currentTime']) => void;
}

const mapDispatchToProps = (
  dispach: ThunkDispatch<State, {}, playerAction.PlayerAction>
): DispachProps => {
  return {
    setCurrentTime: (currentTime: State['player']['currentTime']) => {
      dispach(playerAction.setCurrentTime(currentTime));
    }
  };
};

const mapStateToProps = (state: State) => {
  return {
    sounds: selectSounds(state),
    messageType: selectMessageType(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player);

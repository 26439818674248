import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';

type Props = {
  isAuth: boolean;
} & RouteProps;

const AuthRoute = ({ component: Component, isAuth, ...rest }: Props) => {
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        return isAuth ? (
          Component && <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AuthRoute;

import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Exit from '../controls/exit.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hint: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      flex: 1,
    },
    title: {
      fontWeight: 'bold',
      margin: `${theme.spacing(1)}px 0`,
    },
    exit: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
    },
  })
);

interface IProps {
  svg: string;
  title: string;
  description: string;
}

export default function Aside(props: IProps) {
  const { svg, title, description } = props;
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();

  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <div className={classes.hint}>
        <img src={svg} alt="img-step-3" />
        <Typography
          variant="body2"
          className={classes.title}
          color="textSecondary"
        >
          {title}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </div>

      <div className={classes.exit}>
        <Button color="primary" onClick={handleClick}>
          Quitter
        </Button>
      </div>
      <Exit open={open} setOpen={setOpen} />
    </>
  );
}

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontSize: theme.typography.h6.fontSize,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    }
  })
);
interface IProps {
  children: string | undefined;
}

const Title = React.forwardRef((props: IProps, ref: React.Ref<any>) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div ref={ref} className={classes.title}>
      {children}
    </div>
  );
});

export default Title;

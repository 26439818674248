import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar as MuiAppBar } from '@material-ui/core';
import Tabs from '../components/tabs/tabs.component';
import Menu from './menu/appBarMenu.connector';
import logo from '../../../application/images/logo.svg';
import { AuthRoute } from '../../../router/routes.config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 72,
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      margin: theme.spacing(0, 2),
    },
    tabs: {
      marginLeft: theme.spacing(10),
      flex: 1,
    },
  })
);

interface IProps {
  routeMetaData: AuthRoute;
}

export default function AppBar(props: IProps) {
  const { routeMetaData } = props;
  const classes = useStyles();

  return (
    <MuiAppBar className={classes.container}>
      <img className={classes.logo} src={logo} height="40" alt="logo"></img>

      <Tabs classe={classes.tabs} routeMetaData={routeMetaData} />
      <Menu />
    </MuiAppBar>
  );
}

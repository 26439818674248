import { State } from '../../@types/state';
import { MapSound, Sound } from '../../@types/state/sound';

export function selectInitFetch(state: State): boolean {
  return state.sound.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.sound.pending;
}

export function selectSounds(state: State): MapSound | undefined {
  const { sounds } = state.sound.data;

  return sounds;
}

export function selectSoundsList(state: State): Sound[] {
  const sounds = selectSounds(state);

  if (sounds === undefined) return [];

  const soundsList = Object.values(sounds);

  return soundsList;
}

export function selectSoundById(state: State) {
  return function(soundId: number | undefined): Sound | null {
    const sounds = selectSounds(state);
    if (sounds === undefined || soundId === undefined) return null;

    return sounds[soundId];
  };
}

import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { PbxStatus } from '../../config/planning.config';
const offsetX = 4;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 !important',
      '& > p': {
        textAlign: 'center',
      },
      '& > span': {
        display: 'table',
        margin: '0 auto',
      },
    },
    today: {
      color: theme.dayScaleCellBase.today.color,

      '& > span': {
        display: 'block',
        color: theme.dayScaleCellBase.today.color,
        backgroundColor: theme.dayScaleCellBase.today.background,
        textAlign: 'center',
      },
    },
    pbxStatusGlobal: {
      '&::before': {
        content: '""',
        position: 'relative',
        display: 'block',
        top: `${76 - theme.spacing(1)}px`,
        left: 0,
        height: theme.spacing(1),
        // width = size of the container * % of space occupation - offsetX
        width: `${(100 / 3) * 2 - offsetX}%`,
        background: theme.pbx.open.color,
        zIndex: 100,
      },
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        top: 0,
        left: 0,
        height: theme.spacing(1),
        width: '100%',
        background: theme.pbx.close.color,
        zIndex: 99,
      },
    },
    pbxStatusOpen: {
      '&::after': {
        position: 'relative',
        display: 'block',
        top: 0,
        left: 0,
        content: '""',
        height: theme.spacing(1),
        width: '100%',
        background: theme.pbx.open.color,
        zIndex: 100,
      },
    },
    pbxStatusClose: {
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'block',
        top: 0,
        left: 0,
        height: theme.spacing(1),
        width: '100%',
        background: theme.pbx.close.color,
        zIndex: 100,
      },
    },
  })
);

const DayScaleCellBase = (props: any) => {
  const { today, pbxStatus } = props;
  const classes = useStyles();
  let activeClasse: String;

  switch (pbxStatus) {
    case PbxStatus.GLOBAL: {
      activeClasse = 'pbxStatusGlobal';
      break;
    }
    case PbxStatus.OPEN: {
      activeClasse = 'pbxStatusOpen';
      break;
    }
    case PbxStatus.CLOSE: {
      activeClasse = 'pbxStatusClose';
      break;
    }
    default: {
      activeClasse = 'pbxStatusGlobal';
    }
  }
  // TODO : extends type of classeName to be [key: string]: string
  if (today) {
    return (
      <WeekView.DayScaleCell
        {...props}
        // @ts-ignore
        className={`${classes.root} ${classes.today} ${classes[activeClasse]}`}
      />
    );
  }

  return (
    <WeekView.DayScaleCell
      {...props}
      // @ts-ignore
      className={`${classes.root} ${activeClasse} ${classes[activeClasse]}`}
    />
  );
};

export default DayScaleCellBase;

import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import {
  KeyboardDatePicker,
  MaterialUiPickersDate
} from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {}
  })
);

interface IProps {
  disabled: boolean;
  label: string;
  selectedDate: MaterialUiPickersDate;
  setSelectedDate: Dispatch<SetStateAction<any>>;
  className?: string;
}

const DatePicker = (props: IProps) => {
  const { selectedDate, setSelectedDate, label, disabled, className } = props;
  const classes = useStyles();

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date);
  };

  return (
    <KeyboardDatePicker
      disabled={disabled}
      className={`${clsx(classes.root, className)}`}
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      label={label}
      value={selectedDate}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
    />
  );
};

export default DatePicker;

const config = {
  waitForTime: 450,
  waitForTimeSetup:2000,
  deployment: {
    minTime: 30,
  },
  search: {
    debounceTime: 250,
  },
  featureFlip: {
    pbxStatusGlobal: false
  },
  setup: {
    connexionTime: 1000
  }
};

export default config;

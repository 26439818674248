import { Reducer } from 'redux';
import { State } from '../../@types/state';
import { AlertActionTypes } from './types';
import { AlertAction } from './alert.action';

const initialState = {
  pending: false,
  data: {
    alerts: undefined
  },
  errors: undefined,
  initFetch: true
};

const alert: Reducer<State['alert'], AlertAction> = (
  state: State['alert'] = initialState,
  action: AlertAction
) => {
  switch (action.type) {
    case AlertActionTypes.GET_ALERTS_PENDING: {
      return {
        ...state,
        pending: action.pending
      };
    }
    case AlertActionTypes.GET_ALERTS_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.data,
        initFetch: false
      };
    }
    case AlertActionTypes.GET_ALERTS_ERROR: {
      return {
        ...state,
        pending: false,
        errors: action.message
      };
    }

    default: {
      return state;
    }
  }
};

export default alert;

export enum AuthActionTypes {
  LOGIN_PENDING = '@@auth/LOGIN_PENDING',
  LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@auth/LOGIN_ERROR',
  LOGOUT_PENDING = '@@auth/LOGOUT_PENDING',
  LOGOUT_SUCCESS = '@@auth/LOGOUT_SUCCESS',
  LOGOUT_ERROR = '@@auth/LOGOUT_ERROR',
  RESET_PASSWORD_PENDING = '@@auth/RESET_PASSWORD_PENDING',
  RESET_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = '@@auth/RESET_PASSWORD_ERROR',
  ASK_RESET_PASSWORD_LINK_PENDING = '@@auth/ASK_RESET_PASSWORD_LINK_PENDING',
  ASK_RESET_PASSWORD_LINK_SUCCESS = '@@auth/ASK_RESET_PASSWORD_LINK_SUCCESS',
  ASK_RESET_PASSWORD_LINK_ERROR = '@@auth/ASK_RESET_PASSWORD_LINK_ERROR',
  REFRESH_TOKEN_PENDING = '@@auth/REFRESH_TOKEN_PENDING',
  REFRESH_TOKEN_SUCCESS = '@@auth/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR = '@@auth/REFRESH_TOKEN_ERROR',
}

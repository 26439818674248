import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import { Step as MuiStep } from '@material-ui/core/';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import config, {
  Step,
  Steps,
  StudiotelConfig,
  ActionSelected,
  Level,
} from '../../config/studiotel.config';

import * as studiotelAction from '../../studiotel.action';
import { selectStudiotelById } from '../../studiotel.selector';

import { UpdateStudiotelActionPayload } from '../../../../@types/action/studiotel';

import ExitSetupDialog from '../../../studiotel/components/setup/dialog/exitSetup.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  stepper: {
    padding: theme.spacing(2),
    '& span': {
      fontSize: theme.typography.caption.fontSize,
    },
    '& .MuiStepLabel-label': {
      marginTop: theme.spacing(1),
    },
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    padding: '1% 15%',
  },
}));

interface ParamTypes {
  studiotelId: string;
}

export interface NetworkConfig {
  dhcp: boolean;
  ip: string;
  mask: string;
  gateway: string;
}

export interface VlanConfig {
  [index: string]: any;
  id: number;
  dhcp: boolean;
  ip: string;
  mask: string;
  gateway: string;
}

export interface ProxyConfig {
  https: {
    server: string;
    port: number;
    user: string;
    password: string;
  };
}

export interface StudiotelConfigFile {
  studiotel: {
    type: StudiotelConfig;
    hostname?: string;
    port?: number;
    dtmf?: string;
    url?: string;
    login?: string;
    password?: string;
    phone_number?: string;
    offhook_tone?: string;
    nb_att?: number;
    nb_rep?: number;
    nb_pre?: number;
  };
  network: NetworkConfig;
  dns: string[];
  vlan: VlanConfig[];
  proxy: ProxyConfig;
}

export interface NetworkConfigFile {
  network: NetworkConfig;
  dns: string[];
  vlan: VlanConfig[];
  proxy: ProxyConfig;
}

const initialValue = {
  studiotel: {
    type: StudiotelConfig.NONE,
  },
  network: {
    dhcp: true,
    ip: '',
    mask: '',
    gateway: '',
  },
  dns: ['', ''],
  proxy: {
    https: {
      server: '',
      port: 443,
      user: '',
      password: '',
    },
  },
  vlan: [
    {
      id: 0,
      dhcp: true,
      ip: '',
      mask: '',
      gateway: '',
    },
  ],
};

export default function SetupStepper() {
  const { studiotelId } = useParams<ParamTypes>();
  const studiotel = useSelector(selectStudiotelById)(parseInt(studiotelId));
  const [activeStep, setActiveStep] = React.useState(Steps.STEP_1);
  const [stepValid, setStepValid] = React.useState(false);
  const [openExitDialog, setOpenDialog] = React.useState(false);
  const [level, setLevel] = React.useState(Level.NOVICE);
  const [hasUploadedConfigFile, setHasUploadedConfigFile] =
    React.useState<boolean>(false);

  const [studiotelConfigFile, setStudiotelConfigFile] =
    React.useState<StudiotelConfigFile | null>(null);

  const [actionSelected, setActionSelected] = React.useState(
    ActionSelected.TELEVERSE
  );

  /**
   * Hydrate config once, even when poll is running...
   */
  const isHydrated = React.useRef(false);
  React.useEffect(() => {
    if (studiotel && !isHydrated.current) {
      isHydrated.current = true;

      setStudiotelConfigFile({
        studiotel: {
          ...studiotel?.user_configuration?.studiotel,
          type:
            studiotel?.user_configuration?.studiotel?.type ||
            initialValue.studiotel.type,
        },
        network: studiotel?.user_configuration?.network || initialValue.network,
        dns: studiotel?.user_configuration?.dns || initialValue.dns,
        vlan: studiotel?.user_configuration?.vlan || initialValue.vlan,
        proxy: studiotel?.user_configuration?.proxy || initialValue.proxy,
      });
    }
  }, [studiotel]);

  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { Content, nextStep, previousStep } = config.steps[activeStep];

  function handleExit() {
    setOpenDialog(true);
  }

  async function handleNextClick() {
    if (activeStep === Steps.STEP_6) {
      history.goBack();
    }

    if (activeStep === Steps.STEP_5 && nextStep && studiotelConfigFile) {
      // TELEVERSE - validation step
      if (actionSelected === ActionSelected.TELEVERSE) {
        setLoading(true);
        setActiveStep(nextStep);
        const payload: UpdateStudiotelActionPayload = {
          user_configuration: studiotelConfigFile,
        };
        await dispatch(
          studiotelAction.updateStudiotel(payload, parseInt(studiotelId))
        );
        setLoading(false);

        return;
      }
      // UPLOAD - validation step
      if (actionSelected === ActionSelected.UPLOAD) {
        let link = document.createElement('a');
        const filename = 'config';
        const studiotelConfigFileToUpload = {
          user_configuration: {
            ...studiotelConfigFile,
          },
        };
        const data = JSON.stringify(studiotelConfigFileToUpload);
        let blob = new Blob([data], {
          type: 'application/json',
        });
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.json`;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    }

    // upload choise
    if (activeStep === Steps.STEP_2 && hasUploadedConfigFile) {
      setActiveStep(Steps.STEP_5);

      return;
    }
    if (nextStep) {
      setActiveStep(nextStep);
    }
  }

  function handlePreviousClick() {
    if (activeStep === Steps.STEP_2) {
      setActiveStep(Steps.STEP_1);
    }

    if (activeStep === Steps.STEP_3 && level === Level.ADVANCED) {
      setActiveStep(Steps.STEP_1);
      setLevel(Level.NOVICE);
    }
    if (activeStep === Steps.STEP_3 && level === Level.NOVICE) {
      setActiveStep(Steps.STEP_2);
    }
    if (activeStep === Steps.STEP_4 && level === Level.ADVANCED) {
      setActiveStep(Steps.STEP_3);
    }
    if (activeStep === Steps.STEP_4 && level === Level.NOVICE) {
      setActiveStep(Steps.STEP_2);
    }

    if (previousStep) {
      setActiveStep(previousStep);
    }
  }

  return (
    <div id="setup-stepper-container" className={classes.container}>
      <Stepper
        id="stepper"
        className={classes.stepper}
        activeStep={activeStep}
        alternativeLabel
      >
        {Object.values(config.steps).map((step: Step) => {
          return (
            <MuiStep key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </MuiStep>
          );
        })}
      </Stepper>

      <div className={classes.contentContainer}>
        <Content
          hintAction={
            <Button color="primary" onClick={handleExit}>
              quitter
            </Button>
          }
          action={
            <>
              <Button
                color="primary"
                disabled={
                  activeStep === Steps.STEP_1 ||
                  (hasUploadedConfigFile && activeStep === Steps.STEP_5)
                }
                onClick={handlePreviousClick}
              >
                retour
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={!stepValid}
                onClick={handleNextClick}
              >
                valider
              </Button>
            </>
          }
          stepValid={stepValid}
          setStepValid={setStepValid}
          setActiveStep={setActiveStep}
          hasUploadedConfigFile={hasUploadedConfigFile}
          setHasUploadedConfigFile={setHasUploadedConfigFile}
          studiotelConfigFile={studiotelConfigFile}
          setStudiotelConfigFile={setStudiotelConfigFile}
          actionSelected={actionSelected}
          setActionSelected={setActionSelected}
          level={level}
          setLevel={setLevel}
          loading={loading}
          version={studiotel?.version}
        />
      </div>

      <ExitSetupDialog open={openExitDialog} setOpen={setOpenDialog} />
    </div>
  );
}

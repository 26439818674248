import React from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import loginImg from '../../application/images/login.png';
import AuthForm from '../../modules/auth/components/form/auth.component';
import Loader from '../../modules/utils/components/loader.component';
import ResetDialog from '../../modules/auth/components/dialog/reset.component';
import { Dialogs } from '../../modules/auth/config/auth.config';
import * as authAction from '../../modules/auth/auth.action';
import { selectFetchPending as selectFetchPendingAuth } from '../../modules/auth/auth.selector';

import { ValidRefreshToken, RefreshTokenOptions } from '../../@types/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      display: 'flex',
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      background: theme.login.background,
    },
    container: {
      display: 'flex',
      height: '50%',
      width: '70%',
      maxWidth: '800px',
      minHeight: '450px',
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
    },
    img: {
      objectFit: 'cover',
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    authForm: {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })
);

export default function Login() {
  const [open, setOpen] = React.useState<Dialogs>({
    confirm: false,
  });
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const fetchPendingAuth = useSelector(selectFetchPendingAuth);

  React.useEffect(() => {
    async function authWithRefreshToken() {
      const searchParams = new URLSearchParams(location.search);

      const h = searchParams.get('h');

      if (h) {
        const refreshToken: ValidRefreshToken = {
          grant_type: 'refresh_token',
          refresh_token: h,
        };
        const options: RefreshTokenOptions = {
          authWithRefreshToken: true,
        };
        await dispatch(authAction.refreshToken(refreshToken, options));
      }
    }
    authWithRefreshToken();
  }, [dispatch, location.search]);

  return (
    <div id="login-view" className={classes.view}>
      {fetchPendingAuth && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}
      <div id="login-container" className={classes.container}>
        <img
          src={loginImg}
          className={classes.img}
          alt="login"
          height="100%"
        ></img>
        <AuthForm classe={classes.authForm} open={open} setOpen={setOpen} />
        <ResetDialog open={open} onClose={setOpen} />
      </div>
    </div>
  );
}

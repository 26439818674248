import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      backgroundColor: theme.workflow.content.background,
      boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.2)`,
    },
    aside: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minWidth: '30%',
      padding: theme.spacing(1),
      borderRight: `1px solid #00000029`,
    },
    content: {
      flexGrow: 3,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.spacing(1),
      overflow: 'auto',
    },
    children: {
      flex: 1,
    },
    controls: {
      textAlign: 'right',
      padding: theme.spacing(1),
    },
  })
);

interface IProps {
  title: string;
  aside: React.ReactNode;
  children: React.ReactNode;
  controls: React.ReactNode;
}

export default function Layout(props: IProps) {
  const { title, aside, children, controls } = props;
  const classes = useStyles();

  return (
    <div id="workflow-layout" className={classes.container}>
      <div id="workflow-layout-aside" className={classes.aside}>
        {aside && aside}
      </div>

      <div id="workflow-layout-content" className={classes.content}>
        <Typography variant="h5" color="textSecondary">
          {title}
        </Typography>

        <div id="workflow-layout-children-content" className={classes.children}>
          {children && children}
        </div>

        <div id="workflow-controls" className={classes.controls}>
          {controls && controls}
        </div>
      </div>
    </div>
  );
}

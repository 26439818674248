import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInterval } from '../modules/utils/hook';
import { Switch, Route, useLocation } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import {
  authRoutes,
  AuthRoute,
  getRouteMetaDataFromPathName,
} from '../router/routes.config';

import * as studiotelAction from '../modules/studiotel/studiotel.action';
import * as planningAction from '../modules/planning/planning.action';
import * as organizationAction from '../modules/organization/organization.action';
import * as alertAction from '../modules/alert/alert.action';

import Player from '../modules/player/components/player.connector';
import AppBar from '../modules/appBar/components/appBar.component';
import Drawer from '../modules/utils/components/drawer/drawer.component';
import FleetContent from '../modules/fleet/components/drawer/fleetContent.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100%',
    },
    content: {
      flex: 1,
      paddingTop: theme.header.height,
      backgroundColor: theme.palette.background.paper,
    },
  })
);

export default function Layout() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const routeMetaData = getRouteMetaDataFromPathName(location.pathname);

  // initial API call
  useEffect(() => {
    dispatch(studiotelAction.getStudiotels());
    dispatch(studiotelAction.getStudiotelsState());
    dispatch(planningAction.getPlannings());
    dispatch(organizationAction.getOrganizations());
    dispatch(alertAction.getAlerts());
  }, [dispatch]);

  // API pooling
  useInterval(() => {
    dispatch(studiotelAction.getStudiotels());
    dispatch(studiotelAction.getStudiotelsState());
    // dispatch(alertAction.getAlerts());
  }, 3000);

  return (
    <div id="layout-root" className={classes.container}>
      <CssBaseline />
      {routeMetaData && routeMetaData.appBar && (
        <AppBar routeMetaData={routeMetaData} />
      )}
      {routeMetaData && routeMetaData.drawer && (
        <Drawer content={<FleetContent />} />
      )}
      <Player />
      <main id="layout-content" className={classes.content}>
        <Switch>
          {authRoutes.map((route: AuthRoute) => (
            <Route key={route.path} path={route.path} exact>
              {route.component}
            </Route>
          ))}
        </Switch>
      </main>
    </div>
  );
}

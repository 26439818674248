import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

import { ipValidation } from 'modules/utils/helpers/validation.helpers';

import { StudiotelConfigFile, VlanConfig } from '../stepper.component';

import StepperLayout from '../layout.component';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: `calc(100vh - ${350}px)`,
    overflow: 'auto',
    padding: theme.spacing(2),
    '& .MuiInputBase-root': {
      maxWidth: 300,
    },
    '& #section': {
      marginTop: theme.spacing(2),
    },
    '& #proxy-container': {
      display: 'flex',
      alignItems: 'center',
    },
    '& #proxy-server': {
      width: 300,
    },
    '& #proxy-port': {
      width: 100,
    },
    '& #proxy-separator': {
      margin: theme.spacing(0, 1),
    },
    '& #add-vlan': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
}));

interface IProps {
  hintAction: React.ReactNode;
  action: React.ReactNode;
  setStepValid: React.Dispatch<React.SetStateAction<boolean>>;
  studiotelConfigFile: StudiotelConfigFile;
  setStudiotelConfigFile: React.Dispatch<
    React.SetStateAction<StudiotelConfigFile>
  >;
  version: string | undefined;
}

export default function Network(props: IProps) {
  const {
    hintAction,
    action,
    setStepValid,
    studiotelConfigFile,
    setStudiotelConfigFile,
    version,
  } = props;
  const [haveDns, setHaveDns] = React.useState(
    studiotelConfigFile.dns.length > 1 ? true : false
  );
  const [haveProxy, setHaveProxy] = React.useState(
    studiotelConfigFile.proxy.https.server.length > 1 ? true : false
  );
  const [errors, setErrors] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (studiotelConfigFile.network && !errors) {
      // TODO: improve validation step rules
      // if (studiotelConfigFile.network.dhcp) {
      //   if (studiotelConfigFile.network.ip) {
      //     setStepValid(true);
      //   } else {
      //     setStepValid(false);
      //   }
      // }

      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [studiotelConfigFile.network, setStepValid, errors]);

  function handleActivateDhcp(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const booleanValue = value === 'true' ? true : false;

    setStudiotelConfigFile({
      ...studiotelConfigFile,
      network: {
        ...studiotelConfigFile.network,
        dhcp: booleanValue,
        ip: '',
        mask: '',
        gateway: '',
      },
    });
  }

  function handleDhcpChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'ip' && value.length > 0) {
      const testIp = ipValidation(value);

      if (!testIp) {
        setErrors(true);
      } else {
        setErrors(false);
      }
    }
    setStudiotelConfigFile({
      ...studiotelConfigFile,
      network: { ...studiotelConfigFile.network, [name]: value },
    });
  }

  function handleActivateDns(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const booleanValue = value === 'true' ? true : false;

    if (booleanValue) {
      setStudiotelConfigFile({
        ...studiotelConfigFile,
        dns: ['', ''],
      });
    }

    setHaveDns(booleanValue);
  }

  function handleDnsChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    if (value.length === 0) {
      setErrors(false);
    }

    if (value.length > 0) {
      const testIp = ipValidation(value);

      if (!testIp) {
        setErrors(true);
      } else {
        setErrors(false);
      }
    }

    const newDns = [...studiotelConfigFile.dns];

    newDns[parseInt(name)] = value;

    setStudiotelConfigFile({
      ...studiotelConfigFile,
      dns: newDns,
    });
  }

  function handleActivateProxy(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    setHaveProxy(checked);
    if (!checked) {
      setStudiotelConfigFile({
        ...studiotelConfigFile,
        proxy: {
          https: {
            server: '',
            port: 443,
            user: '',
            password: '',
          },
        },
      });
    }
  }

  function handleProxyChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;
    setStudiotelConfigFile({
      ...studiotelConfigFile,
      proxy: {
        https: {
          ...studiotelConfigFile.proxy.https,
          [name]: value,
        },
      },
    });
  }

  function handleActivateVlan(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    const booleanValue = value === 'true' ? true : false;
    const newVlan = [...studiotelConfigFile.vlan];

    newVlan[parseInt(name)].dhcp = booleanValue;

    if (booleanValue) {
      newVlan[parseInt(name)].ip = '';
      newVlan[parseInt(name)].mask = '';
      newVlan[parseInt(name)].gateway = '';
    }

    setStudiotelConfigFile({
      ...studiotelConfigFile,
      vlan: newVlan,
    });
  }

  function handleVlanChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name;
    const value = event.target.value;

    const keys = name.split('-');
    const index = parseInt(keys[0]);
    const field: string = keys[1];

    if (field === 'ip' && value.length > 0) {
      const testIp = ipValidation(value);

      if (!testIp) {
        setErrors(true);
      } else {
        setErrors(false);
      }
    }

    const newVlan = [...studiotelConfigFile.vlan];

    newVlan[index][field] = value;

    setStudiotelConfigFile({
      ...studiotelConfigFile,
      vlan: newVlan,
    });
  }

  return (
    <StepperLayout
      hintTitle={'Vos paramètres de configuration réseau'}
      hintContent={
        <Typography variant="caption" color="textSecondary" align="center">
          {`Cette partie est réservée à nos installateurs.`}
        </Typography>
      }
      hintAction={hintAction && hintAction}
      title={'Vos paramètres de configuration réseau'}
      studiotelVersion={`Version du Studioplay : ${
        version ? version : 'inconnue'
      }`}
      action={action && action}
    >
      <div className={classes.container}>
        <Typography variant="subtitle2">DHCP</Typography>
        <Typography variant="caption">
          Les paramètres IP peuvent être déterminés automatiquement si votre
          réseau le permet. Sinon, vous devez demander les paramètres IP
          appropriés à votre administrateur réseau.
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="dhcp"
            value={studiotelConfigFile.network.dhcp}
            onChange={handleActivateDhcp}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Obtenir une adresse IP automatiquement"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Utiliser l'adresse IP suivante :"
            />
            <TextField
              name="ip"
              label="Adress IP"
              type="text"
              variant="outlined"
              size="small"
              margin="normal"
              autoComplete="off"
              disabled={studiotelConfigFile.network.dhcp}
              value={studiotelConfigFile.network.ip}
              onChange={handleDhcpChange}
              inputProps={{
                pattern:
                  '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
                onChange: handleDhcpChange,
              }}
            />
            <TextField
              name="mask"
              label="Masque sous réseau :"
              type="text"
              variant="outlined"
              size="small"
              margin="normal"
              autoComplete="off"
              disabled={studiotelConfigFile.network.dhcp}
              value={studiotelConfigFile.network.mask}
              onChange={handleDhcpChange}
            />
            <TextField
              name="gateway"
              label="Passerelle par défaut :"
              type="text"
              variant="outlined"
              size="small"
              margin="normal"
              autoComplete="off"
              disabled={studiotelConfigFile.network.dhcp}
              value={studiotelConfigFile.network.gateway}
              onChange={handleDhcpChange}
            />
          </RadioGroup>
        </FormControl>

        <Typography id="section" variant="subtitle2">
          DNS
        </Typography>
        <Typography variant="caption">
          Les paramètres IP peuvent être déterminés automatiquement si votre
          réseau le permet. Sinon, vous devez demander les paramètres IP
          appropriés à votre administrateur réseau.
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup name="dns" value={haveDns} onChange={handleActivateDns}>
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Obtenir les adresses des serveurs DNS automatiquement"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Utiliser l'adresse IP suivante :"
            />
            {studiotelConfigFile.dns.map((dns: string, index: number) => {
              return (
                <TextField
                  key={index}
                  name={`${index}`}
                  label={`DNS-${index}`}
                  type="text"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  autoComplete="off"
                  disabled={haveDns}
                  value={dns}
                  onChange={handleDnsChange}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        <Typography id="section" variant="subtitle2">
          PROXY
        </Typography>
        <Typography variant="caption">Paramètre du server proxy</Typography>
        <Switch
          name="proxy-activation"
          checked={haveProxy}
          onChange={handleActivateProxy}
        />

        <div id="proxy-container">
          <TextField
            id="proxy-server"
            name="server"
            label="Serveur PROXY web sécurisé"
            type="text"
            variant="outlined"
            size="small"
            margin="normal"
            autoComplete="off"
            disabled={!haveProxy}
            value={studiotelConfigFile.proxy.https.server}
            onChange={handleProxyChange}
          />
          <Typography id="proxy-separator"> : </Typography>
          <TextField
            id="proxy-port"
            name="port"
            label="Port"
            type="text"
            variant="outlined"
            size="small"
            margin="normal"
            autoComplete="off"
            disabled={!haveProxy}
            value={studiotelConfigFile.proxy.https.port}
            onChange={handleProxyChange}
          />
        </div>
        <TextField
          name="user"
          label="Nom d'utilisateur"
          type="text"
          variant="outlined"
          size="small"
          margin="normal"
          autoComplete="off"
          disabled={!haveProxy}
          value={studiotelConfigFile.proxy.https.user}
          onChange={handleProxyChange}
        />
        <TextField
          name="password"
          label="Mot de passe"
          type="text"
          variant="outlined"
          size="small"
          margin="normal"
          autoComplete="off"
          disabled={!haveProxy}
          value={studiotelConfigFile.proxy.https.password}
          onChange={handleProxyChange}
        />

        <Typography id="section" variant="subtitle2">
          VLAN
        </Typography>
        <Typography variant="caption">
          Les paramètres IP peuvent être déterminés automatiquement si votre
          réseau le permet. Sinon, vous devez demander les paramètres IP
          appropriés à votre administrateur réseau.
        </Typography>

        {studiotelConfigFile.vlan.map((vlan: VlanConfig, index: number) => {
          return (
            <FormControl component="fieldset" key={vlan.id}>
              <RadioGroup
                name={`${index}`}
                value={vlan.dhcp}
                onChange={handleActivateVlan}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Obtenir les adresses des serveurs DNS automatiquement"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Utiliser l'adresse IP suivante :"
                />
                <TextField
                  name={`${index}-id`}
                  label="id"
                  type="number"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  autoComplete="off"
                  disabled={studiotelConfigFile.vlan[index].dhcp}
                  value={studiotelConfigFile.vlan[index].id}
                  onChange={handleVlanChange}
                />
                <TextField
                  name={`${index}-ip`}
                  label="Adresse IP"
                  type="text"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  autoComplete="off"
                  disabled={studiotelConfigFile.vlan[index].dhcp}
                  value={studiotelConfigFile.vlan[index].ip}
                  onChange={handleVlanChange}
                />
                <TextField
                  name={`${index}-mask`}
                  label="Masque sous réseau"
                  type="text"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  autoComplete="off"
                  disabled={studiotelConfigFile.vlan[index].dhcp}
                  value={studiotelConfigFile.vlan[index].mask}
                  onChange={handleVlanChange}
                />
                <TextField
                  name={`${index}-gateway`}
                  label="Passerelle par défaut"
                  type="text"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  autoComplete="off"
                  disabled={studiotelConfigFile.vlan[index].dhcp}
                  value={studiotelConfigFile.vlan[index].gateway}
                  onChange={handleVlanChange}
                />
              </RadioGroup>
            </FormControl>
          );
        })}
        <div id="add-vlan">
          <Button disabled startIcon={<AddCircleOutlineRoundedIcon />}>
            Ajouter VLAN
          </Button>
        </div>
      </div>
    </StepperLayout>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import * as studiotelAction from '../../../studiotel/studiotel.action';

import { UpdateStudiotelActionPayload } from '../../../../@types/action/studiotel';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '& svg': {
      fontSize: 80,
      marginRight: theme.spacing(2),
    },
  },
}));

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  studiotelIdToRemove: number | null;
  setStudiotelIdToRemove: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function AddStudiotelDialog(props: IProps) {
  const { open, setOpen, studiotelIdToRemove, setStudiotelIdToRemove } = props;
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClose() {
    setOpen(false);
  }

  async function handleConfirm() {
    if (studiotelIdToRemove) {
      setLoading(true);
      const payload: UpdateStudiotelActionPayload = {
        organization_id: 0,
      };
      await dispatch(
        studiotelAction.updateStudiotel(payload, studiotelIdToRemove)
      );
      await studiotelAction.getStudiotels();
      setStudiotelIdToRemove(null);
      setLoading(false);
      setOpen(false);
    }
  }

  return (
    <Dialog id="add-studiotel-dialog" open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.content}
        >
          <WarningRoundedIcon fontSize="large" color="primary" />
          Vous vous apprêtez à supprimer votre Studioplay de l'organisation.
          Êtes-vous certain de vouloir le supprimer ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          non
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {loading && <CircularProgress size={20} />}
          {!loading && <Typography variant="subtitle1">oui</Typography>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { getAuthenticatedInstance } from './helpers/axios.helpers';

const authenticatedInstance = getAuthenticatedInstance();

export async function getSounds(): Promise<any> {
  return authenticatedInstance.get('/sounds');
}

export async function getSoundsForAnOrganizationId(
  organizationId: number
): Promise<any> {
  return authenticatedInstance.post('/rpc/get_organization_sounds', {
    organization_id: organizationId
  });
}

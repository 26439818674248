import React, { Dispatch, SetStateAction } from 'react';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import SyncProblemRoundedIcon from '@material-ui/icons/SyncProblemRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Status, StatusDescription, Step } from './config/deployment.config';
import Controls, {
  IProps as ControlsIProps
} from './components/workflow/controls/controls.component';
import { InProgressDeployment } from '../../@types/deployment';

export function getStepContent(
  activeStep: number,
  setActiveStep: Dispatch<SetStateAction<number>>,
  steps: Step[],
  isStepValid: boolean,
  setIsStepValid: Dispatch<SetStateAction<boolean>>,
  deployment: InProgressDeployment,
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>
): React.ReactNode {
  const activeStepMeta = steps.find((step: Step) => step.step === activeStep);

  if (activeStepMeta === undefined)
    return <div>aucun contenu pour cette étape</div>;

  const { Content } = activeStepMeta;

  return (
    <Content
      setIsStepValid={setIsStepValid}
      deployment={deployment}
      setDeployment={setDeployment}
      Controls={(props: ControlsIProps) => (
        <Controls
          deployment={deployment}
          setDeployment={setDeployment}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          isStepValid={isStepValid}
          setIsStepValid={setIsStepValid}
          {...props}
        />
      )}
    />
  );
}

interface Meta {
  description: string;
  icon: React.ReactNode | null;
}
export function getMetaDependingOnStatus(status: number): Meta {
  const meta: Meta = { description: '', icon: null };

  switch (status) {
    case Status.TO_DEPLOY: {
      meta.description = StatusDescription.TO_DEPLOY;
      meta.icon = (
        <SyncRoundedIcon
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
      );
      break;
    }
    case Status.DEPLOYMENT_IN_PROGRESS: {
      meta.description = StatusDescription.DEPLOYMENT_IN_PROGRESS;
      meta.icon = (
        <SyncRoundedIcon
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
      );
      break;
    }
    case Status.DEPLOYMENT_SUCCESS: {
      meta.description = StatusDescription.DEPLOYMENT_SUCCESS;
      meta.icon = (
        <CheckCircleRoundedIcon
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
      );
      break;
    }
    case Status.DEPLOYMENT_ERROR: {
      meta.description = StatusDescription.DEPLOYMENT_ERROR;
      meta.icon = (
        <SyncProblemRoundedIcon
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
      );
      break;
    }
    default: {
      meta.description = StatusDescription.DEPLOYMENT_IN_PROGRESS;
      meta.icon = (
        <SyncRoundedIcon
          style={{ display: 'inline-flex', verticalAlign: 'middle' }}
        />
      );
    }
  }

  return meta;
}

import React from 'react';
import { useHistory } from 'react-router';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '& svg': {
      fontSize: 80,
      marginRight: theme.spacing(2),
    },
  },
}));

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ExitSetupDialog(props: IProps) {
  const { open, setOpen } = props;
  const history = useHistory();
  const classes = useStyles();

  function handleClose() {
    setOpen(false);
  }

  function handleConfirm() {
    history.goBack();
  }

  return (
    <Dialog id="exit-setup-dialog" open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText
          id="exit-setup-description"
          className={classes.content}
        >
          <WarningRoundedIcon fontSize="large" color="primary" />
          Vous vous apprêtez à quitter l'espace de configuration de votre
          Studioplay. Si vous quittez maintenant, toutes les étapes précédemment
          effectuées ne seront pas sauvegardées. Êtes-vous certain de vouloir
          quitter ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          non
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
          autoFocus
        >
          oui
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { State } from '../../@types/state';
import { Studiotel } from '../../@types/state/studiotel';
import { OrganizationHierarchy } from '../../@types/state/hierarchy';
import { TreeItems } from '../../@types/hierarchy';
import { selectStudiotelsByOrganizationId } from '../studiotel/studiotel.selector';
import { selectOrganizationById } from '../organization/organization.selector';
import { selectUserOrganization } from '../auth/auth.selector';
import { selectHasStudiotelDisconnectedInStudiotelList } from '../studiotel/studiotel.selector';

export function selectInitFetch(state: State): boolean {
  return state.hierarchy.initFetch;
}

export function selectFetchPending(state: State): boolean {
  return state.hierarchy.pending;
}

function createHierachy(
  state: State,
  hierarchy: OrganizationHierarchy[],
  nodeIds: Set<number>
): TreeItems[] {
  const treeItems: TreeItems[] = [];

  nodeIds.forEach((organizationId: number) => {
    const organization = selectOrganizationById(state)(organizationId);
    const studiotels = selectStudiotelsByOrganizationId(state)(organizationId);
    const studiotelsIds =
      studiotels.map((studiotel: Studiotel) => studiotel.id) || [];
    const hasStudiotelDisconnectedInStudiotelList = selectHasStudiotelDisconnectedInStudiotelList(
      state,
      studiotelsIds
    );

    const children = hierarchy.filter(
      (item: OrganizationHierarchy) =>
        item.parent_id === organizationId && item.depth === 1
    );
    const childrenIds = new Set(
      children.map((item: OrganizationHierarchy) => item.child_id)
    );

    if (childrenIds?.size > 0) {
      const node: TreeItems = {
        id: organizationId,
        children: createHierachy(state, hierarchy, childrenIds),
        name: organization?.name,
        manager: organization?.manager,
        alert: hasStudiotelDisconnectedInStudiotelList,
        ...(studiotels && studiotels.length > 0 && { studiotels }),
      };

      let hasChildrenAlert = false;
      node.children?.forEach((treeItems: TreeItems) => {
        if (treeItems.alert) hasChildrenAlert = true;
      });

      node.alert = hasChildrenAlert;

      treeItems.push(node);
    } else {
      const node: TreeItems = {
        id: organizationId,
        name: organization?.name,
        manager: organization?.manager,
        alert: hasStudiotelDisconnectedInStudiotelList,
        ...(studiotels && studiotels.length > 0 && { studiotels }),
      };

      treeItems.push(node);
    }
  });

  return treeItems;
}

export const selectTreeItems = function (state: State): TreeItems[] {
  const { hierarchy } = state.hierarchy.data;

  if (hierarchy === undefined) return [];

  const userOrganizationId = selectUserOrganization(state);
  if (userOrganizationId === null) return [];

  const nodeIds = new Set([userOrganizationId]);

  return createHierachy(state, hierarchy, nodeIds);
};

export const selectTreeItemsFromAnOrganizationId = function (state: State) {
  return function (nodeId: number) {
    const { hierarchy } = state.hierarchy.data;

    if (hierarchy === undefined) return [];

    const nodeIds = new Set([nodeId]);

    return createHierachy(state, hierarchy, nodeIds);
  };
};

export function selectFleetTopLevel(state: State) {
  const treeItems = selectTreeItems(state);
  const topLevelNode = treeItems[0];
  const children = topLevelNode?.children;
  const manager = topLevelNode?.manager;

  let startTreeItem: TreeItems[];

  if (manager && children) {
    startTreeItem = children;
  } else {
    startTreeItem = treeItems;
  }

  return startTreeItem;
}

export function selectFleetTopLevelFromAnOrganizationId(state: State) {
  return function (organizationId: number) {
    const treeItems = selectTreeItemsFromAnOrganizationId(state)(
      organizationId
    );
    const topLevelNode = treeItems[0];
    const children = topLevelNode?.children;
    const manager = topLevelNode?.manager;
    let startTreeItem: TreeItems[] = [];

    if (manager && children) {
      startTreeItem = children;
    } else {
      startTreeItem = treeItems;
    }

    return startTreeItem;
  };
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../modules/utils/components/loader.component';
import { selectFetchPending } from '../../modules/auth/auth.selector';
import { ValidRefreshToken, RefreshTokenOptions } from '../../@types/auth';
import * as AuthAction from '../../modules/auth/auth.action';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      display: 'flex',
      flex: '1',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      background: theme.login.background,
    },
    container: {
      display: 'flex',
      height: '50%',
      width: '70%',
      maxWidth: '800px',
      minHeight: '450px',
      boxShadow: theme.shadows[4],
      borderRadius: theme.shape.borderRadius,
    },
    img: {
      objectFit: 'cover',
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    authForm: {
      borderTopRightRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })
);

export default function ResetPassword() {
  const location = useLocation();

  const classes = useStyles();
  const fetchPending = useSelector(selectFetchPending);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const h = searchParams.get('h');
    if (h) {
      const refreshToken: ValidRefreshToken = {
        grant_type: 'refresh_token',
        refresh_token: h,
      };
      const options: RefreshTokenOptions = {
        resetPassword: true,
      };
      dispatch(AuthAction.refreshToken(refreshToken, options));
    }
  }, [dispatch, location]);

  return (
    <div id="login-view" className={classes.view}>
      {fetchPending && (
        <Backdrop className={classes.backdrop} open>
          <Loader />
        </Backdrop>
      )}
    </div>
  );
}

import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../layout.component';
import Aside from '../aside.component';
import svg from './img_step5.svg';
import { InProgressDeployment } from '../../../../../../@types/deployment';
import { selectSoundById } from '../../../../../sound/sound.selector';
import Message from '../../../../../studiotel/components/currentMessages/message/message.connector';
import { selectDeploymentOrganizationId } from '../../../../deployment.selector';
import { selectOrganizationById } from '../../../../../organization/organization.selector';
import moment from 'moment';
import { messageTypes } from '../../../../../studiotel/config/studiotel.config';
import StudiotelDisplay from '../../../../../studiotel/components/studiotelDisplay/studiotelDisplay.component';
import TextField from '@material-ui/core/TextField';

export interface IProps {
  Controls: React.ElementType;
  setIsStepValid: Dispatch<SetStateAction<boolean>>;
  deployment: InProgressDeployment;
  setDeployment: Dispatch<SetStateAction<InProgressDeployment>>;
}

export default function Step5(props: IProps) {
  const { Controls, setIsStepValid, deployment, setDeployment } = props;
  const getSoundById = useSelector(selectSoundById);
  const sound = getSoundById(deployment.sound_id);
  const selectedOrganizationId = useSelector(selectDeploymentOrganizationId);
  const getOrganizationById = useSelector(selectOrganizationById);
  const selectedOrganization = getOrganizationById(selectedOrganizationId);

  useEffect(() => {
    setIsStepValid(true);
  }, [deployment, setIsStepValid]);

  function handleInformationChange(event: React.ChangeEvent<HTMLInputElement>) {
    const deploymentUpdated = {
      ...deployment,
      description: event?.currentTarget.value,
    };
    setDeployment(deploymentUpdated);
  }

  return (
    <Layout
      title="Validation avant déploiement"
      aside={
        <Aside
          svg={svg}
          title="Validation avant déploiement"
          description="Vérifions les informations de votre pilotage avant le déploiement. Pensez bien à vérifier chacune des informations avant de confirmer l’envoi des messages sur le ou les Studioplay(s) NT séléctionnés."
        />
      }
      controls={<Controls />}
    >
      <Box flexGrow={1} height="100%">
        <Box
          id="step5-content"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Box id="workflow-summary1" mb={1}>
            <Box id="workflow-summary1-h-wrapper" display="flex" height="100%">
              <Box
                id="workflow-summary1-content"
                bgcolor="workflow.background.main"
                flexGrow={1}
              >
                <Box fontWeight="fontWeightBold" p={1} fontSize="h6.fontSize">
                  Message et contenu
                </Box>
                <Box p={1}>
                  {sound && (
                    <Message
                      key={sound.id}
                      sounds={sound}
                      messageType={sound.type}
                      isDefault
                    />
                  )}
                  {deployment.sound && (
                    <Box display="flex" flexDirection="column">
                      <Box fontWeight="fontWeightBold">
                        {deployment.sound.name}
                      </Box>
                      <Box>{deployment.sound_title}</Box>
                      <Box>{deployment.sound_description}</Box>
                      <Box>
                        {
                          //@ts-ignore
                          messageTypes[deployment.message_type]
                        }
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box alignSelf="center">
                <Button disabled>modifier</Button>
              </Box>
            </Box>
          </Box>

          <Box id="workflow-summary2" mb={1}>
            <Box id="workflow-summary2-h-wrapper" display="flex" height="100%">
              <Box
                id="workflow-summary2-content"
                bgcolor="workflow.background.main"
                flexGrow={1}
              >
                <Box fontWeight="fontWeightBold" p={1} fontSize="h6.fontSize">
                  Echelle de déploiement
                </Box>
                <Box fontSize="caption" p={1}>
                  <strong>Enseigne concernée :</strong>{' '}
                  {selectedOrganization?.name}
                </Box>
                {deployment.studiotels_ids &&
                  deployment.studiotels_ids.map((studiotelId: number) => {
                    return (
                      <Box pl={1} key={studiotelId}>
                        <StudiotelDisplay studiotelId={studiotelId} />
                      </Box>
                    );
                  })}
              </Box>
              <Box alignSelf="center">
                <Button disabled>modifier</Button>
              </Box>
            </Box>
          </Box>

          <Box id="workflow-summary3" flexGrow={1} mb={1}>
            <Box id="workflow-summary3-h-wrapper" display="flex" height="100%">
              <Box
                id="workflow-summary3-content"
                bgcolor="workflow.background.main"
                flexGrow={1}
              >
                <Box fontWeight="fontWeightBold" p={1} fontSize="h6.fontSize">
                  Période de diffusion
                </Box>
                {deployment.planning?.is_default ? (
                  <Box p={1}>Message par défaut</Box>
                ) : (
                  <>
                    <Box fontSize="caption" p={1}>
                      <strong>À partir du :</strong>{' '}
                      {deployment.planning?.start_time &&
                        moment(deployment.planning?.start_time).format(
                          'dddd D MMMM YYYY à HH:mm:ss'
                        )}
                    </Box>
                    <Box fontSize="caption" p={1}>
                      <strong>Jusqu'au :</strong>{' '}
                      {deployment.planning?.stop_time &&
                        moment(deployment.planning?.stop_time).format(
                          'dddd D MMMM YYYY à HH:mm:ss'
                        )}
                    </Box>
                    {/* <Box>
                      {deployment.planning?.week_days.map((weekDay: any) => {
                        return JSON.stringify(weekDay);
                      })}
                    </Box> */}
                  </>
                )}
                <Box fontWeight="fontWeightBold" p={1} fontSize="h6.fontSize">
                  Informations complémentaires
                </Box>
                <Box p={1}>
                  <TextField
                    label="Informations complémentaires sur le deploiement"
                    onChange={handleInformationChange}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box alignSelf="center">
                <Button disabled>modifier</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}

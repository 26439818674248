import { getToken } from '../../../modules/auth/auth.selector';
import { store } from '../../../Store';

export function injectToken(config: any) {
  const updatedConfig = config;
  const state = store.getState();
  const token = getToken(state);

  updatedConfig.headers.Authorization = `Bearer ${token}`;

  return updatedConfig;
}

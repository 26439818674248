import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Message from '../message/message.connector';
import Grid from '@material-ui/core/Grid';
import CallEndRoundedIcon from '@material-ui/icons/CallEndRounded';
import Box from '@material-ui/core/Box';
import { Planning } from '../../../../../@types/state/planning';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    background: theme.pbx.close.color
  },
  pbxCloseIcon: {
    marginRight: theme.spacing(1)
  }
}));

interface IProps {
  currentStudiotelVoicemailPlanning: Planning | null;
}

const PbxClose: React.FC<IProps> = props => {
  const { currentStudiotelVoicemailPlanning } = props;
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexWrap="no-wrap" flexGrow={1}>
        <Box className={classes.close} width={8} mr={1}></Box>

        <Box width={1}>
          <Grid container alignItems="center">
            <Grid item>
              <CallEndRoundedIcon className={classes.pbxCloseIcon} />
            </Grid>
            <Grid item>
              <Box>Standard fermé</Box>
            </Grid>
          </Grid>

          {currentStudiotelVoicemailPlanning && (
            <Box display="flex" flexWrap="no-wrap" alignItems="center">
              <Message
                sounds={currentStudiotelVoicemailPlanning.sounds}
                messageType={currentStudiotelVoicemailPlanning.message_type}
                isDefault={currentStudiotelVoicemailPlanning.is_default}
              />
              <Box>
                <DragIndicatorRoundedIcon />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PbxClose;

import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IProps {
  expanded: string[];
  setExpanded: Dispatch<SetStateAction<string[]>>;
  children: JSX.Element[];
}

export default function Hierachy(props: IProps) {
  const { children, expanded, setExpanded } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleChange}
    >
      {children}
    </TreeView>
  );
}
